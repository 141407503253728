import { connect } from 'react-redux';

import {
  homeClicked,
  newLeaveRequestClicked,
  logsClicked,
  leaveRequestsConsultClicked,
  activityReportClicked,
  newExpenseClicked,
  expensesMyExpensesClicked,
} from '../Redux/Actions/Actions';

import Home from './Home';

function mapStateToProps(state) {
  return {
    firstName: state.identity.state === 'SYNCHRONISED' ? state.identity.firstName : '',
    employeeType: state.identity.type,
    email: state.identity.email,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    seeHome: () => dispatch(homeClicked()),
    newLeaveRequest: () => dispatch(newLeaveRequestClicked()),
    seeLeaveRequests: () => dispatch(leaveRequestsConsultClicked()),
    seeActivityReport: () => dispatch(activityReportClicked()),
    newExpense: () => dispatch(newExpenseClicked()),
    myExpense: () => dispatch(expensesMyExpensesClicked()),
    goLogs: () => dispatch(logsClicked()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
