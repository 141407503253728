import { CURRENCIES_GET_END } from '../../Actions/Actions'

const currenciesInitialState = null;

function currencies(state = currenciesInitialState, action){
    switch(action.type){
        case CURRENCIES_GET_END:
            return action.currencies;
        default: 
            return state;
    }
}

export default currencies;