export const CURRENCIES_GET_START = "CURRENCIES_GET_START"
export const CURRENCIES_GET_END= "CURRENCIES_GET_END"

export const currenciesGetStart = () => ({
    type: CURRENCIES_GET_START
})

export const currenciesGetEnd = (currencies) => ({
    type: CURRENCIES_GET_END,
    currencies: currencies
})