import React from 'react';
import { Nav } from 'react-bootstrap';
import './NavBar.css';

const authorizedMails =
  'jagagniere@bertek.fr,gberthalon@bertek.fr,jrabat@bertek.fr,ycollet@bertek.fr,tetjemesian@bertek.fr,nkossakowski@bertek.pl, dteliakovskyi@bertek.pl';

export default function NavBar(props) {
  return (
    <Nav className='navigation'>
      {props.currentPage !== 'Home' && (
        <Nav.Item className='navigation__link' onClick={props.seeHome}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='25'
            height='25'
            fill='currentColor'
            className='bi bi-arrow-left-square'
            viewBox='0 0 16 16'>
            <path
              fillRule='evenodd'
              d='M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm11.5 5.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z'
            />
          </svg>
        </Nav.Item>
      )}

      <Nav.Item className='navigation__link' onClick={props.seeHome}>
        <picture className='navigation__picture'>
          <img src='bertek-black-logo.png' className='navigation__logo' alt='Bertek'></img>
        </picture>        
      </Nav.Item>
      <div className='navigation__box'>
      <div>v2.0.1</div>
        {authorizedMails.includes(props.email) && (
          <Nav.Item className='navigation__link' onClick={props.goLogs}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='25'
              height='25'
              fill='currentColor'
              className='bi bi-info-square'
              viewBox='0 0 16 16'>
              <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z' />
              <path d='m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z' />
            </svg>
          </Nav.Item>
        )}        
        <Nav.Item className='navigation__link' onClick={props.logout}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='30'
            height='30'
            fill='currentColor'
            className='bi bi-box-arrow-right'
            viewBox='0 0 16 16'>
            <path
              fillRule='evenodd'
              d='M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z'
            />
            <path
              fillRule='evenodd'
              d='M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z'
            />
          </svg>
        </Nav.Item>     
      </div>
    </Nav>
  );
}
