import { connect } from 'react-redux'
import CancelLeaveRequest from './CancelLeaveRequest'
import {
    leaveRequestCancelHideClicked,
    leaveRequestCancelConfirmClicked,
    leaveRequestCancelPostEnd,
    leaveRequestsGetEnd
} from '../../../Redux/Actions/Actions'


function mapStateToProps(state){
    return {
        modalShow: state.leaveRequests.toDelete !== null,
        deleteLine: state.leaveRequests.toDelete,
        contactId: state.identity.contactId
    }
}

function mapDispatchToProps(dispatch){
    return {
        hideModal: () => dispatch(leaveRequestCancelHideClicked()),
        confirm: () => dispatch(leaveRequestCancelConfirmClicked()),
        postEnd: (message) => dispatch(leaveRequestCancelPostEnd(message)),
        getEnd: (response) => dispatch(leaveRequestsGetEnd(response)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CancelLeaveRequest)