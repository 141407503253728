import {
  ACTIVITY_REPORT_ACTIVITY_CHANGE, ACTIVITY_REPORT_ADD_ACTIVITY_CLICKED, ACTIVITY_REPORT_CLOSE_CONFIRM_MODAL_CLICKED, ACTIVITY_REPORT_CLOSE_DELETE_MODAL_CLICKED, ACTIVITY_REPORT_CLOSE_DOWNLOAD_MODAL_CLICKED, ACTIVITY_REPORT_CONFIRM_DELETE_CLICKED,
  ACTIVITY_REPORT_DELETE_CLICKED, ACTIVITY_REPORT_EMPTY_LINES, ACTIVITY_REPORT_FILES_POST_END,
  ACTIVITY_REPORT_FILES_POST_ERROR, ACTIVITY_REPORT_FILE_ADD, ACTIVITY_REPORT_FILE_CHANGE, ACTIVITY_REPORT_FILE_DELETE_CLICKED, ACTIVITY_REPORT_GENERATE_DOWNLOAD_CLICKED, ACTIVITY_REPORT_GET_END,
  ACTIVITY_REPORT_GET_ERROR, ACTIVITY_REPORT_GET_LOGO_ERROR, ACTIVITY_REPORT_GET_START, ACTIVITY_REPORT_INVOICE_CHANGE, ACTIVITY_REPORT_LANG_CHANGE, ACTIVITY_REPORT_POST_END, ACTIVITY_REPORT_POST_START, ACTIVITY_REPORT_TABLE_MOUNTED, ACTIVITY_REPORT_TABS_CLICKED, ACTIVITY_REPORT_TOTAL_TOO_LOW, ACTIVITY_REPORT_TYPE_CHANGE, ACTIVITY_REPORT_VALUE_CHANGE
} from '../Actions/Actions';

import cloneDeep from 'lodash/cloneDeep';

const now = new Date();
let selectYear = [];

var internalActivities = ['Conference / MeetUp', 'Training'];
var externalActivities = ['External Leave'];

for (var i = -1; i < 2; i++) {
  selectYear.push(now.getFullYear() + i);
}

const activityReportInitialState = {
  selectedYear: now.getFullYear(),
  selectedMonth: now.getMonth(),
  selectedType: 'Daily',
  lines: [],
  footerLine: null,
  selectedDeleteLine: { name: '', type: '', total: 0, days: [], nation: '' },
  activityModalShow: false,
  confirmModalShow: false,
  downloadModalShow: false,
  daysOfMonth: new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate(),
  daysOfWork: 0,
  message: null,
  ready: false,
  disableUpload: true,
  busy: true,
  activeKey: 'Activity-Report',
  selectYear: selectYear,
  availableActivities: [],
  invoice: null,
};

const activityReportAcceptedType = ['image', 'pdf'];
const activityReportAcceptedTypeMandatory = ['pdf'];
const invoiceAcceptedType = ['pdf'];

function changeLangOfLines(lines, client, lang) {
  return lines.map((line) => (line.client === client ? { ...line, lang: lang } : line));
}

function updateTypeOnLines(newType, countryCode, lines, footerLine) {
  const hoursPerDay = countryCode === 'FR' ? 7 : 8;
  if (lines.length > 0 && footerLine !== null) {
    if (lines[0].unit === 'Day') {
      lines.forEach((line) => {
        line.days.map((day) => (day.value = day.value / hoursPerDay));
        countTotalInLine(line);
      });
      footerLine.name = 'Total days';
    } else if (lines[0].unit === 'Hour') {
      lines.forEach((line) => {
        line.days.map((day) => (day.value = day.value * hoursPerDay));
        countTotalInLine(line);
      });
      footerLine.name = 'Total hours';
    }
    countTotalAllDay(lines, footerLine);
    countTotalInFooter(footerLine);
  }
}

function updateLinesOnChangeValue(newValue, tempLines, tempFooter, indexLine, indexDay, selectedType) {
  if (tempLines[indexLine].days[indexDay].value !== newValue) {
    tempLines[indexLine].days[indexDay].value = newValue;
    countTotalInLine(tempLines[indexLine]);
    countTotalInDay(tempLines, tempFooter, indexDay, selectedType);
    countTotalInFooter(tempFooter);
  }
}

function updateFooterLine(footerLine, currentLines, deletedLine) {
  const footerLineDays = footerLine.days;
  const hoursPerDay = deletedLine.isoCodeCountry === 'FR' ? 7 : 8;
  const updatedFooterLineDays = updateFooterLineDays(footerLineDays, currentLines, deletedLine, hoursPerDay);

  return {
    ...footerLine,
    days: updatedFooterLineDays,
    total: updateTotal(updatedFooterLineDays),
  };
}

function updateFooterLineDays(footerLineDays, currentLines, deletedLine, hoursPerDay) {
  return footerLineDays.map((day, index) => {
    let updatedValue;

    if (deletedLine.unit === 'Hour') {
      updatedValue = deletedLine.days[index].value
        ? day.value - Math.round(parseFloat(deletedLine.days[index].value / hoursPerDay)) / 100
        : day.value;
    } else {
      updatedValue = deletedLine.days[index].value
        ? day.value - Math.round(parseFloat(deletedLine.days[index].value) * 100) / 100
        : day.value;
    }

    return {
      ...day,
      value: updatedValue,
    };
  });
}

function updateTotal(days) {
  return days.reduce((acc, day) => (acc += day.value), 0);
}

function countTotalInDay(lines, footer, index, selectedType) {
  footer.days[index].value = 0;
  lines.map((line) => {
    const hoursPerDay = line.isoCodeCountry === 'FR' ? 7 : 8;
    let valueDay = 0;
    if (selectedType === 'Daily') {
      valueDay =
        line.unit === 'Day' || !line.unit
          ? parseFloat(line.days[index].value)
          : parseFloat(line.days[index].value / hoursPerDay);
    } else {
      valueDay = line.days[index].value;
    }
    return (footer.days[index].value += Math.round(valueDay * 100) / 100 ? Math.round(valueDay * 100) / 100 : 0);
  });
}

function countTotalInLine(line) {
  line.total = 0;
  line.days.forEach(
    (day) =>
      (line.total += Math.round(parseFloat(day.value) * 100) / 100 ? Math.round(parseFloat(day.value) * 100) / 100 : 0)
  );
}

function countTotalAllDay(lines, footer) {
  footer.days.forEach((day) => {
    let index = footer.days.indexOf(day);
    day.value = 0;

    lines.forEach((line) => {
      const hoursPerDay = line.isoCodeCountry === 'FR' ? 7 : 8;
      if (line.unit === 'Hour')
        day.value += line.days[index].value ? Math.round(parseFloat(line.days[index].value / hoursPerDay)) / 100 : 0;
      else day.value += line.days[index].value ? Math.round(parseFloat(line.days[index].value) * 100) / 100 : 0;
    });
  });
}

function countTotalInFooter(tempFooter) {
  tempFooter.total = 0;
  tempFooter.days.forEach(
    (day) =>
      (tempFooter.total +=
        Math.round(parseFloat(day.value) * 100) / 100 ? Math.round(parseFloat(day.value) * 100) / 100 : 0)
  );
}

function activityReport(state = activityReportInitialState, action) {
  switch (action.type) {
    case ACTIVITY_REPORT_INVOICE_CHANGE: {
      if (action.newInvoice) {
        let validFormat = invoiceAcceptedType.some((format) => action.newInvoice.type.includes(format));
        let validSize = action.newInvoice.size < 3 * 1024 * 1024;
        if (validFormat && validSize) {
          return {
            ...state,
            invoice: action.newInvoice,
            message: null,
          };
        } else if (!validFormat)
          return {
            ...state,
            message: {
              type: 'danger',
              value: 'Please upload a file of type pdf.',
            },
          };
        else
          return {
            ...state,
            message: {
              type: 'danger',
              value: 'Please upload a file smaller than 3 Mb.',
            },
          };
      } else {
        return {
          ...state,
          invoice: null,
          message: null,
        };
      }
    }
    case ACTIVITY_REPORT_LANG_CHANGE:
      return {
        ...state,
        lines: changeLangOfLines(state.lines, action.client, action.newLang),
      };
    case ACTIVITY_REPORT_GET_START:
      return {
        ...state,
        lines: [],
        footerLine: null,
        daysOfWork: 0,
        daysOfMonth: 0,
        message: null,
        ready: false,
        invoice: null,
      };
    case ACTIVITY_REPORT_GET_END:
      return {
        ...state,
        ...action.datas,
        busy: false,
      };
    case ACTIVITY_REPORT_GET_ERROR:
      return {
        ...state,
        busy: true,
        message: {
          type: 'danger',
          value: "The server doesn't respond, please try again later.",
        },
      };
    case ACTIVITY_REPORT_GET_LOGO_ERROR:
      return {
        ...state,
        message: {
          type: 'danger',
          value: "The server doesn't respond, unable to get the logo.",
        },
      };
    case ACTIVITY_REPORT_TYPE_CHANGE:
      let lines = cloneDeep(state.lines);
      let footerLine = cloneDeep(state.footerLine);
      updateTypeOnLines(action.newType, action.countryCode, lines, footerLine);
      const hoursPerDay = action.countryCode === 'FR' ? 7 : 8;
      return {
        ...state,
        selectedType: action.newType,
        daysOfWork: action.newType === 'Daily' ? state.daysOfWork / hoursPerDay : state.daysOfWork * hoursPerDay,
        ready: false,
        lines: lines,
        footerLine: footerLine,
      };
    case ACTIVITY_REPORT_CLOSE_DOWNLOAD_MODAL_CLICKED:
      return {
        ...state,
        downloadModalShow: false,
      };
    case ACTIVITY_REPORT_GENERATE_DOWNLOAD_CLICKED:
      return {
        ...state,
        downloadModalShow: true,
        selectedDownloadActivity: action.activity,
      };
    case ACTIVITY_REPORT_TOTAL_TOO_LOW:
      let unit = state.selectedType === 'Daily' ? 'days' : 'hours';
      let message = {
        type: 'danger',
        value:
          'The minimun declared ' +
          unit +
          ' must be ' +
          state.daysOfWork +
          '. Please complete your AR and save it again',
      };
      return {
        ...state,
        message: message,
      };
    case ACTIVITY_REPORT_CLOSE_CONFIRM_MODAL_CLICKED:
      return {
        ...state,
        confirmModalShow: false,
      };
    case ACTIVITY_REPORT_EMPTY_LINES:
      return {
        ...state,
        confirmModalShow: true,
      };
    case ACTIVITY_REPORT_FILE_CHANGE:
      if (action.newFile) {
        let validFormat = activityReportAcceptedTypeMandatory.some((format) => action.newFile.type.includes(format));

        let validSize = action.newFile.size < 3 * 1024 * 1024;
        if (validFormat && validSize) {
          let newLines = state.lines.map((line) => {
            if (action.activityReportId === line.activityReportId) {
              return {
                ...line,
                file: action.newFile,
              };
            } else return line;
          });
          return {
            ...state,
            lines: newLines,
            disableUpload: newLines.filter((line) => line.file !== null).length === 0,
            message: null,
          };
        } else if (!validFormat)
          return {
            ...state,
            message: {
              type: 'danger',
              value: 'Please upload a file of type pdf',
            },
          };
        else
          return {
            ...state,
            message: {
              type: 'danger',
              value: 'Please upload a file smaller than 3 Mo.',
            },
          };
      } else {
        return {
          ...state,
          invoice: null,
          message: null,
        };
      }
    case ACTIVITY_REPORT_FILE_ADD: {
      let validFormat = activityReportAcceptedType.some((format) => action.newFile.type.includes(format));

      let validSize = action.newFile.size < 3 * 1024 * 1024;
      if (validFormat && validSize) {
        let newLines = state.lines.map((line) => {
          if (action.activityReportId === line.activityReportId) {
            return {
              ...line,
              otherFiles: [...line.otherFiles, action.newFile],
            };
          } else return line;
        });
        return {
          ...state,
          lines: newLines,
          message: null,
        };
      } else if (!validFormat)
        return {
          ...state,
          message: {
            type: 'danger',
            value: 'Please upload a file of type pdf or image.',
          },
        };
      else
        return {
          ...state,
          message: {
            type: 'danger',
            value: 'Please upload a file smaller than 3 Mo.',
          },
        };
    }
    case ACTIVITY_REPORT_FILE_DELETE_CLICKED:
      let newLines = state.lines.map((line) => {
        if (action.activityReportId === line.activityReportId) {
          return {
            ...line,
            otherFiles: line.otherFiles.filter((file, index) => index !== action.index),
          };
        } else return line;
      });
      return {
        ...state,
        lines: newLines,
        message: null,
      };
    case ACTIVITY_REPORT_FILES_POST_ERROR:
      return {
        ...state,
        message: {
          type: 'danger',
          value: 'Please try again or contact your manager',
        },
      };
    case ACTIVITY_REPORT_FILES_POST_END:
      return {
        ...state,
        message: action.message,
      };
    case ACTIVITY_REPORT_TABS_CLICKED:
      return {
        ...state,
        activeKey: action.newKey,
        message: null,
      };
    case ACTIVITY_REPORT_POST_START:
      return {
        ...state,
        message: null,
        busy: true,
        confirmModalShow: false,
      };
    case ACTIVITY_REPORT_POST_END:
      return {
        ...state,
        message: action.response.message,
        ready: action.response.message.type === 'success' ? true : false,
        lines: action.response.missions,
        busy: false,
      };
    case ACTIVITY_REPORT_CLOSE_DELETE_MODAL_CLICKED:
      return {
        ...state,
        activityModalShow: false,
        selectedDeleteLine: {
          name: '',
          type: '',
          total: 0,
          days: [],
          nation: '',
        },
      };
    case ACTIVITY_REPORT_CONFIRM_DELETE_CLICKED: {
      let lines = cloneDeep(state.lines);
      const deletedLine = lines.splice(action.lineIndex, 1);

      let availableActivities = [...state.availableActivities];
      availableActivities.push(state.lines[action.lineIndex].name);

      return {
        ...state,
        activityModalShow: false,
        lines: lines,
        availableActivities: availableActivities,
        footerLine: updateFooterLine(state.footerLine, lines, deletedLine[0]),
      };
    }
    case ACTIVITY_REPORT_DELETE_CLICKED:
      return {
        ...state,
        activityModalShow: true,
        selectedDeleteLine: action.selectedDeleteLine,
      };
    case ACTIVITY_REPORT_VALUE_CHANGE:
      let tempLines = cloneDeep(state.lines);
      let tempFooter = cloneDeep(state.footerLine);
      updateLinesOnChangeValue(
        action.newValue,
        tempLines,
        tempFooter,
        action.indexLine,
        action.indexDay,
        action.selectedType
      );
      return {
        ...state,
        ready: false,
        lines: tempLines,
        footerLine: tempFooter,
      };
    case ACTIVITY_REPORT_ADD_ACTIVITY_CLICKED:
      let firstLine = cloneDeep(state.lines[0]);
      const stateLines = cloneDeep(state.lines);

      const selectedActivities = stateLines.reduce((acc, line) => {
        if (action.employeeType === 'Employee') {
          if (internalActivities.includes(line.name)) {
            return [...acc, line.name]
          }
        } else {
          if (externalActivities.includes(line.name)) {
            return [...acc, line.name]
          }
        }

        return acc
      }, [])

      let activities = []
    
      if (action.employeeType === 'Employee') {
        activities = internalActivities.filter(activity => !selectedActivities.includes(activity))
      } else {
        activities = externalActivities.filter(activity => !selectedActivities.includes(activity))
      }

      const activityLine = {
        name: activities[0],
        type: 'A',
        total: 0,
        days: [],
        canModified: true
      };

      firstLine.days.forEach((day) => {
        activityLine.days.push({
          date: day.date,
          value: 0,
          isWeekend: day.isWeekend,
          isHolidays: day.isHolidays,
        });
      });
      return {
        ...state,
        lines: [...state.lines, activityLine],
        availableActivities: activities.length ? activities : [],
      };
    case ACTIVITY_REPORT_TABLE_MOUNTED:
      let availableActivities = action.employeeType === 'Employee' ? internalActivities : externalActivities;

      state.lines.forEach(
        (line) => (availableActivities = availableActivities.filter((activity) => activity !== line.name))
      );
      return {
        ...state,
        availableActivities,
      };
    case ACTIVITY_REPORT_ACTIVITY_CHANGE: {
      let lines = cloneDeep(state.lines);

      lines[action.lineIndex].name = action.selectedActivity;

      return {
        ...state,
        lines,
      };
    }
    default:
      return state;
  }
}

export default activityReport;
