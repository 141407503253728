import React from 'react';
import './Expense.css';
import { FaPlus, FaArrowLeft, FaCheck } from 'react-icons/fa';
import { IconContext } from 'react-icons';
import { BsCheckBox } from 'react-icons/bs';
import { FaTrash } from 'react-icons/fa';
import { MdCheckBoxOutlineBlank } from 'react-icons/md';
import ExpenseService from '../Services/ExpensesServices';
import CancelExpenseContainer from './Components/CancelExpense/CancelExpenseContainer';
import { Alert } from 'react-bootstrap';

import { Breakpoint } from 'react-socks';

const monthNames = {
	'01': 'January',
	'02': 'February',
	'03': 'March',
	'04': 'April',
	'05': 'May',
	'06': 'June',
	'07': 'July',
	'08': 'August',
	'09': 'September',
	10: 'October',
	11: 'November',
	12: 'December',
};

export default class Expense extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			month: '',
			year: '',
		};
	}
	componentDidMount() {
		this.fetch();
		console.log(this.props);
	}

	async fetch() {
		let response = {};
		try {
			this.props.getStart();
			if (this.props.id === null) {
				response = await ExpenseService.getExpense(this.props.expenseId);
			} else {
				response = await ExpenseService.getExpense(this.props.id);
			}
			//curencies
			this.getCurrencies();

			this.props.getEnd(response);
			this.setState({ month: monthNames[this.props.month.substring(0, 2)] });
			this.setState({ year: '20' + this.props.month.slice(-2) });
		} catch (e) {
			this.props.setError(e.toString());
		}
	}
	componentDidUpdate(prevProps) {
		if (prevProps.currency !== this.props.currency)
			this.props.currencies.forEach((currency) => {
				if (currency.id === this.props.currency) {
					this.setState({ symbol: currency.symbol });
				}
			});
		if (prevProps.message) {
			if (prevProps.message.type === 'success') {
				setTimeout(() => {
					this.props.expenseDeleteEnd(null);
				}, 5000);
			}
		}
	}

	async getCurrencies() {
		let currencies = await ExpenseService.getCurrencies();

		currencies.forEach((currency) => {
			if (currency.id === this.props.currencyId) {
				this.props.setCurrencyForExpenseAccount(currency);
			}
		});
		this.props.currenciesGetEnd(currencies);
	}

	render = () => {
		return (
			<>
				<div className='title'></div>
				<div className='leaveRequests'>
					<h2 className='leaveRequests__title'>Expense Account</h2>
					{this.props.message !== null && (
						<Alert variant={this.props.message.type}>
							<p>{this.props.message.value}</p>
						</Alert>
					)}
					<div className='leaveRequest__flex leaveRequest__flex--center'>
						<div className='leaveRequest__block leaveRequest__block--row'>
							<label className='leaveRequest__label'>Current Month:</label>
							<div className='leaveRequest__status'>
								{this.state.month} {this.state.year}
							</div>
						</div>
						<div className='leaveRequest__block leaveRequest__block--row'>
							<label className='leaveRequest__label'>Refund currency :</label>
							<div className='leaveRequest__status'>
								{this.props.currencyModel ? this.props.currencyModel.code : ''}
							</div>
						</div>
					</div>
					<section className='leaveRequests__section'>
						<Breakpoint large up>
							<div className='leaveRequests__table'>
								<header className='leaveRequests__header'>
									<ul className='leaveRequests__list'>
										<li className='leaveRequests__item'>Expense Type</li>
										<li className='leaveRequests__item'>Description</li>
										<li className='leaveRequests__item'>Date</li>
										<li className='leaveRequests__item'>Total Net</li>
										<li className='leaveRequests__item'>VAT</li>
										<li className='leaveRequests__item'>Total</li>
										<li className='leaveRequests__item'>Receipt</li>
										{this.props.canModify && <li className='leaveRequests__item'>Remove</li>}
									</ul>
								</header>
								<div className='leaveRequests__main'>
									{this.props.lines.map((expense) => (
										<ul key={expense.id} className='leaveRequests__list'>
											<li className='leaveRequests__item'>{expense.type}</li>
											<li className='leaveRequests__item'>{expense.description}</li>
											<li className='leaveRequests__item'>{expense.date}</li>
											<li className='leaveRequests__item'>{expense.totalNet}</li>
											<li className='leaveRequests__item'>{expense.totalTax}</li>
											<li className='leaveRequests__item'>{expense.totalBrut}</li>
											<li className='leaveRequests__item'>
												{expense.bill ? (
													<IconContext.Provider value={{ className: 'checked-icon' }}>
														<BsCheckBox />
													</IconContext.Provider>
												) : (
													<IconContext.Provider value={{ className: 'checked-icon' }}>
														<MdCheckBoxOutlineBlank />
													</IconContext.Provider>
												)}
											</li>
											{this.props.canModify && (
												<li className='leaveRequests__item'>
													<FaTrash
														className='print__icon'
														onClick={() => this.props.deleteClicked(expense.id, expense.type, expense.date)}
													/>
												</li>
											)}
										</ul>
									))}
								</div>
								{this.props.lines.length > 1 && (
									<div className='leaveRequests__header'>
										<ul className='leaveRequests__list'>
											<li className='leaveRequests__item'>Total</li>
											<li className='leaveRequests__item'></li>
											<li className='leaveRequests__item'></li>
											<li className='leaveRequests__item'>{this.props.totalNet}</li>
											<li className='leaveRequests__item'>{this.props.totalTax}</li>
											<li className='leaveRequests__item'>{this.props.totalBrut}</li>
											<li className='leaveRequests__item'></li>
											<li className='leaveRequests__item'></li>
										</ul>
									</div>
								)}
							</div>
						</Breakpoint>
						<Breakpoint medium down>
							{this.props.lines.map((expense) => (
								<section key={expense.id} className='leaveRequests__section'>
									<div className='leaveRequests__table'>
										<div className='leaveRequests__item'>
											<div> Date</div>
											<div className='leaveRequests__text'>{expense.date}</div>
										</div>
										{expense.description && (
											<div className='leaveRequests__item'>
												<div> Description</div>
												<div className='leaveRequests__text'>{expense.description}</div>
											</div>
										)}
										<div className='leaveRequests__item'>
											<div>Expense type</div>
											<div className='leaveRequests__text'>{expense.type}</div>
										</div>
										<div className='leaveRequests__item'>
											<div>Total tax free</div>
											<div className='leaveRequests__text'>{expense.totalTax}</div>
										</div>
										<div className='leaveRequests__item'>
											<div>VAT</div>
											<div className='leaveRequests__text'>{expense.totalNet}</div>
										</div>
										<div className='leaveRequests__item'>
											<div>Total amount</div>
											<div className='leaveRequests__text'>{expense.totalBrut}</div>
										</div>
										<div className='leaveRequests__item'>
											<div>Receipt</div>
											<div>
												{expense.bill ? (
													<IconContext.Provider value={{ className: 'checked-icon' }}>
														<BsCheckBox />
													</IconContext.Provider>
												) : (
													<IconContext.Provider value={{ className: 'checked-icon' }}>
														<MdCheckBoxOutlineBlank />
													</IconContext.Provider>
												)}
											</div>
										</div>
										{this.props.canModify && (
											<li className='leaveRequests__item'>
												<button
													className='btn btn--outlined leaveRequests__btn leaveRequests__btn--outlined'
													onClick={() => this.props.deleteClicked(expense.id, expense.type, expense.date)}>
													Remove
												</button>
											</li>
										)}
									</div>
								</section>
							))}
						</Breakpoint>
						{this.props.canModify && (
							<label className='leaveRequest__label leaveRequest__label--color'>
								<input checked={this.props.checkbox} type='checkbox' onChange={this.props.checkboxClicked} /> I'm aware
								that I have to send the original receipts as soon as possible to my Bertek office
							</label>
						)}
					</section>
					{this.props.canModify && (
						<div className='leaveRequest__flex leaveRequest__flex--noGap'>
							<div className='leaveRequest__group'>
								<button
									className='print__attach btn btn--outlined'
									onClick={() => {
										this.props.doneClicked(this.props.expensesMyExpensesClicked);
									}}>
									<FaArrowLeft className='print__icon' />
									Back
								</button>
							</div>
							<div className='leaveRequest__group leaveRequest__group--end'>
								<div className='leaveRequest__block'>
									<button className='print__attach btn btn--secondary' onClick={this.props.addExpense}>
										<FaPlus className='print__icon' />
										Add expense
									</button>
								</div>
								<div className='leaveRequest__block'>
									<button
										className='print__attach btn btn--primary'
										onClick={() => {
											this.props.doneClicked(this.props.checkbox);
										}}>
										Done
										<FaCheck className='print__icon' />
									</button>
								</div>
							</div>
						</div>
					)}
					{!this.props.canModify && (
						<div className='leaveRequest__group'>
							<button
								className='print__attach btn btn--outlined'
								onClick={() => {
									this.props.expensesMyExpensesClicked();
								}}>
								<FaArrowLeft className='print__icon' />
								Back
							</button>
						</div>
					)}

					<CancelExpenseContainer fetch={this.fetch.bind(this)} />
				</div>
			</>
		);
	};
}
