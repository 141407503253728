import { connect } from 'react-redux';
import LeaveRequest from './LeaveRequest';

import {
  leaveRequestDateChange,
  leaveRequestTypeChange,
  leaveRequestAttestationChange,
  leaveRequestNumberOfDaysChange,
  leaveRequestPostEnd,
  sickAttestationPostEnd,
  leaveRequestPostError,
  leaveRequestSubmitClicked,
  leaveRequestGetStart,
  leaveRequestGetEnd,
  leaveRequestGetFileEnd,
  homeClicked,
  leaveRequestCancelClicked,
  leaveRequestDescriptionChange,
  leaveRequestsConsultClicked,
} from '../../Redux/Actions/Actions';

function mapStateToProps(state) {
  return {
    initialDate: state.leaveRequests.leaveRequest.initialDate,
    endDate: state.leaveRequests.leaveRequest.endDate,
    type: state.leaveRequests.leaveRequest.type,
    sickAttestation: state.leaveRequests.leaveRequest.sickAttestation,
    sickLRName: state.leaveRequests.leaveRequest.sickLRName,
    sickLRLink: state.leaveRequests.leaveRequest.sickLRLink,
    contactId: state.identity.contactId,
    numberOfDays: state.leaveRequests.leaveRequest.numberOfDays,
    message: state.leaveRequests.message,
    firstName: state.identity.firstName,
    lastName: state.identity.lastName,
    businessUnitId: state.identity.businessUnitId,
    sharepointMessage: state.leaveRequests.leaveRequest.sharepointMessage,
    leaveRequest: state.leaveRequests.leaveRequest.leaveRequest,
    canCancel: state.leaveRequests.leaveRequest.canCancel,
    canModify: state.leaveRequests.leaveRequest.canModify,
    status: state.leaveRequests.leaveRequest.status,
    description: state.leaveRequests.leaveRequest.description,
    ready:
      typeof state.leaveRequests.leaveRequest.initialDate.date !== 'undefined' &&
      typeof state.leaveRequests.leaveRequest.endDate.date !== 'undefined',
  };
}

function mapDispatchToProps(dispatch) {
  return {
    seeLeaveRequests: () => dispatch(leaveRequestsConsultClicked()),
    setDates: (startDate, endDate, everythingSet) =>
      dispatch(leaveRequestDateChange(startDate, endDate, everythingSet)),
    setType: (newType) => dispatch(leaveRequestTypeChange(newType)),
    setAttestation: (newAttestation) => dispatch(leaveRequestAttestationChange(newAttestation)),
    setNumberOfDays: (newNumber) => dispatch(leaveRequestNumberOfDaysChange(newNumber)),
    setDescription: (newDescription) => dispatch(leaveRequestDescriptionChange(newDescription)),
    postEnd: (message, isSick) => dispatch(leaveRequestPostEnd(message, isSick)),
    sickAttestationPostEnd: (message) => dispatch(sickAttestationPostEnd(message)),
    seeHome: () => dispatch(homeClicked()),
    postError: () => dispatch(leaveRequestPostError()),
    submitClicked: () => dispatch(leaveRequestSubmitClicked()),
    getStart: () => dispatch(leaveRequestGetStart()),
    getEnd: (response) => dispatch(leaveRequestGetEnd(response)),
    getFileEnd: (response) => dispatch(leaveRequestGetFileEnd(response)),
    delete: (leaveRequest) => dispatch(leaveRequestCancelClicked(leaveRequest)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LeaveRequest);
