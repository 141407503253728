import { authProvider } from '../../AuthUtil/authProvider';

class ExpenseService {
  // GET all currencies
  async getCurrencies() {
    const token = await authProvider.getIdToken();
    let response = await fetch(process.env.REACT_APP_API_URL + '/currencies', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token.idToken.rawIdToken,
      },
    });

    response = await response.json();

    return response;
  }
  //CREATE Expense Account
  async createExpenseAccount(expenseAccount) {
    const token = await authProvider.getIdToken();
    let response = await fetch(process.env.REACT_APP_API_URL + '/Expense', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token.idToken.rawIdToken,
      },
      body: JSON.stringify(expenseAccount),
    });
    response = await response.json();
    return response;
  }

  // GET Expense and ExpenseLines
  async getExpense(expenseId) {
    const token = await authProvider.getIdToken();
    let response = await fetch(process.env.REACT_APP_API_URL + `/Expense?expenseId=${expenseId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token.idToken.rawIdToken,
      },
    });

    response = await response.json();
    return response;
  }
  //DELETE ExpenseLine
  async removeExpenseLine(expenseLineId) {
    const token = await authProvider.getIdToken();
    let response = await fetch(process.env.REACT_APP_API_URL + '/ExpenseLine?expenseLineId=' + expenseLineId, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token.idToken.rawIdToken,
      },
    });
    response = await response.json();
    return response;
  }
  //POST send expenseLine to CRM
  async sendExpenseLine(model) {
    const token = await authProvider.getIdToken();
    let response = await fetch(process.env.REACT_APP_API_URL + '/ExpenseLine', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token.idToken.rawIdToken,
      },
      body: JSON.stringify(model),
    });
    response = await response.json();
    return response;
  }
  //GET get expenses by contactID
  async getExpenses(contactId) {
    const token = await authProvider.getIdToken();
    let response = await fetch(process.env.REACT_APP_API_URL + '/Expenses?contactId=' + contactId, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token.idToken.rawIdToken,
      },
    });
    response = await response.json();
    return response;
  }
  //POST Upload to sharepoint
  async uploadToSharePoint(model) {
    const token = await authProvider.getIdToken();
    let response = await fetch(process.env.REACT_APP_API_URL + '/ExpenseLine/UploadToSharepoint', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token.idToken.rawIdToken,
      },
      body: JSON.stringify(model),
    });
    response = await response.json();

    return response;
  }
  //POST Upload File to sharepoint
  async uploadFileToSharePoint(file) {
    const token = await authProvider.getIdToken();
    let response = await fetch(process.env.REACT_APP_API_URL + '/ExpenseLine/upload', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token.idToken.rawIdToken,
      },
      body: file,
    });
    response = await response.json();

    return response;
  }
}

export default new ExpenseService();
