import { connect } from "react-redux";
import ExpenseRequest from "./ExpenseRequest";
import {
  currenciesGetStart,
  currenciesGetEnd,
  getExpenseAccountByContactId,
  setIsExistExpenseAccountForCurrentMonth,
  expenseAccountClicked,
  setExpenseId,
  homeClicked,
  setIsAccountCreation,
} from "../../../Redux/Actions/Actions";

function mapStateToProps(state) {
  return {
    currenciesList: state.expenses.currencies,
    contactId: state.identity.contactId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    homeClicked: () => dispatch(homeClicked()),
    expenseAccountClicked: () => dispatch(expenseAccountClicked()),
    currenciesGetStart: () => dispatch(currenciesGetStart()),
    currenciesGetEnd: (currencies) => dispatch(currenciesGetEnd(currencies)),
    getExpenseAccountByContactId: (data) =>
      dispatch(getExpenseAccountByContactId(data)),
    setIsExistExpenseAccountForCurrentMonth: (data) =>
      dispatch(setIsExistExpenseAccountForCurrentMonth(data)),
    setExpenseId: (data) => dispatch(setExpenseId(data)),
    setIsAccountCreation: (data) => dispatch(setIsAccountCreation(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseRequest);
