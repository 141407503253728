function ActivityReportRequestTableModel(year, month, comment, type, contactId, employeeType) {
    this.year = year || null;
    this.month = month || null;
    this.comment = comment || null;
    this.type = type || "Daily";
    this.contactId = contactId || null;
    this.employeeType = employeeType || null;
}

ActivityReportRequestTableModel.prototype.getYear = function(){
    return this.year
}

ActivityReportRequestTableModel.prototype.getMonth = function(){
    return this.month
}

ActivityReportRequestTableModel.prototype.getComment = function(){
    return this.comment
}

ActivityReportRequestTableModel.prototype.getContactId = function(){
    return this.contactId
}

ActivityReportRequestTableModel.prototype.getEmployeeType = function(){
    return this.employeeType
}

ActivityReportRequestTableModel.prototype.getType = function(){
    return this.type
}

ActivityReportRequestTableModel.prototype.setYear = function(year){
    this.year = year
}

ActivityReportRequestTableModel.prototype.setMonth = function(month){
    this.month = month
}

ActivityReportRequestTableModel.prototype.setComment = function(comment){
    this.comment = comment;
}

ActivityReportRequestTableModel.prototype.setType = function(type){
    this.type = type
}

module.exports = ActivityReportRequestTableModel;