import { authProvider } from "../../AuthUtil/authProvider";

class ActivityReportService {
  async getMissions(activityReportRequestTable) {
    const token = await authProvider.getIdToken();
    let response = await fetch(
      process.env.REACT_APP_API_URL +
        "/ActivityReports?" +
        new URLSearchParams(activityReportRequestTable).toString(),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token.idToken.rawIdToken,
        },
      }
    );
    response = await response.json();
    return response;
  }

  async getLogo(logoRequestModel) {
    const token = await authProvider.getIdToken();
    let response = await fetch(
      process.env.REACT_APP_API_URL +
        "/ActivityReport/Logo?" +
        new URLSearchParams(logoRequestModel).toString(),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token.idToken.rawIdToken,
        },
      }
    );
    response = await response.json();
    return response;
  }

  async addActivityReports(activityReportRequestSave) {
    const token = await authProvider.getIdToken();
    let response = await fetch(
      process.env.REACT_APP_API_URL + "/ActivityReport",
      {
        method:
          typeof activityReportRequestSave.missions[0].activityReportId ===
          "string"
            ? "PUT"
            : "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token.idToken.rawIdToken,
        },
        body: JSON.stringify(activityReportRequestSave),
      }
    );
    response = await response.json();
    return response;
  }

  async uploadActivityReports(
    files,
    activityReportRequestUpload,
    invoice = null,
    invoiceRequestUpload = null
  ) {
    const token = await authProvider.getIdToken();
    let formdata = new FormData();
    files.forEach((file) => {
      var blob = file.file.slice(0, file.file.size, "application/pdf");
      let newFile = new File(
        [blob],
        file.name + "." + file.file.type.split("/")[1],
        { type: "application/pdf" }
      );
      formdata.append("files", newFile);
    });

    await fetch(process.env.REACT_APP_API_URL + "/ActivityReport/Upload", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token.idToken.rawIdToken,
      },
      body: formdata,
    });

    let response = await fetch(
      process.env.REACT_APP_API_URL + "/ActivityReport/UploadToSharepoint",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token.idToken.rawIdToken,
        },
        body: JSON.stringify(activityReportRequestUpload),
      }
    );

    response = await response.json();

    if (invoice !== null) {
      let formdata = new FormData();
      var blob = invoice.slice(0, invoice.size, invoice.type);
      let newFile = new File(
        [blob],
        invoiceRequestUpload.contactId + "." + invoice.type.split("/")[1],
        { type: invoice.type }
      );
      formdata.append("files", newFile);

      await fetch(
        process.env.REACT_APP_API_URL + "/ActivityReport/uploadInvoice",
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token.idToken.rawIdToken,
          },
          body: formdata,
        }
      );

      response = await fetch(
        process.env.REACT_APP_API_URL +
          "/ActivityReport/UploadInvoiceToSharepoint",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token.idToken.rawIdToken,
          },
          body: JSON.stringify(invoiceRequestUpload),
        }
      );

      response = await response.json();
    }

    return response;
  }
}

export default new ActivityReportService();
