import React from 'react';
import { Modal } from 'react-bootstrap';

export default function SpinnerModal(props) {
	return (
		<Modal show={props.show} onHide={() => {}} centered>
			<Modal.Body>
				<div className='modal__body'>
					<div className='spinner'>
						<div className='dot'></div>
						<div className='dot'></div>
						<div className='dot'></div>
						<div className='dot'></div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
}
