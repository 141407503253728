import { connect } from 'react-redux';
import ActivityReportTable from './ActivityReportTable';
import {
  activityReportDeleteClicked,
  activityReportValueChange,
  activityReportAddActivityClicked,
  activityReportTableMounted,
  activityReportActivityChange,
} from '../../../Redux/Actions/Actions';

function mapStateToProps(state) {
  return {
    employeeType: state.identity.type,
    busy: state.activityReport.busy,
    lines: state.activityReport.lines,
    footerLine: state.activityReport.footerLine,
    daysOfMonth: state.activityReport.daysOfMonth,
    daysOfWork: state.activityReport.daysOfWork,
    selectedType: state.activityReport.selectedType,
    availableActivities: state.activityReport.availableActivities,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    delete: (line) => dispatch(activityReportDeleteClicked(line)),
    valueChange: (newValue, indexLine, indexDay, selectedType) =>
      dispatch(activityReportValueChange(newValue, indexLine, indexDay, selectedType)),
    addActivity: (employeeType) => dispatch(activityReportAddActivityClicked(employeeType)),
    componentMounted: (employeeType) => dispatch(activityReportTableMounted(employeeType)),
    activityChange: (selectedActivity, lineIndex) =>
      dispatch(activityReportActivityChange(selectedActivity, lineIndex)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityReportTable);
