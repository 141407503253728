import {
    LEAVE_REQUEST_DATE_CHANGE,
    LEAVE_REQUEST_TYPE_CHANGE,
    LEAVE_REQUEST_ATTESTATION_CHANGE,
    LEAVE_REQUEST_NUMBER_OF_DAYS_CHANGE,
    NEW_LEAVE_REQUEST_CLICKED,
    LEAVE_REQUEST_CONSULT_CLICKED,
    LEAVE_REQUEST_GET_END,
    LEAVE_REQUEST_GET_FILE_END,
    LEAVE_REQUEST_DESCRIPTION_CHANGE,
} from '../../Actions/Actions'

const leaveRequestInitialState = {
    initialDate: {
        date: undefined,
        moment: 'Morning'
    },
    endDate: {
        date: undefined,
        moment: 'Afternoon'
    },
    type: "Ponctual",
    sickAttestation: undefined,
    numberOfDays: 0,
    leaveRequest: null,
    canCancel: false,
    canModify: true,
    status: null,
    description: ''
}

const acceptedType = ["image","pdf"];

function leaveRequest(state = leaveRequestInitialState, action){
    switch(action.type){
        case LEAVE_REQUEST_GET_END:
            return{
                ...state,
                initialDate: {
                    date: new Date(action.response.startingDate),
                    moment: action.response.startingTime?"Afternoon":"Morning"
                },
                endDate: {
                    date: new Date(action.response.endingDate),
                    moment: action.response.endingTime?"Afternoon":"Morning"
                },
                numberOfDays: action.response.numberOfDays,
                type: action.response.type,
                canCancel: action.response.canCancel,
                canModify: action.response.canModify,
                status: action.response.status,
                description: action.response.description,
                sickLRName: action.response.sickLRName
            }
        case LEAVE_REQUEST_GET_FILE_END:
            return {
                ...state,
                sickLRLink: action.response.sickLRLink
            }
        case LEAVE_REQUEST_CONSULT_CLICKED:
            return{
                ...leaveRequestInitialState,
                leaveRequest: action.leaveRequest
            }
        case NEW_LEAVE_REQUEST_CLICKED:
            return leaveRequestInitialState;
        case LEAVE_REQUEST_NUMBER_OF_DAYS_CHANGE:
            return{
                ...state,
                numberOfDays: action.newNumber
            }
        case LEAVE_REQUEST_ATTESTATION_CHANGE:
            let validFormat = acceptedType.some(format => action.newAttestation.type.includes(format));
            let validSize = action.newAttestation.size < 3 * 1024 * 1024;
            if (validFormat && validSize) return{
                ...state,
                sickAttestation: action.newAttestation,
                sickLRName: null,
                sickLRLink: null
            }
            else return state;
        case LEAVE_REQUEST_TYPE_CHANGE:
            return{
                ...state,
                type: action.newType
            }
        case LEAVE_REQUEST_DATE_CHANGE:
            return {
                ...state,
                initialDate: action.startDate,
                endDate: action.endDate
            }
        case LEAVE_REQUEST_DESCRIPTION_CHANGE:
            return{
                ...state,
                description: action.newDescription
            }
        default:
            return state;
    }
}

export default leaveRequest;