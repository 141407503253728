export const EXPENSE_DONE_CLICKED = 'EXPENSE_DONE_CLICKED';
export const EXPENSE_CONSULT_CLICKED = 'EXPENSE_CONSULT_CLICKED';

export const EXPENSE_NEW_EXPENSE_CLICKED = 'EXPENSE_NEW_EXPENSE_CLICKED';
export const EXPENSE_GET_CURRENCIES = 'EXPENSE_GET_CURRENCIES';
export const EXPENSE_STARTED_GET_CURRENCIES = 'EXPENSE_STARTED_GET_CURRENCIES';
export const EXPENSE_FINISHED_GET_CURRENCIES = 'EXPENSE_FINISHED_GET_CURRENCIES';
export const EXPENSE_OK_BUTTON_CLICKED = 'EXPENSE_OK_BUTTON_CLICKED';
export const EXPENSE_CURRENCY_CHANGE = 'EXPENSE_CURRENCY_CHANGE';
export const EXPENSE_IS_SET_ANY_CURRENCY = 'EXPENSE_IS_SET_ANY_CURRENCY';
export const EXPENSE_CHECKBOX_CLICKED = 'EXPENSE_CHECKBOX_CLICKED';
export const EXPENSE_NEW_EXPENSE_ACCOUNT_ID = 'EXPENSE_NEW_EXPENSE_ACCOUNT_ID';

//new account steps
export const EXPENSE_CREATE_NEW_ACCOUNT_STEP_1 = 'EXPENSE_CREATE_NEW_ACCOUNT_STEP_1';
export const EXPENSE_CREATE_NEW_ACCOUNT_STEP_2 = 'EXPENSE_CREATE_NEW_ACCOUNT_STEP_2';
export const EXPENSE_CREATE_NEW_ACCOUNT_STEP_3_ADD_FORM = 'EXPENSE_CREATE_NEW_ACCOUNT_STEP_3_ADD_FORM';
export const EXPENSE_CREATE_NEW_ACCOUNT_STEP_4_COMPLETE = 'EXPENSE_CREATE_NEW_ACCOUNT_STEP_4_COMPLETE';
export const EXPENSE_LEAVE_CREATE_ACCOUNT = 'EXPENSE_LEAVE_CREATE_ACCOUNT';

//manage account
export const EXPENSE_GET_START = 'EXPENSE_GET_START';
export const EXPENSE_GET_END = 'EXPENSE_GET_END';
export const EXPENSE_IT_IS_SEND_BUTTON_AVALIBLE = 'EXPENSE_IT_IS_SEND_BUTTON_AVALIBLE';
export const EXPENSE_IS_VALID_BEFORE_SEND = 'EXPENSE_IS_VALID_BEFORE_SEND';

//remove expenseLine from redux state
export const EXPENSE_REMOVE_EXPENSE_LINE_FROM_STATE = 'EXPENSE_REMOVE_EXPENSE_LINE_FROM_STATE';

export const EXPENSE_GET_EXPENSE_ACCOUNT_BY_CONTACT_ID = 'EXPENSE_GET_EXPENSE_ACCOUNT_BY_CONTACT_ID';
export const EXPENSE_SET_IS_EXIST_ACCOUNT_FOR_CURRENT_MONTH = 'EXPENSE_SET_IS_EXIST_ACCOUNT_FOR_CURRENT_MONTH';

export const ADD_EXPENSE_CLICKED = 'ADD_EXPENSE_CLICKED';
export const EXPENSE_ACCOUNT_CLICKED = 'EXPENSE_ACCOUNT_CLICKED';
export const EXPENSE_SET_CURRENCY_FOR_EXPENSE_ACCOUNT = 'EXPENSE_SET_CURRENCY_FOR_EXPENSE_ACCOUNT';
export const EXPENSE_SET_EXPENSE_ID = 'EXPENSE_SET_EXPENSE_ID';
export const EXPENSE_ACCOUNT_CREATION = 'EXPENSE_ACCOUNT_CREATION';
//error
export const EXPENSE_SET_ERROR = 'EXPENSE_SET_ERROR';

export const expenseDoneClicked = (valid) => ({
	type: EXPENSE_DONE_CLICKED,
	valid: valid,
});

export const expenseConsultClicked = (id, status) => ({
	type: EXPENSE_CONSULT_CLICKED,
	id: id,
	status: status,
});

export const newExpenseClicked = () => ({
	type: EXPENSE_NEW_EXPENSE_CLICKED,
});
export const getCurrencies = (currencies) => ({
	type: EXPENSE_GET_CURRENCIES,
	payload: {
		currencies,
	},
});
export const startedFetchingCurrencies = () => ({
	type: EXPENSE_STARTED_GET_CURRENCIES,
});
export const finishedFetchningCurrencies = (data) => ({
	type: EXPENSE_FINISHED_GET_CURRENCIES,
	data,
});

export const okButtonClicked = (data) => ({
	type: EXPENSE_OK_BUTTON_CLICKED,
	data,
});

export const currencyChange = (currencyId, currencyCode) => ({
	type: EXPENSE_CURRENCY_CHANGE,
	payload: {
		currencyId,
		currencyCode,
	},
});

export const isSetAnyCurrency = (isSetAnyCurrency) => ({
	type: EXPENSE_IS_SET_ANY_CURRENCY,
	payload: {
		isSetAnyCurrency,
	},
});
export const accountCreateNextStepTwo = () => ({
	type: EXPENSE_CREATE_NEW_ACCOUNT_STEP_2,
});
export const accountCreateStepThree = () => ({
	type: EXPENSE_CREATE_NEW_ACCOUNT_STEP_3_ADD_FORM,
});
export const accountCreateStepFour = () => ({
	type: EXPENSE_CREATE_NEW_ACCOUNT_STEP_4_COMPLETE,
});
export const accountCreateStepOne = () => ({
	type: EXPENSE_CREATE_NEW_ACCOUNT_STEP_1,
});

export const leaveCreateAccountForm = () => ({
	type: EXPENSE_LEAVE_CREATE_ACCOUNT,
});

export const expenseGetStart = () => ({
	type: EXPENSE_GET_START,
});

export const setErrorToExpense = (error) => ({
	type: EXPENSE_SET_ERROR,
	payload: {
		error,
	},
});
export const expenseGetEnd = (data) => ({
	type: EXPENSE_GET_END,
	data: data,
});

export const setSendButtonAvalibity = (data) => ({
	type: EXPENSE_IT_IS_SEND_BUTTON_AVALIBLE,
	payload: {
		data,
	},
});

export const expenseCheckboxClicked = () => ({
	type: EXPENSE_CHECKBOX_CLICKED,
});
export const changeIsValidToSend = (data) => ({
	type: EXPENSE_IS_VALID_BEFORE_SEND,
	payload: {
		data,
	},
});

export const setIdOfNewExpenseAccount = (data) => ({
	type: EXPENSE_NEW_EXPENSE_ACCOUNT_ID,
	payload: {
		data,
	},
});

//New
export const getExpenseAccountByContactId = (data) => ({
	type: EXPENSE_GET_EXPENSE_ACCOUNT_BY_CONTACT_ID,
	payload: {
		data,
	},
});

export const setIsExistExpenseAccountForCurrentMonth = (data) => ({
	type: EXPENSE_SET_IS_EXIST_ACCOUNT_FOR_CURRENT_MONTH,
	payload: {
		data,
	},
});

export const expenseAccountClicked = () => ({
	type: EXPENSE_ACCOUNT_CLICKED,
});

export const setCurrencyForExpenseAccount = (data) => ({
	type: EXPENSE_SET_CURRENCY_FOR_EXPENSE_ACCOUNT,
	payload: {
		data,
	},
});

export const setExpenseId = (data) => ({
	type: EXPENSE_SET_EXPENSE_ID,
	payload: { data },
});

export const setIsAccountCreation = (data) => ({
	type: EXPENSE_ACCOUNT_CREATION,
	payload: { data },
});
