import React from 'react';
import './Home.css';

export default function Home(props) {
	const cards = [
		{
			title: 'Activity Report 📈',
			description: (
				<>
					<p>Because I am nice, I promise ✋ :</p>
					<br />
					<p>- to complete my activity report by</p>
					the 25th of each month,
					<br />
					<br />
					<p>
						- that on the 5th of the following month at the latest, I will submit the pdf of my activity report signed
						by the client.
					</p>
					<br />
					<p> All the staff thanks you 🙏</p>
				</>
			),
			add: 'ACTIVITY',
			addHandler: props.seeActivityReport,
			employee: false,
		},
		{
			title: 'Leave request 🏝',
			description: (
				<>
					<p>
						Because I'm a forward-thinking person 🧐, I'm making my leave request at least 2 weeks before my departure
						date.
					</p>
					<br />
					<p>
						❗️Warning: for health issues, summer vacation requests must be at least for 2 consecutive weeks between
						June 1st and October 31st.
					</p>
					<br />
					<p>Have a nice trip ✌️</p>
				</>
			),
			add: 'NEW REQUEST',
			history: 'MY REQUESTS',
			addHandler: props.newLeaveRequest,
			historyHandler: props.seeLeaveRequests,
			employee: true,
		},
		{
			title: 'Expense account 💸',
			description: (
				<>
					<p>Time ⏱ is money 💰!</p>
					<br />
					<p>
						For organizational reasons (sometime life is not fun 🙁), to be refund the 10th of the month you have to
						submit your expense account before the 5th.
					</p>
					<br />
					<p>The accountant 🤡</p>
				</>
			),
			add: 'NEW EXPENSE',
			history: 'MY EXPENSES',
			addHandler: props.newExpense,
			historyHandler: props.myExpense,
			employee: true,
		},
	];

	return (
		<div className='home'>
			<div className='title'>
				<h2 className='home__label'>Welcome on Coconut {props.firstName} !</h2>
				<div className='home__sublabel'>Make your administrative procedures easy ✅</div>
			</div>
			<div className='home__wrapper'>
				<div className='home__flexbox'>
					{cards.map(
						(card, index) =>
							(props.employeeType === 'Employee' || !card.employee) && (
								<div key={card.title + index} className='home__card'>
									<div>
										<h3 className='home__title'>{card.title}</h3>
										<p className='home__text'>{card.description}</p>
									</div>
									<div className='home__group'>
										<button className='home__btn btn btn--primary' onClick={card.addHandler}>
											{card.add}
										</button>
										{card.history && card.historyHandler && (
											<button className='home__btn btn--primary btn' onClick={card.historyHandler}>
												{card.history}
											</button>
										)}
									</div>
								</div>
							)
					)}
				</div>
			</div>
		</div>
	);
}
