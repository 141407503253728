import {
  ADD_EXPENSE_CLICKED,
  ADD_EXPENSE_DATE_CHANGE,
  ADD_EXPENSE_DESCRIPTION_CHANGE,
  ADD_EXPENSE_TYPE_CHANGE,
  ADD_EXPENSE_DEPARTURE_CHANGE,
  ADD_EXPENSE_ARRIVAL_CHANGE,
  ADD_EXPENSE_ROUND_TRIP_CLICKED,
  ADD_EXPENSE_AMOUNT_TAX_CHANGE,
  ADD_EXPENSE_AMOUNT_TAX_FREE_CHANGE,
  ADD_EXPENSE_NIGHTS_CHANGE,
  ADD_EXPENSE_PERSONS_CHANGE,
  ADD_EXPENSE_RECEIPT_CHANGE,
  ADD_EXPENSE_AMOUNT_CHANGE,
  ADD_EXPENSE_POWER_CHANGE,
  ADD_EXPENSE_KILOMETERS_CHANGE,
  ADD_EXPENSE_VEHICLE_CHANGE,
  ADD_EXPENSE_CURRENCY_CHANGE,
} from "../../Actions/Actions";

const date = new Date();

const initialState = {
  date: date,
  description: "",
  type: "Choose a type",
  departure: "",
  arrival: "",
  roundTrip: false,
  amountTax: "",
  amountTaxFree: "",
  nights: "",
  persons: "",
  receipt: null,
  power: "3 CV and less",
  kilometers: "",
  vehicle: "Car",
  currency: "",
};

const acceptedType = ["png", "jpeg", "pdf"];

const addExpense = (state = initialState, action) => {
  switch (action.type) {
    case ADD_EXPENSE_CURRENCY_CHANGE:
      return {
        ...state,
        currency: action.currency,
      };
    case ADD_EXPENSE_VEHICLE_CHANGE:
      return {
        ...state,
        vehicle: action.vehicle,
      };
    case ADD_EXPENSE_KILOMETERS_CHANGE:
      return {
        ...state,
        kilometers: action.kilometers,
      };
    case ADD_EXPENSE_POWER_CHANGE:
      return {
        ...state,
        power: action.power,
      };
    case ADD_EXPENSE_AMOUNT_CHANGE:
      return {
        ...state,
        amountTax: action.amount,
        amountTaxFree: action.amount,
      };
    case ADD_EXPENSE_RECEIPT_CHANGE:
      let file = {};
      if (action.receipt) {
        file = action.receipt;
        let FileSize = action.receipt.size / 1024 / 1024; // in MB

        const isValid = acceptedType.some((format) =>
          file.type.includes(format)
        );

        if (FileSize > 3) {
          file = false;
        } else if (!isValid) {
          file = false;
        }
      } else {
        file = null;
      }
      return {
        ...state,
        receipt: file,
      };
    case ADD_EXPENSE_PERSONS_CHANGE:
      return {
        ...state,
        persons: action.persons,
      };
    case ADD_EXPENSE_NIGHTS_CHANGE:
      return {
        ...state,
        nights: action.nights,
      };
    case ADD_EXPENSE_AMOUNT_TAX_FREE_CHANGE:
      return {
        ...state,
        amountTaxFree: action.amountTaxFree,
      };
    case ADD_EXPENSE_AMOUNT_TAX_CHANGE:
      return {
        ...state,
        amountTax: action.amountTax,
      };
    case ADD_EXPENSE_ROUND_TRIP_CLICKED:
      return {
        ...state,
        roundTrip: !state.roundTrip,
      };
    case ADD_EXPENSE_ARRIVAL_CHANGE:
      return {
        ...state,
        arrival: action.arrival,
      };
    case ADD_EXPENSE_DEPARTURE_CHANGE:
      return {
        ...state,
        departure: action.departure,
      };
    case ADD_EXPENSE_TYPE_CHANGE:
      return {
        ...state,
        type: action.newType,
      };
    case ADD_EXPENSE_DESCRIPTION_CHANGE:
      return {
        ...state,
        description: action.description,
      };
    case ADD_EXPENSE_CLICKED:
      return initialState;
    case ADD_EXPENSE_DATE_CHANGE:
      return {
        ...state,
        date: action.date,
      };
    default:
      return state;
  }
};

export default addExpense;
