import { authProvider } from '../../../AuthUtil/authProvider';


class LeaveRequestServices {
    async getNumberOfDays(numberOfDaysModel){
        const token = await authProvider.getIdToken();
        let response = await fetch(process.env.REACT_APP_API_URL + '/LeaveRequest/CalculateDays?' + new URLSearchParams(numberOfDaysModel).toString(), {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.idToken.rawIdToken
            }
        });
        response = await response.json();
        return response;
    }

    async addLeaveRequest(leaveRequestModel){
        const token = await authProvider.getIdToken();
        let response = await fetch(process.env.REACT_APP_API_URL + '/LeaveRequest', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.idToken.rawIdToken
            },
            body: JSON.stringify(leaveRequestModel)
        });
        response = await response.json();
        return response;
    }

    async uploadSickLeave(file, uploadSickLeaveModel){
        const token = await authProvider.getIdToken();
        let formdata = new FormData();
        var blob = file.slice(0, file.size, file.type); 
        let newFile = new File([blob], uploadSickLeaveModel.leaveRequestId + "." + file.type.split("/")[1], {type: file.type});
        formdata.append('files',  newFile)
        await fetch(process.env.REACT_APP_API_URL + '/LeaveRequest/Upload', {
            method: 'POST',
            headers:{
                'Authorization': 'Bearer ' + token.idToken.rawIdToken
            },
            body: formdata
        });
        let response = await fetch(process.env.REACT_APP_API_URL + '/LeaveRequest/UploadToSharepoint', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.idToken.rawIdToken
            },
            body: JSON.stringify(uploadSickLeaveModel)
        });
        return await response.json();
    }

    async getLeaveRequest(leaveRequestId){
        const token = await authProvider.getIdToken();
        let response = await fetch(process.env.REACT_APP_API_URL + '/LeaveRequest?leaveRequestId=' + leaveRequestId, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.idToken.rawIdToken
            }
        });
        response = await response.json();
        return response;
    }

    async getLeaveRequestFile(uploadSickLeaveModel) {
        const token = await authProvider.getIdToken();
        let response = await fetch(process.env.REACT_APP_API_URL + '/LeaveRequest/GetFile', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.idToken.rawIdToken   
            },
            body: JSON.stringify(uploadSickLeaveModel)
        })
        response = await response.json();
        return response;
    }

    async putLeaveRequest(leaveRequestModel){
        const token = await authProvider.getIdToken();
        let response = await fetch(process.env.REACT_APP_API_URL + '/LeaveRequest', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.idToken.rawIdToken
            },
            body: JSON.stringify(leaveRequestModel)
        });
        response = await response.json();
        return response;
    }
}

export default new LeaveRequestServices();