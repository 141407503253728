export const EXPENSES_GET_END = 'EXPENSES_GET_END'
export const EXPENSES_GET_START = 'EXPENSES_GET_START'

export const EXPENSES_PAGINATION_CLICKED = 'EXPENSES_PAGINATION_CLICKED'
export const EXPENSES_MY_EXPENSES_CLICKED = "EXPENSES_MY_EXPENSES_CLICKED";

export const expensesPaginationClicked = (newPage) => ({
    type: EXPENSES_PAGINATION_CLICKED,
    newPage: newPage
})

export const expensesGetStart = () => ({
    type: EXPENSES_GET_START
})

export const expensesGetEnd = (response) => ({
    type: EXPENSES_GET_END,
    response: response
})

export const expensesMyExpensesClicked = () => ({
    type: EXPENSES_MY_EXPENSES_CLICKED,
  });