import React, { Component } from 'react';
import { Modal, Spinner } from 'react-bootstrap';

export default class LoadingSynchro extends Component {
  render = () => {
    return (
      <Modal show={this.props.identityState === 'CONNECTED'} onHide={() => {}} centered>
        <Modal.Header>
          <Modal.Title>Synchronize informations</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='modal__body'>
            <div className='spinner'>
              <div className='dot'></div>
              <div className='dot'></div>
              <div className='dot'></div>
              <div className='dot'></div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn--primary' onClick={this.props.logout}>
            Logout
          </button>
        </Modal.Footer>
      </Modal>
    );
  };
}
