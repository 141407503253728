import { connect } from 'react-redux'
import SpinnerModal from './SpinnerModal'


function mapStateToProps(state){
    return {
        show: state.spinnerShow
    }
}

export default connect(mapStateToProps)(SpinnerModal)