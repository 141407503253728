import { connect } from 'react-redux'
import Logs from './Logs'

import {
    logsGetEnd,
    logsGetError
} from '../Redux/Actions/Actions'

function mapStateToProps(state){
    return {
        logs: state.logs.logs,
        message: state.logs.message
    }
}

function mapDispatchToProps(dispatch){
    return {
        getEnd: (logs) => dispatch(logsGetEnd(logs)),
        getError: () => dispatch(logsGetError())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Logs)