import {
  EXPENSE_GET_EXPENSE_ACCOUNT_BY_CONTACT_ID,
  EXPENSE_SET_IS_EXIST_ACCOUNT_FOR_CURRENT_MONTH,
  EXPENSE_SET_EXPENSE_ID,
  EXPENSE_ACCOUNT_CREATION,
} from "../../Actions/Actions";

const initialState = {
  ExpenseAccounts: [],
  IsExistExpenseAccountForCurrentMonth: false,
  currentExpenseId: "",
  isAccountCreation: false,
};

const ExpenseAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case EXPENSE_GET_EXPENSE_ACCOUNT_BY_CONTACT_ID: {
      return {
        ...state,
        ExpenseAccounts: action.payload.data,
      };
    }
    case EXPENSE_SET_IS_EXIST_ACCOUNT_FOR_CURRENT_MONTH: {
      return {
        ...state,
        IsExistExpenseAccountForCurrentMonth: action.payload.data,
      };
    }
    case EXPENSE_SET_EXPENSE_ID: {
      return {
        ...state,
        currentExpenseId: action.payload.data,
      };
    }
    case EXPENSE_ACCOUNT_CREATION: {
      return {
        ...state,
        isAccountCreation: action.payload.data,
      };
    }
    default:
      return state;
  }
};

export default ExpenseAccountReducer;
