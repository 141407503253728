import { connect } from "react-redux";
import AddExpense from "./AddExpense";

import {
  addExpenseDateChange,
  addExpenseDescriptionChange,
  addExpenseTypeChange,
  addExpenseBackClicked,
  addExpenseDepartureChange,
  addExpenseArrivalChange,
  addExpenseRoundTripClicked,
  addExpenseAmountTaxChange,
  addExpenseAmountTaxFreeChange,
  addExpenseNightsChange,
  addExpensePersonsChange,
  addExpenseReceiptChange,
  addExpenseAmountChange,
  addExpensePowerChange,
  addExpenseVehicleChange,
  currenciesGetStart,
  currenciesGetEnd,
  addExpenseCurrencyChange,
  addExpenseKilometersChange,
  addExpensePostStart,
  addExpensePostEnd,
} from "../../Redux/Actions/Actions";

function mapStateToProps(state) {
  return {
    month: state.expenses.expense.month,
    date: state.expenses.addExpense.date,
    description: state.expenses.addExpense.description,
    type: state.expenses.addExpense.type,
    departure: state.expenses.addExpense.departure,
    arrival: state.expenses.addExpense.arrival,
    roundTrip: state.expenses.addExpense.roundTrip,
    amountTax: state.expenses.addExpense.amountTax,
    amountTaxFree: state.expenses.addExpense.amountTaxFree,
    nights: state.expenses.addExpense.nights,
    persons: state.expenses.addExpense.persons,
    receipt: state.expenses.addExpense.receipt,
    power: state.expenses.addExpense.power,
    kilometers: state.expenses.addExpense.kilometers,
    vehicle: state.expenses.addExpense.vehicle,
    currencies: state.expenses.currencies,
    currency: state.expenses.addExpense.currency,
    expenseId: state.expenses.expense.id,
    firstName: state.identity.firstName,
    lastName: state.identity.lastName,
    businessUnitId: state.identity.businessUnitId,
    ownerid: state.expenses.expense.ownerid,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dateChange: (date) => dispatch(addExpenseDateChange(date)),
    descriptionChange: (description) =>
      dispatch(addExpenseDescriptionChange(description)),
    typeChange: (type) => dispatch(addExpenseTypeChange(type)),
    backClicked: () => dispatch(addExpenseBackClicked()),
    departureChange: (departure) =>
      dispatch(addExpenseDepartureChange(departure)),
    arrivalChange: (arrival) => dispatch(addExpenseArrivalChange(arrival)),
    roundTripClicked: () => dispatch(addExpenseRoundTripClicked()),
    amountTaxChange: (amountTax) =>
      dispatch(addExpenseAmountTaxChange(amountTax)),
    amountTaxFreeChange: (amountTaxFree) =>
      dispatch(addExpenseAmountTaxFreeChange(amountTaxFree)),
    nightsChange: (nights) => dispatch(addExpenseNightsChange(nights)),
    personsChange: (persons) => dispatch(addExpensePersonsChange(persons)),
    receiptChange: (receipt) => dispatch(addExpenseReceiptChange(receipt)),
    amountChange: (amount) => dispatch(addExpenseAmountChange(amount)),
    powerChange: (power) => dispatch(addExpensePowerChange(power)),
    vehicleChange: (vehicle) => dispatch(addExpenseVehicleChange(vehicle)),
    currenciesGetStart: () => dispatch(currenciesGetStart()),
    currenciesGetEnd: (currencies) => dispatch(currenciesGetEnd(currencies)),
    currencyChange: (currency) => dispatch(addExpenseCurrencyChange(currency)),
    kilometersChange: (kilometers) =>
      dispatch(addExpenseKilometersChange(kilometers)),
    postStart: () => dispatch(addExpensePostStart()),
    postEnd: () => dispatch(addExpensePostEnd()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddExpense);
