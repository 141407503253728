export const LOGIN = 'AAD_LOGIN_SUCCESS'
export const LOGOUT = 'AAD_LOGOUT_SUCCESS'
export const IDENTITY_GET_END = 'IDENTITY_GET_END'
export const IDENTITY_GET_ERROR = 'IDENTITY_GET_ERROR'
export const HOME_CLICKED = 'HOME_CLICKED'

export const homeClicked = () => ({
    type: HOME_CLICKED
})

export const identityGetEnd = data => ({
    type: IDENTITY_GET_END,
    data: data
})

export const identityGetError = () => ({
    type: IDENTITY_GET_ERROR
})