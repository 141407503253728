export const ADD_EXPENSE_CLICKED = "ADD_EXPENSE_CLICKED";
export const ADD_EXPENSE_BACK_CLICKED = "ADD_EXPENSE_BACK_CLICKED";
export const ADD_EXPENSE_ROUND_TRIP_CLICKED = "ADD_EXPENSE_ROUND_TRIP_CLICKED";

export const ADD_EXPENSE_DATE_CHANGE = "ADD_EXPENSE_DATE_CHANGE";
export const ADD_EXPENSE_DESCRIPTION_CHANGE = "ADD_EXPENSE_DESCRIPTION_CHANGE";
export const ADD_EXPENSE_TYPE_CHANGE = "ADD_EXPENSE_TYPE_CHANGE";
export const ADD_EXPENSE_DEPARTURE_CHANGE = "ADD_EXPENSE_DEPARTURE_CHANGE";
export const ADD_EXPENSE_ARRIVAL_CHANGE = "ADD_EXPENSE_ARRIVAL_CHANGE";
export const ADD_EXPENSE_AMOUNT_TAX_CHANGE = "ADD_EXPENSE_AMOUNT_TAX_CHANGE";
export const ADD_EXPENSE_AMOUNT_TAX_FREE_CHANGE =
  "ADD_EXPENSE_AMOUNT_TAX_FREE_CHANGE";
export const ADD_EXPENSE_NIGHTS_CHANGE = "ADD_EXPENSE_NIGHTS_CHANGE";
export const ADD_EXPENSE_PERSONS_CHANGE = "ADD_EXPENSE_PERSONS_CHANGE";
export const ADD_EXPENSE_RECEIPT_CHANGE = "ADD_EXPENSE_RECEIPT_CHANGE";
export const ADD_EXPENSE_AMOUNT_CHANGE = "ADD_EXPENSE_AMOUNT_CHANGE";
export const ADD_EXPENSE_POWER_CHANGE = "ADD_EXPENSE_POWER_CHANGE";
export const ADD_EXPENSE_KILOMETERS_CHANGE = "ADD_EXPENSE_KILOMETERS_CHANGE";
export const ADD_EXPENSE_VEHICLE_CHANGE = "ADD_EXPENSE_VEHICLE_CHANGE";
export const ADD_EXPENSE_CURRENCY_CHANGE = "ADD_EXPENSE_CURRENCY_CHANGE";
export const ADD_EXPENSE_POST_START = "ADD_EXPENSE_POST_START";
export const ADD_EXPENSE_POST_END = "ADD_EXPENSE_POST_END";

export const addExpensePostStart = () => ({
  type: ADD_EXPENSE_POST_START,
});

export const addExpensePostEnd = () => ({
  type: ADD_EXPENSE_POST_END,
});

export const addExpenseCurrencyChange = (currency) => ({
  type: ADD_EXPENSE_CURRENCY_CHANGE,
  currency: currency,
});

export const addExpenseVehicleChange = (vehicle) => ({
  type: ADD_EXPENSE_VEHICLE_CHANGE,
  vehicle: vehicle,
});

export const addExpenseKilometersChange = (kilometers) => ({
  type: ADD_EXPENSE_KILOMETERS_CHANGE,
  kilometers: kilometers,
});

export const addExpensePowerChange = (power) => ({
  type: ADD_EXPENSE_POWER_CHANGE,
  power: power,
});

export const addExpenseAmountChange = (amount) => ({
  type: ADD_EXPENSE_AMOUNT_CHANGE,
  amount: amount,
});

export const addExpenseReceiptChange = (receipt) => ({
  type: ADD_EXPENSE_RECEIPT_CHANGE,
  receipt: receipt,
});

export const addExpensePersonsChange = (persons) => ({
  type: ADD_EXPENSE_PERSONS_CHANGE,
  persons: persons,
});

export const addExpenseNightsChange = (nights) => ({
  type: ADD_EXPENSE_NIGHTS_CHANGE,
  nights: nights,
});

export const addExpenseAmountTaxFreeChange = (amountTaxFree) => ({
  type: ADD_EXPENSE_AMOUNT_TAX_FREE_CHANGE,
  amountTaxFree: amountTaxFree,
});

export const addExpenseAmountTaxChange = (amountTax) => ({
  type: ADD_EXPENSE_AMOUNT_TAX_CHANGE,
  amountTax: amountTax,
});

export const addExpenseRoundTripClicked = () => ({
  type: ADD_EXPENSE_ROUND_TRIP_CLICKED,
});

export const addExpenseArrivalChange = (arrival) => ({
  type: ADD_EXPENSE_ARRIVAL_CHANGE,
  arrival: arrival,
});

export const addExpenseDepartureChange = (departure) => ({
  type: ADD_EXPENSE_DEPARTURE_CHANGE,
  departure: departure,
});

export const addExpenseBackClicked = () => ({
  type: ADD_EXPENSE_BACK_CLICKED,
});

export const addExpenseClicked = () => ({
  type: ADD_EXPENSE_CLICKED,
});

export const addExpenseDateChange = (date) => ({
  type: ADD_EXPENSE_DATE_CHANGE,
  date: date,
});

export const addExpenseDescriptionChange = (description) => ({
  type: ADD_EXPENSE_DESCRIPTION_CHANGE,
  description: description,
});

export const addExpenseTypeChange = (newType) => ({
  type: ADD_EXPENSE_TYPE_CHANGE,
  newType: newType,
});
