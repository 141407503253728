function LeaveRequestModel(	startingDate, startingTime, endingDate, endingTime, type, numberOfDaysOfHolidays, contactId, description, leaveRequestId) {
    this.contactId = contactId || null;
    this.startingDate = startingDate || null;
    this.startingTime = startingTime;
    this.endingDate = endingDate || null;
    this.endingTime = endingTime;
    this.type = type || null;
    this.numberOfDaysOfHolidays = numberOfDaysOfHolidays || null;
    this.leaveRequestId = leaveRequestId || null;
    this.description = description || null;
}

module.exports = LeaveRequestModel;