import { authProvider } from '../../../../AuthUtil/authProvider';


class CancelLeaveRequestServices {
    async cancelLeaveRequest(LeaveRequestId){
        const token = await authProvider.getIdToken();
        let response = await fetch(process.env.REACT_APP_API_URL + '/LeaveRequest/cancel', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.idToken.rawIdToken
            },
            body: JSON.stringify({leaveRequestId: LeaveRequestId})
        });
        response = await response.json();
        return response;
    }
}

export default new CancelLeaveRequestServices();