import React from 'react';
import ExpenseService from '../../../Services/ExpensesServices';
import { Modal } from 'react-bootstrap';

export default class CancelExpense extends React.Component {
  async delete() {
    let id = this.props.id;
    //change value of remove to true
    this.props.deleteStart();
    let response = await ExpenseService.removeExpenseLine(id);
    //stop spinner
    this.props.deleteEnd(response.message);
    this.props.fetch();
  }

  render = () => {
    return (
      <Modal show={this.props.id !== null} onHide={this.props.cancel}>
        <Modal.Header closeButton>
          <Modal.Title>Remove expense</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Do you confirm the removing of your expense from the {this.props.date} of type {this.props.type} ?
        </Modal.Body>
        <Modal.Footer className='modal__footer'>
          <button className='btn btn--outlined' onClick={this.props.cancel}>
            Close
          </button>
          <button className='btn btn--primary' onClick={this.delete.bind(this)}>
            Remove
          </button>
        </Modal.Footer>
      </Modal>
    );
  };
}
