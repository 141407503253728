import { connect } from 'react-redux'
import ConfirmSaveAr from './ConfirmSaveAr'
import {
    activityReportCloseConfirmModalClicked
} from '../../../Redux/Actions/Actions'


function mapStateToProps(state){
    return {
        modalShow: state.activityReport.confirmModalShow
    }
}

function mapDispatchToProps(dispatch){
    return {
        hideModal: () => dispatch(activityReportCloseConfirmModalClicked())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmSaveAr)