import React, { useEffect, useState } from 'react';
import { Alert, Form, Tab, Tabs } from 'react-bootstrap';
import { FaArrowRight, FaDownload, FaPaperclip, FaPlus, FaSave, FaTrash, FaUpload } from 'react-icons/fa';

import SpinnerModal from '../App/Component/SpinnerModal/SpinnerModal';
import ActivityReportTableContainer from './Components/ActivityReportTable/ActivityReportTableContainer';
import ConfirmSaveArContainer from './Components/ConfirmSaveAr/ConfirmSaveArContainer';
import CraPdfDownloaderContainer from './Components/CraPdf/CraPdfDownloaderContainer';
import DeleteActivityContainer from './Components/DeleteActivity/DeleteActivityContainer';
import ActivityReportService from './Services/ActivityReportService';

import './ActivityReport.css';

import ActivityReportRequestSaveModel from './Models/ActivityReportRequestSaveModel';
import ActivityReportRequestTableModel from './Models/ActivityReportRequestTableModel';
import InvoiceRequestSaveModel from './Models/InvoiceRequestSaveModel';
import LogoUrlRequestModel from './Models/LogoUrlRequestModel';

const internalActivities = [
  'Annual Leave',
  'Ponctual Leave',
  'Unpaid Leave',
  'Conference/ MeetUp',
  'Sick Leave',
  'Training',
  'Other Leave',
];
const externalActivities = ['External Leave'];

const monthOfYear = [
  { value: 0, label: 'January' },
  { value: 1, label: 'February' },
  { value: 2, label: 'March' },
  { value: 3, label: 'April' },
  { value: 4, label: 'May' },
  { value: 5, label: 'June' },
  { value: 6, label: 'July' },
  { value: 7, label: 'August' },
  { value: 8, label: 'September' },
  { value: 9, label: 'October' },
  { value: 10, label: 'November' },
  { value: 11, label: 'December' },
];

const date = new Date();
const currentYear = date.getFullYear();
const currentMonth = date.getMonth();

export default function ActivityReport(props) {
  const [isOpen, setIsOpen] = useState(false);

  const selectedActivities = props.lines.reduce((acc, line) => {
    if (props.employeeType === 'Employee') {
      if (internalActivities.includes(line.name)) {
        return [...acc, line.name]
      }
    } else {
      if (externalActivities.includes(line.name)) {
        return [...acc, line.name]
      }
    }

    return acc
  }, [])

  let activities = []

  if (props.employeeType === 'Employee') {
    activities = internalActivities.filter(activity => !selectedActivities.includes(activity))
  } else {
    activities = externalActivities.filter(activity => !selectedActivities.includes(activity))
  }

  const activityReportRequestTable = new ActivityReportRequestTableModel(
    props.selectedYear,
    props.selectedMonth,
    props.comment,
    props.selectedType,
    props.contactId,
    props.employeeType
  );

  useEffect(() => {
    activityReportRequestTable.setYear(currentYear);
    activityReportRequestTable.setMonth(currentMonth);
    updateLines(true);
  }, []);

  async function updateLines(toMoveOnThird) {
    props.getStart();
    try {
      const response = await ActivityReportService.getMissions(activityReportRequestTable);
      const ready =
        response.lines.every((line) => line.type !== 'M' || typeof line.activityReportId === 'string') &&
        response.lines.length !== 0;
        
      props.getEnd({
        lines: response.lines,
        footerLine: response.footer,
        daysOfWork: response.daysOfWork,
        selectedYear: activityReportRequestTable.getYear(),
        selectedMonth: activityReportRequestTable.getMonth(),
        comment: response.comment,
        daysOfMonth: response.daysOfMonth,
        message: response.message,
        selectedType: response.type || 'Daily',
        ready: ready,
        activeKey: toMoveOnThird && ready ? 'Attach' : 'Activity-Report',
      });
    } catch (error) {
      props.getError();
    }
  }

  const onChangeMonth = (event) => {
    activityReportRequestTable.setMonth(parseInt(event.target.value));
    activityReportRequestTable.setYear(props.selectedYear);
    updateLines(false);
  };

  const onChangeYear = (event) => {
    activityReportRequestTable.setYear(event.target.value);
    activityReportRequestTable.setMonth(parseInt(props.selectedMonth));
    updateLines(false);
  };

  const onChangeComment = (event) => {
    activityReportRequestTable.setComment(event.target.value);
    props.getEnd({
      comment: activityReportRequestTable.getComment()
    })
  }

  const onClickSave = async () => {
    setIsOpen(true);
    if (!props.busy) {
      const emptyLine = props.lines.filter((line) => line.type === 'M' && line.total === 0);
      if (props.footerLine.total < props.daysOfWork) {
        setIsOpen(false);
        props.totalTooLow();
      } else if (emptyLine.length > 0) {
        setIsOpen(false);
        props.emptyLines();
      } else {
        await sendDataToCrm();
        // After send data to CRM go on next tab
        setIsOpen(false);
        props.changeKey('Print');
      }
    }
  };

  async function generateDownloadClicked(activity) {
    if (!activity.ARBertek) {
      try {
        props.getLogoStart();
        const logoUrlRequestModel = new LogoUrlRequestModel(activity.contractId, activity.logo.substring(0, 3));
        const response = await ActivityReportService.getLogo(logoUrlRequestModel);
        activity.logo = response.result;
        props.getLogoEnd();
      } catch (e) {
        props.getLogoError();
      }
    }
    props.showDownload(activity);
  }

  async function sendDataToCrm() {
    const activityReportRequestSave = new ActivityReportRequestSaveModel(
      props.selectedYear,
      props.selectedMonth,
      props.comment,
      props.selectedType,
      props.contactId,
      props.lines
    );
    props.postStart();
    const response = await ActivityReportService.addActivityReports(activityReportRequestSave);
    props.postEnd(response);
  }

  async function uploadFile() {
    props.filesPostStart();
    const files = [];
    let invoiceRequestModel = null;
    const activityReportsId = [];
    props.lines
      .filter((line) => line.type === 'M' && line.file !== null)
      .forEach((line) => {
        files.push({ name: line.activityReportId, file: line.file });
        activityReportsId.push(line.activityReportId);
        line.otherFiles.forEach((file, index) =>
          files.push({
            name: line.activityReportId + '-' + (index + 1).toString(),
            file: file,
          })
        );
      });

    const activityReportRequestModel = {
      firstName: props.firstName,
      lastName: props.lastName,
      businessUnitId: props.businessUnitId,
      activityReportsId: activityReportsId,
      type: props.type,
    };

    if (props.invoice !== null)
      invoiceRequestModel = new InvoiceRequestSaveModel(
        props.firstName,
        props.lastName,
        props.businessUnitId,
        props.contactId,
        props.type
      );

    try {
      const response = await ActivityReportService.uploadActivityReports(
        files,
        activityReportRequestModel,
        props.invoice,
        invoiceRequestModel
      );
      props.filesPostEnd(response);
    } catch (e) {
      props.filesPostError();
    }
  }
  return (
    <div>
      <SpinnerModal show={isOpen} />
      <div className='title'></div>
      <div className='activity'>
        <Tabs activeKey={props.activeKey} id='AR-tabs' onSelect={(e) => props.changeKey(e)}>
          <Tab eventKey='Activity-Report' title='Activity Report'>
            {props.message !== null && (
              <Alert variant={props.message.type}>
                <p>{props.message.value}</p>
              </Alert>
            )}
            <div className='activity__table'>
              <Form className='activity__date'>
                <Form.Control
                  as='select'
                  size='sm'
                  onChange={onChangeMonth}
                  defaultValue={props.selectedMonth}
                  value={props.selectedMonth}
                  disabled={props.busy}>
                  {monthOfYear.map((month) => (
                    <option key={'month-' + month.value} value={month.value}>
                      {month.label}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control
                  as='select'
                  size='sm'
                  onChange={onChangeYear}
                  defaultValue={props.selectedYear}
                  value={props.selectedYear}
                  disabled={props.busy}>
                  {props.selectYear.map((year) => (
                    <option key={'year-' + year} value={year}>
                      {year}
                    </option>
                  ))}
                </Form.Control>
              </Form>
              {props.lines.length !== 0 ? (
                <ActivityReportTableContainer />
              ) : (
                <div className='print__title'>Loading...</div>
              )}
              {props.lines.length !== 0 && (
                <button
                  disabled={!activities.length}
                  key='header-0-button'
                  className='print__attach btn btn--secondary'
                  onClick={() => props.addActivity(props.employeeType)}>
                  <FaPlus className='print__icon' />
                  Add activity
                </button>
              )}
            </div>
            <div className='activity__header'>
              <Form>
                <Form.Control
                  as='textarea'
                  rows='2'
                  cols='100'
                  onChange={onChangeComment}
                  placeholder='Add more information... (optional)'
                  value={props.comment ? props.comment : ""}
                  disabled={props.lines[0] ? !props.lines[0].canModified : false}
                  maxLength={200}
                />
              </Form>
              {props.lines.length !== 0 && (
                <button
                  className='print__attach btn btn--primary'
                  disabled={props.busy}
                  onClick={onClickSave}>
                  <FaSave className='print__icon' />
                  Save
                </button>
              )}
            </div>
          </Tab>
          <Tab eventKey='Print' title='Print the client AR' disabled={!props.ready}>
            {props.message !== null && (
              <Alert variant={props.message.type}>
                <p>{props.message.value}</p>
              </Alert>
            )}
            <p className='print__title'>
              Choose a language for your AR and print it so the client and yourself can sign it
            </p>
            <ul className='print__cards'>
              {props.lines
                .filter((line) => line.type === 'M')
                .map((activity) => (
                  <li key={'ActivityReportDownloader' + activity.client} className='print__card'>
                    <header className='print__header'>
                      <b>{activity.client}</b>
                    </header>
                    <div className='print__main'>
                      <div
                        className={`print__radio btn--primary ${activity.lang === 'English' ? 'print__checked' : ''}`}
                        onClick={() => props.langChange(activity.client, 'English')}>
                        <span role='img' aria-label='English'>
                          🇬🇧
                        </span>
                      </div>
                      <div
                        className={`print__radio btn--primary ${activity.lang === 'Polish' ? 'print__checked' : ''}`}
                        onClick={() => props.langChange(activity.client, 'Polish')}>
                        <span role='img' aria-label='Polish'>
                          🇵🇱
                        </span>
                      </div>
                      <div
                        className={`print__radio btn--primary ${activity.lang === 'French' ? 'print__checked' : ''}`}
                        onClick={() => props.langChange(activity.client, 'French')}>
                        <span role='img' aria-label='French'>
                          🇫🇷
                        </span>
                      </div>
                    </div>
                    <div
                      className='print__attach print__footer btn btn--primary'
                      onClick={() => generateDownloadClicked(activity)}>
                      <FaDownload className='print__icon' />
                      Generate
                    </div>
                  </li>
                ))}
            </ul>
            <div className='activity__footer'>
              <button className='print__attach btn btn--primary' onClick={() => props.changeKey('Attach')}>
                Next
                <FaArrowRight className='print__icon' />
              </button>
            </div>
            <CraPdfDownloaderContainer />
          </Tab>
          <Tab eventKey='Attach' title='Attach the signed AR' disabled={!props.ready}>
            {props.message !== null && (
              <Alert variant={props.message.type}>
                <p>{props.message.value}</p>
              </Alert>
            )}
            <p className='print__title'>
              We're almost done! When your AR is signed, scan it and join it here <br />
              If you have several AR, join your AR seperately
            </p>
            <ul className='print__cards'>
              {props.lines
                .filter((line) => line.type === 'M')
                .map((line, index) => (
                  <li className='print__card' key={'line-' + index}>
                    <header className='print__header'>
                      <b>{line.client}</b>
                    </header>
                    <div className='print__main'>
                      <div className={`${line.file === null ? 'print__attach btn--secondary' : 'print__attached'}`}>
                        <label
                          htmlFor={'file-' + index}
                          className={`print__label ${!line.file ? 'print__attach' : ''}`}>
                          {line.file === null ? (
                            <>
                              <FaPlus className='print__icon' />
                              Attach
                            </>
                          ) : (
                            line.file.name.replace('.pdf', '')
                          )}
                        </label>
                        <input
                          id={'file-' + index}
                          className='input-file'
                          type='file'
                          accept='.pdf'
                          onChange={(e) => props.fileChange(line.activityReportId, e.target.files[0])}
                        />
                      </div>
                      {line.file !== null && <FaPaperclip className='print__icon'></FaPaperclip>}
                    </div>
                    {line.otherFiles.map((file, fileIndex) => (
                      <div key={'otherFile' + index + fileIndex} className='print__main'>
                        <div className='print__attach print__attached'>
                          <label className='print__label'>{file.name.replace('.pdf', '')}</label>
                        </div>
                        <FaTrash
                          className='print__icon'
                          onClick={() => props.fileDelete(line.activityReportId, fileIndex)}
                        />
                      </div>
                    ))}
                    {line.file !== null && (
                      <div className='print__main'>
                        <div className='print__attach btn--secondary'>
                          <FaPlus className='print__icon' />
                          <label htmlFor={'fileAdd-' + index} className='print__add'>
                            Add other
                          </label>
                          <input
                            id={'fileAdd-' + index}
                            className='input-file'
                            type='file'
                            accept='image/*,.pdf'
                            onChange={(e) => props.fileAdd(line.activityReportId, e.target.files[0])}
                          />
                        </div>
                      </div>
                    )}
                  </li>
                ))}
              {props.type === 'Freelance' && (
                <li className='print__card'>
                  <header className='print__header'>
                    <b>Add Invoice (optional)</b>
                  </header>
                  <div className='print__main'>
                    <div className={`${props.invoice === null ? 'print__attach btn--secondary' : 'print__attached'}`}>
                      <label
                        htmlFor={'file-invoice'}
                        className={`print__label ${props.invoice === null ? 'print__attach' : ''}`}>
                        {props.invoice === null ? (
                          <>
                            <FaPlus className='print__icon' />
                            Attach
                          </>
                        ) : (
                          props.invoice.name
                        )}
                      </label>
                      <input
                        id={'file-invoice'}
                        className='input-file'
                        type='file'
                        accept='.pdf'
                        onChange={(e) => props.invoiceChange(e.target.files[0])}
                      />
                    </div>
                    {props.invoice !== null && <FaPaperclip className='print__icon'></FaPaperclip>}
                  </div>
                </li>
              )}
            </ul>
            <div className='activity__footer'>
              <button
                className='print__attach activity__btn btn btn--primary'
                key='button-upload'
                disabled={props.disableUpload}
                onClick={uploadFile.bind(this)}>
                <FaUpload className='print__icon' />
                Upload
              </button>
            </div>
          </Tab>
        </Tabs>
      </div>
      <ConfirmSaveArContainer confirmSaveAr={sendDataToCrm.bind(this)} />
      <DeleteActivityContainer />
    </div>
  );
}
