import { connect } from 'react-redux'
import CraPdfDownloader from './CraPdfDownloader'
import {
    activityReportCloseDownloadModalClicked
} from '../../../Redux/Actions/Actions'

function mapStateToProps(state){
    return {
        name: state.identity.state==='SYNCHRONISED' ? state.identity.firstName + ' ' + state.identity.lastName : '',
        modalShow: state.activityReport.downloadModalShow,
        selectedYear: state.activityReport.selectedYear,
        selectedMonth: state.activityReport.selectedMonth,
        comment: state.activityReport.comment,
        activity: state.activityReport.selectedDownloadActivity
    }
}

function mapDispatchToProps(dispatch){
    return {
        hideDownloadActivityModal: () => dispatch(activityReportCloseDownloadModalClicked())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CraPdfDownloader)