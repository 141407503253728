import React from 'react';
import { AuthenticationState } from 'react-aad-msal';

import HomeContainer from '../Home/HomeContainer';
import ActivityReportContainer from '../ActivityReport/ActivityReportContainer';
import LeaveRequestContainer from '../LeaveRequests/LeaveRequest/LeaveRequestContainer';
import LeaveRequestsContainer from '../LeaveRequests/LeaveRequests/LeaveRequestsContainer';
import ExpensesContainer from '../Expenses/Expenses/ExpensesContainer';
import ExpenseContainer from '../Expenses/Expense/ExpenseContainer';
import AddExpenseContainer from '../Expenses/AddExpense/AddExpenseContainer';
import NewExpenseAccount from '../Expenses/Expense/NewExpenseAddAccount/ExpenseRequestContainer';

import LogsContainer from '../Logs/LogsContainer';

import './Login.css';

export default function MainContent(props) {
  switch (props.authenticationState) {
    case AuthenticationState.Authenticated:
      switch (props.currentPage) {
        case 'ActivityReport':
          return <ActivityReportContainer />;
        case 'LeaveRequest':
          return <LeaveRequestContainer />;
        case 'LeaveRequests':
          return <LeaveRequestsContainer />;
        case 'Logs':
          return <LogsContainer />;
        case 'Expense':
          return <ExpenseContainer />;
        case 'Expenses':
          return <ExpensesContainer />;
        case 'NewExpense':
          return <NewExpenseAccount />;
        case 'AddExpense':
          return <AddExpenseContainer />;
        default:
          return <HomeContainer />;
      }
    case AuthenticationState.InProgress:
      return (
        <div className='login'>
          <div className='login__aside'>
            <h1 className='login__title'>Authenticating...</h1>
            <button className='login__btn' onClick={props.login}>
              Retry
            </button>
          </div>
          <div className='login__cube' />
          <div className='login__cube' />
          <div className='login__cube' />
          <div className='login__cube' />
          <div className='login__cube' />
          <div className='login__cube' />
          <div className='login__cube' />
        </div>
      );
    default:
      return (
        <div className='login'>
          <aside className='login__aside'>
            <h1 className='login__title'>Welcome on Extranet</h1>
            <h3 className='login__subtitle'>Please login to your account</h3>
            <button className='login__btn' onClick={props.login}>
              Login now
            </button>
          </aside>
          <div className='login__cube' />
          <div className='login__cube' />
          <div className='login__cube' />
          <div className='login__cube' />
          <div className='login__cube' />
          <div className='login__cube' />
          <div className='login__cube' />
        </div>
      );
  }
}
