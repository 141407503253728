import { LOGIN, LOGOUT, IDENTITY_GET_END, IDENTITY_GET_ERROR } from '../Actions/Actions';

const identityInitialState = {
  state: 'DISCONNECTED',
};

function identity(state = identityInitialState, action) {
  switch (action.type) {
    case IDENTITY_GET_ERROR:
      return {
        ...state,
        state: 'FAILED',
      };
    case IDENTITY_GET_END:
      return {
        state: 'SYNCHRONISED',
        ...action.data,
      };
    case LOGIN:
      return {
        state: 'CONNECTED',
        email: action.payload.account.userName,
      };
    case LOGOUT:
      return {
        state: 'DISCONNECTED',
      };
    default:
      return state;
  }
}

export default identity;
