import React, { useEffect } from 'react';
import { BreakpointProvider } from 'react-socks';

import NavBarContainer from '../NavBar/NavBarContainer';
import MainContentContainer from '../MainContent/MainContentContainer';
import LoadingSynchroContainer from './Component/LoadingSynchro/LoadingSynchroContainer';
import SpinnerModalContainer from './Component/SpinnerModal/SpinnerModalContainer';
import FailedSynchroContainer from './Component/FailedSynchro/FailedSynchroContainer';

import { authProvider } from '../AuthUtil/authProvider';
import { AuthenticationState } from 'react-aad-msal';
import { AzureAD } from 'react-aad-msal';

import { store } from '../Redux/Store';
import AppService from './Services/AppService';

import './App.css';

// store.subscribe(() => console.log(store.getState()));

export default function App(props) {
  const { identityState } = props;
  useEffect(() => {
    if (identityState === 'CONNECTED') updateIdentity();
  }, [identityState]);

  async function updateIdentity() {
    try {
      let infos = await AppService.getContactInformations(props.email);
      props.identityGetEnd(infos);
    } catch (e) {
      props.identityGetError();
    }
  }
  return (
    <BreakpointProvider>
      <AzureAD provider={authProvider} forceLogin={false} reduxStore={store}>
        {({ login, logout, authenticationState, error }) => {
          return (
            <div className='App'>
              {authenticationState === AuthenticationState.Authenticated && <NavBarContainer logout={logout} />}
              <div className='wrapper'>
                <MainContentContainer authenticationState={authenticationState} login={login} error={error} />
                <LoadingSynchroContainer logout={logout} />
                <FailedSynchroContainer logout={logout} />
                <SpinnerModalContainer />
              </div>
            </div>
          );
        }}
      </AzureAD>
    </BreakpointProvider>
  );
}
