import React from 'react';
import DatePicker from 'react-datepicker';
import { FaPlus, FaArrowLeft, FaPaperclip } from 'react-icons/fa';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import en from 'date-fns/locale/en-GB';

import 'react-datepicker/dist/react-datepicker.css';
import { Form, Alert } from 'react-bootstrap';
import ExpensesServices from '../Services/ExpensesServices';
import SpinnerModal from '../../App/Component/SpinnerModal/SpinnerModal';

registerLocale('en-GB', en);
setDefaultLocale('en-GB');

function pad(number) {
	if (number < 10) {
		return '0' + number;
	}
	return number;
}
const monthNames = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];
export default class AddExpense extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			symbol: false,
			currencyError: false,
			typeError: false,
			amountTaxError: false,
			amountTaxFreeError: false,
			receiptError: false,
			personsError: false,
			amountError: false,
			kilometersError: false,
			date: '',
			alertMessage: '',
			isModalOpen: false,
		};
	}
	formatDateToSend(date) {
		return date.getFullYear() + '-' + pad(date.getMonth() + 1) + '-' + pad(date.getDate());
	}

	componentDidMount() {
		if (this.props.currencies === null) {
			this.getCurrenciesAsync();
		}
		const date = new Date();
		date.setDate(date.getDate() - 5);
		const month = monthNames[date.getMonth()];
		const year = date.getFullYear();
		this.setState({ date: `${month} ${year}` });
	}
	async getCurrenciesAsync() {
		this.props.currenciesGetStart();
		let result = await ExpensesServices.getCurrencies();
		this.props.currenciesGetEnd(result);
	}
	componentDidUpdate(prevProps) {
		if (prevProps.type !== this.props.type) {
			this.setState({
				amountTaxError: false,
				amountTaxFreeError: false,
				receiptError: false,
				personsError: false,
				amountError: false,
				kilometersError: false,
				send: false,
			});
		}
		if (prevProps.currency !== this.props.currency)
			this.props.currencies.forEach((currency) => {
				if (currency.id === this.props.currency) {
					this.setState({ symbol: currency.symbol });
				} else if (this.props.currency === '') {
					this.setState({ symbol: false });
				}
			});
	}

	async onSubmitClicked() {
		this.setState({ isModalOpen: true });
		let isError = false;
		let requestModel = {};
		requestModel.expenseId = this.props.expenseId || null;
		requestModel.receipt = this.props.receipt ? this.props.receipt : null;
		requestModel.roundTrip = this.props.roundTrip || null;
		requestModel.administrativePower = this.props.power ? this.props.power : null;
		requestModel.vehicle = this.props.vehicle || null;
		requestModel.NBkilometers = (this.props.kilometers && parseInt(this.props.kilometers)) || null;
		requestModel.NBPersons = (this.props.persons && parseInt(this.props.persons)) || null;
		requestModel.NBNights = parseInt(this.props.nights) || null;
		requestModel.totalBrut = (this.props.amountTax && parseFloat(this.props.amountTax).toFixed(2)) || null;
		requestModel.totalNet = (this.props.amountTaxFree && parseFloat(this.props.amountTaxFree).toFixed(2)) || null;
		requestModel.arrival = this.props.arrival || null;
		requestModel.departure = this.props.departure || null;
		requestModel.currencyId = this.props.currency || null;
		requestModel.date = this.formatDateToSend(this.props.date) || null;
		requestModel.type = this.props.type || null;
		requestModel.description = this.props.description || null;
		requestModel.ownerId = this.props.ownerid || null;

		// Main values
		if (requestModel.currencyId === null) {
			isError = true;
			this.setState({ currencyError: true, isModalOpen: false });
		} else {
			this.setState({ currencyError: false });
		}
		if (requestModel.type === 'Choose a type') {
			isError = true;
			this.setState({ typeError: true, isModalOpen: false });
		} else {
			this.setState({ typeError: false });
		}

		//Amount with tax and tax free
		if (
			[
				'Train',
				'Plane',
				'Hotel',
				'Restaurant',
				'Highway',
				'Taxi',
				'Small Equipment',
				'Parking',
				'Post',
				'Electricity / Water',
				'Various',
			].includes(requestModel.type)
		) {
			if (!requestModel.totalBrut) {
				isError = true;
				this.setState({ amountTaxError: true, isModalOpen: false });
			} else {
				this.setState({ amountTaxError: false });
			}

			if (!requestModel.totalNet) {
				isError = true;
				this.setState({ amountTaxFreeError: true, isModalOpen: false });
			} else {
				this.setState({ amountTaxFreeError: false });
			}
		}

		//Number of Persons
		if (['Restaurant'].includes(requestModel.type)) {
			if (!requestModel.NBPersons) {
				isError = true;
				this.setState({ personsError: true, isModalOpen: false });
			} else {
				this.setState({ personsError: false });
			}
		}
		//Receipt
		if (
			[
				'Train',
				'Plane',
				'Hotel',
				'Restaurant',
				'Highway',
				'Taxi',
				'Rent',
				'Small Equipment',
				'Parking',
				'Post',
				'Electricity / Water',
			].includes(requestModel.type)
		) {
			if (!requestModel.receipt) {
				isError = true;
				this.setState({ alertMessage: 'Please attach receipt' });
				this.setState({ receiptError: true, isModalOpen: false });
			} else {
				this.setState({ receiptError: false });
				this.setState({ alertMessage: '' });
			}
		}

		//Amount
		if (['Rent'].includes(requestModel.type)) {
			if (!requestModel.totalNet) {
				isError = true;
				this.setState({ amountError: true, isModalOpen: false });
			} else {
				this.setState({ amountError: false });
			}
		}
		//Kilometric Allowance
		if (['Kilometric Allowances'].includes(requestModel.type)) {
			if (!requestModel.NBkilometers) {
				isError = true;
				this.setState({ kilometersError: true, isModalOpen: false });
			} else {
				this.setState({ kilometersError: false });
			}
		}

		if (!isError) {
			if (requestModel.type === 'Kilometric Allowances') {
				requestModel.type = 'Kilometric allowances';
				requestModel.totalBrut = 0;
				requestModel.totalNet = 0;
			}
			//Send expenseLine to CRM
			requestModel.totalBrut = parseFloat(requestModel.totalBrut);
			requestModel.totalNet = parseFloat(requestModel.totalNet);

			let result = await ExpensesServices.sendExpenseLine(requestModel);

			if (result.message.type === 'danger') {
				this.setState({ alertMessage: result.message.value, isModalOpen: false });
			} else {
				this.props.postStart();
				// Upload File to Sharepoint
				if (requestModel.receipt !== null) {
					let fileName = requestModel.receipt.name.split('.');
					fileName[0] = result.expenseLine.btk_expenselineid;
					fileName = fileName.join('.');
					const fileType = requestModel.receipt.type;
					const blob = requestModel.receipt.slice(0, requestModel.receipt.size, fileType);
					const newFile = new File([blob], fileName, { type: fileType });

					const data = new FormData();
					data.append('files', newFile);

					await ExpensesServices.uploadFileToSharePoint(data);

					//Send data about file

					const model = {
						expenseLineId: result.expenseLine.btk_expenselineid,
						firstName: this.props.firstName,
						lastName: this.props.lastName,
						//businessUnitId: "9079CB6E-81BA-E911-A824-000D3AB2DE59",
						businessUnitId: this.props.businessUnitId,
					};

					await ExpensesServices.uploadToSharePoint(model);
				}
				this.setState({ isModalOpen: false });
				this.props.postEnd();
			}
		}
	}

	render = () => {
		return (
			<>
				<SpinnerModal show={this.state.isModalOpen} />
				<div className='leaveRequest'>
					<section className='leaveRequest__section'>
						<h2 className='leaveRequest__title'>New Expense Account</h2>
						{this.props.receipt === false && (
							<div className='leaveRequest__alert'>
								<Alert variant='danger'>Please check file format and make sure file is smaller than 3 Mb.</Alert>
							</div>
						)}
						{this.state.alertMessage && (
							<div className='leaveRequest__alert'>
								<Alert variant='danger'>{this.state.alertMessage}</Alert>
							</div>
						)}

						<section className='leaveRequest__wrapper'>
							<div className='leaveRequest__flex'>
								<label className='leaveRequest__label'>Expense Account for:</label>
								<div className='leaveRequest__status'>{this.state.date}</div>
							</div>
							<div className='leaveRequest__flex'>
								<p className='leaveRequest__label'>Date of the expense: </p>
								<DatePicker
									className='leaveRequest__date'
									dateFormat='dd/MM/yyyy'
									maxDate={new Date()}
									selected={this.props.date}
									onChange={(date) => {
										this.props.dateChange(date);
									}}
								/>
							</div>

							{this.props.currencies !== null && (
								<div className='leaveRequest__flex'>
									<p className='leaveRequest__label'>Currency of the expense: </p>
									<Form className='leaveRequest__checkbox'>
										<Form.Control
											as='select'
											className='leaveRequest__select'
											value={this.props.currency}
											onChange={(e) => this.props.currencyChange(e.target.value)}
											isInvalid={this.state.currencyError}>
											<option hidden>Select</option>
											{this.props.currencies.map((currency) => (
												<option key={currency.id} value={currency.id}>
													{currency.code}
												</option>
											))}
										</Form.Control>
									</Form>
								</div>
							)}
							<div className='leaveRequest__flex leaveRequest__block--column'>
								<label className='leaveRequest__label'>Description (optional):</label>
								<Form className='leaveRequest__checkbox'>
									<Form.Control
										as='textarea'
										rows='3'
										cols='42'
										className='leaveRequest__textarea'
										value={this.props.description}
										onChange={(e) => this.props.descriptionChange(e.target.value)}
										maxLength={100}
									/>
								</Form>
							</div>
							<div className='leaveRequest__flex'>
								<label className='leaveRequest__label'>Type:</label>
								<Form className='leaveRequest__checkbox'>
									<Form.Control
										as='select'
										className='leaveRequest__select'
										value={this.props.type}
										onChange={(e) => this.props.typeChange(e.target.value)}
										isInvalid={this.state.typeError}>
										<option hidden>Select</option>
										<option>Kilometric Allowances</option>
										<option>Train</option>
										<option>Plane</option>
										<option>Highway</option>
										<option>Taxi</option>
										<option>Hotel</option>
										<option>Restaurant</option>
										<option>Small Equipment</option>
										<option>Parking</option>
										<option>Rent</option>
										<option>Post</option>
										<option>Electricity / Water</option>
										<option>Various</option>
									</Form.Control>
								</Form>
							</div>

							{['Kilometric Allowances'].includes(this.props.type) && (
								<div className='leaveRequest__flex'>
									<label className='leaveRequest__label'>Vehicle:</label>
									<div className='leaveRequest__status'>{this.props.vehicle}</div>
								</div>
							)}

							{['Kilometric Allowances'].includes(this.props.type) && (
								<div className='leaveRequest__flex'>
									<label className='leaveRequest__label'>Administrative Power:</label>
									<Form className='leaveRequest__checkbox'>
										<Form.Control
											as='select'
											value={this.props.power}
											className='leaveRequest__select'
											onChange={(e) => this.props.powerChange(e.target.value)}>
											<option hidden>Select</option>
											<option>3 CV and less</option>
											<option>4 HP</option>
											<option>5 HP</option>
											<option>6 HP</option>
											<option>7 HP and more</option>
										</Form.Control>
									</Form>
								</div>
							)}
							{['Kilometric Allowances'].includes(this.props.type) && (
								<div className='leaveRequest__flex'>
									<label className='leaveRequest__label'>Number of kilometers:</label>
									<Form className='leaveRequest__checkbox'>
										<Form.Control
											type='number'
											value={this.props.kilometers}
											onChange={(e) => this.props.kilometersChange(e.target.value)}
											isInvalid={this.state.kilometersError}
										/>
									</Form>
								</div>
							)}

							{['Kilometric Allowances', 'Train', 'Plane'].includes(this.props.type) && (
								<div className='leaveRequest__flex'>
									<label className='leaveRequest__label'>Departure Place:</label>
									<Form className='leaveRequest__checkbox'>
										<Form.Control
											type='text'
											value={this.props.departure}
											onChange={(e) => this.props.departureChange(e.target.value)}
										/>
									</Form>
								</div>
							)}

							{['Kilometric Allowances', 'Train', 'Plane'].includes(this.props.type) && (
								<div className='leaveRequest__flex'>
									<label className='leaveRequest__label'>Arrival Place:</label>
									<Form className='leaveRequest__checkbox'>
										<Form.Control
											type='text'
											value={this.props.arrival}
											onChange={(e) => this.props.arrivalChange(e.target.value)}
										/>
									</Form>
								</div>
							)}

							{['Kilometric Allowances', 'Train', 'Plane'].includes(this.props.type) && (
								<div className='leaveRequest__flex'>
									<label className='leaveRequest__label'>Round Trip:</label>
									<Form className='leaveRequest__check'>
										<Form.Check type='checkbox' value={this.props.roundTrip} onChange={this.props.roundTripClicked} />
										<div>Yes</div>
									</Form>
								</div>
							)}

							{['Hotel'].includes(this.props.type) && (
								<div className='leaveRequest__flex'>
									<label className='leaveRequest__label'>Number of nights:</label>
									<Form className='leaveRequest__checkbox'>
										<Form.Control
											type='number'
											value={this.props.nights}
											onChange={(e) => this.props.nightsChange(e.target.value)}
										/>
									</Form>
								</div>
							)}

							{['Restaurant'].includes(this.props.type) && (
								<div className='leaveRequest__flex'>
									<label className='leaveRequest__label'>Number of person:</label>
									<Form className='leaveRequest__checkbox'>
										<Form.Control
											type='number'
											value={this.props.persons}
											onChange={(e) => this.props.personsChange(e.target.value)}
											isInvalid={this.state.personsError}
										/>
									</Form>
								</div>
							)}
							{[
								'Train',
								'Plane',
								'Hotel',
								'Restaurant',
								'Highway',
								'Taxi',
								'Small Equipment',
								'Parking',
								'Post',
								'Electricity / Water',
								'Various',
							].includes(this.props.type) && (
								<div className='leaveRequest__flex'>
									<label className='leaveRequest__label'>Amount without VAT</label>
									<Form className='leaveRequest__checkbox'>
										<Form.Control
											type='number'
											value={this.props.amountTaxFree}
											onChange={(e) => this.props.amountTaxFreeChange(e.target.value)}
											isInvalid={this.state.amountTaxFreeError}
										/>
										{this.state.symbol !== false && <div className='leaveRequest__symbol'>{this.state.symbol}</div>}
									</Form>
								</div>
							)}
							{[
								'Train',
								'Plane',
								'Hotel',
								'Restaurant',
								'Highway',
								'Taxi',
								'Small Equipment',
								'Parking',
								'Post',
								'Electricity / Water',
								'Various',
							].includes(this.props.type) && (
								<div className='leaveRequest__flex'>
									<label className='leaveRequest__label'>Amount with VAT</label>
									<Form className='leaveRequest__checkbox'>
										<Form.Control
											type='number'
											value={this.props.amountTax}
											onChange={(e) => this.props.amountTaxChange(e.target.value)}
											isInvalid={this.state.amountTaxError}
										/>
										{this.state.symbol !== false && <div className='leaveRequest__symbol'>{this.state.symbol}</div>}
									</Form>
								</div>
							)}
							{['Rent'].includes(this.props.type) && (
								<div className='leaveRequest__flex'>
									<label className='leaveRequest__label'>Amount:</label>
									<Form className='leaveRequest__checkbox'>
										<Form.Control
											type='number'
											value={this.props.amountTaxFree}
											onChange={(e) => this.props.amountChange(e.target.value)}
											isInvalid={this.state.amountError}
										/>
									</Form>
								</div>
							)}
							{[
								'Train',
								'Plane',
								'Hotel',
								'Restaurant',
								'Highway',
								'Taxi',
								'Small Equipment',
								'Parking',
								'Post',
								'Electricity / Water',
								'Various',
								'Rent',
							].includes(this.props.type) && (
								<>
									<div className='print__main'>
										{this.props.receipt === null || this.props.receipt === false ? (
											<div className='print__attach btn--secondary'>
												<FaPlus className='print__icon' />
												<label htmlFor='receipt' className='print__label print__label--max'>
													Add a receipt
												</label>
												<input
													id='receipt'
													className='input-file'
													type='file'
													accept='.pdf'
													onChange={(e) => this.props.receiptChange(e.target.files[0])}
												/>
											</div>
										) : (
											<div className='print__attach print__attached'>
												<label htmlFor='receipt' className='print__label print__label--max'>
													{this.props.receipt.name}
												</label>
												<input
													id='receipt'
													className='input-file'
													type='file'
													accept='.pdf'
													onChange={(e) => this.props.receiptChange(e.target.files[0])}
												/>
												<FaPaperclip className='print__icon'></FaPaperclip>
											</div>
										)}
									</div>
								</>
							)}
						</section>
						<div className='leaveRequest__group'>
							<button className='print__attach btn leaveRequest__btn btn--outlined' onClick={this.props.backClicked}>
								<FaArrowLeft className='print__icon' />
								Back
							</button>
							<button className='btn leaveRequest__btn btn--primary' onClick={this.onSubmitClicked.bind(this)}>
								Send
							</button>
						</div>
					</section>
				</div>
			</>
		);
	};
}
