import {
    LEAVE_REQUEST_CANCEL_CONFIRM_CLICKED,
    LEAVE_REQUEST_CANCEL_HIDE_CLICKED,
    LEAVE_REQUEST_CANCEL_CLICKED
} from '../../Actions/Actions'

const toDeleteInitialState = null

function toDelete(state = toDeleteInitialState, action){
    switch(action.type){
        case LEAVE_REQUEST_CANCEL_CONFIRM_CLICKED:
        case LEAVE_REQUEST_CANCEL_HIDE_CLICKED:
            return null;
        case LEAVE_REQUEST_CANCEL_CLICKED:
            return action.leaveRequest
        default: 
            return state;
    }
}

export default toDelete;