import { connect } from "react-redux";
import {
  expenseGetStart,
  setErrorToExpense,
  expenseGetEnd,
  setSendButtonAvalibity,
  accountCreateStepThree,
  expenseCheckboxClicked,
  changeIsValidToSend,
  expenseDeleteClicked,
  leaveCreateAccountForm,
  expenseDoneClicked,
  addExpenseClicked,
  currenciesGetStart,
  currenciesGetEnd,
  setCurrencyForExpenseAccount,
  expenseDeleteEnd,
  expensesMyExpensesClicked,
} from "../../Redux/Actions/Actions";
import Expense from "./Expense";

function mapStateToProps(state) {
  return {
    lines: state.expenses.expense.lines,
    totalBrut: state.expenses.expense.totalBrut,
    totalNet: state.expenses.expense.totalNet,
    totalTax: state.expenses.expense.totalTax,
    SendButtonAvalibity: state.expenses.expense.SendExpenseLinesAvalibity,
    checkBoxState: state.expenses.expense.isCheckedSubmitCheckbox,
    isValidBeforeSend: state.expenses.expense.isValidBeforeSend,
    canModify: state.expenses.expense.canModify,
    month: state.expenses.expense.month,
    id: state.expenses.expense.id,
    expenseId: state.expenses.expenseAccount.currentExpenseId,
    checkbox: state.expenses.expense.checkbox,
    message: state.expenses.expense.message,
    currencyId: state.expenses.expense.currency,
    currencies: state.expenses.currencies,
    currencyModel: state.expenses.expense.currencyModel,
    isAccountCreation: state.expenses.expenseAccount.isAccountCreation,
  };
}

function mapDispatchStateToProps(dispatch) {
  return {
    getStart: () => dispatch(expenseGetStart()),
    setError: (error) => dispatch(setErrorToExpense(error)),
    getEnd: (data) => dispatch(expenseGetEnd(data)),
    setSendButtonExpenseLinesAvalibity: (data) =>
      dispatch(setSendButtonAvalibity(data)),
    moveToAddForm: () => dispatch(accountCreateStepThree()),
    checkboxClicked: () => dispatch(expenseCheckboxClicked()),
    changeIsValidToSend: (data) => dispatch(changeIsValidToSend(data)),
    deleteClicked: (id, type, date) =>
      dispatch(expenseDeleteClicked(id, type, date)),
    leaveCreateAccountForm: () => dispatch(leaveCreateAccountForm()),
    doneClicked: (valid) => dispatch(expenseDoneClicked(valid)),
    addExpense: () => dispatch(addExpenseClicked()),
    currenciesGetEnd: (data) => dispatch(currenciesGetEnd(data)),
    currenciesGetStart: () => dispatch(currenciesGetStart()),
    setCurrencyForExpenseAccount: (data) =>
      dispatch(setCurrencyForExpenseAccount(data)),
    expenseDeleteEnd: (data) => dispatch(expenseDeleteEnd(data)),
    expensesMyExpensesClicked: () => dispatch(expensesMyExpensesClicked()),
  };
}

export default connect(mapStateToProps, mapDispatchStateToProps)(Expense);
