import { connect } from 'react-redux'
import DeleteActivity from './DeleteActivity'
import {
    activityReportCloseDeleteModalClicked,
    activityReportConfirmDeleteClicked
} from '../../../Redux/Actions/Actions'


function mapStateToProps(state){
    return {
        modalShow: state.activityReport.activityModalShow,
        deleteLine: state.activityReport.selectedDeleteLine,
        lines: state.activityReport.lines
    }
}

function mapDispatchToProps(dispatch){
    return {
        hideModal: () => dispatch(activityReportCloseDeleteModalClicked()),
        delete: (indexLine) => dispatch(activityReportConfirmDeleteClicked(indexLine))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteActivity)