export const LEAVE_REQUEST_DATE_CHANGE = 'LEAVE_REQUEST_DATE_CHANGE'
export const LEAVE_REQUEST_TYPE_CHANGE = 'LEAVE_REQUEST_TYPE_CHANGE'
export const LEAVE_REQUEST_ATTESTATION_CHANGE = 'LEAVE_REQUEST_ATTESTATION_CHANGE'
export const LEAVE_REQUEST_NUMBER_OF_DAYS_CHANGE = 'LEAVE_REQUEST_NUMBER_OF_DAYS_CHANGE'
export const LEAVE_REQUEST_DESCRIPTION_CHANGE = 'LEAVE_REQUEST_DESCRIPTION_CHANGE'

export const NEW_LEAVE_REQUEST_CLICKED = 'NEW_LEAVE_REQUEST_CLICKED'
export const LEAVE_REQUEST_SUBMIT_CLICKED = 'LEAVE_REQUEST_SUBMIT_CLICKED'
export const LEAVE_REQUEST_CONSULT_CLICKED = 'LEAVE_REQUEST_CONSULT_CLICKED'

export const LEAVE_REQUEST_POST_END = 'LEAVE_REQUEST_POST_END'
export const SICK_ATTESTATION_POST_END = 'SICK_ATTESTATION_POST_END'
export const LEAVE_REQUEST_POST_ERROR = 'LEAVE_REQUEST_POST_ERROR'
export const LEAVE_REQUEST_GET_START = 'LEAVE_REQUEST_GET_START'
export const LEAVE_REQUEST_GET_END = 'LEAVE_REQUEST_GET_END'
export const LEAVE_REQUEST_GET_FILE_END = 'LEAVE_REQUEST_GET_FILE_END'

export const leaveRequestDescriptionChange = (newDescription) => ({
    type: LEAVE_REQUEST_DESCRIPTION_CHANGE,
    newDescription: newDescription
})

export const leaveRequestConsultClicked = (leaveRequest) => ({
    type: LEAVE_REQUEST_CONSULT_CLICKED,
    leaveRequest: leaveRequest
})

export const leaveRequestGetEnd = (response) => ({
    type: LEAVE_REQUEST_GET_END,
    response: response
})

export const leaveRequestGetFileEnd = (response) => ({
    type: LEAVE_REQUEST_GET_FILE_END,
    response: response
})

export const leaveRequestGetStart = () => ({
    type: LEAVE_REQUEST_GET_START
})

export const leaveRequestDateChange = (startDate, endDate, everythingSet) => ({
    type: LEAVE_REQUEST_DATE_CHANGE,
    startDate: startDate,
    endDate: endDate,
    everythingSet: everythingSet
})

export const leaveRequestTypeChange = newType => ({
    type: LEAVE_REQUEST_TYPE_CHANGE,
    newType: newType
})

export const leaveRequestAttestationChange = newAttestation => ({
    type: LEAVE_REQUEST_ATTESTATION_CHANGE,
    newAttestation: newAttestation
})

export const leaveRequestNumberOfDaysChange = newNumber => ({
    type: LEAVE_REQUEST_NUMBER_OF_DAYS_CHANGE,
    newNumber: newNumber
})

export const leaveRequestPostEnd = ( message, isSick) => ({
    type: LEAVE_REQUEST_POST_END,
    message: message,
    isSick: isSick
})

export const newLeaveRequestClicked = () => ({
    type: NEW_LEAVE_REQUEST_CLICKED
})

export const sickAttestationPostEnd = (message) => ({
    type: SICK_ATTESTATION_POST_END,
    message: message
})

export const leaveRequestPostError = () => ({
    type: LEAVE_REQUEST_POST_ERROR
})

export const leaveRequestSubmitClicked = () => ({
    type: LEAVE_REQUEST_SUBMIT_CLICKED
})