export const LEAVE_REQUEST_CANCEL_CLICKED = 'LEAVE_REQUEST_CANCEL_CLICKED'
export const LEAVE_REQUEST_CANCEL_HIDE_CLICKED = 'LEAVE_REQUEST_CANCEL_HIDE_CLICKED'
export const LEAVE_REQUEST_CANCEL_CONFIRM_CLICKED = 'LEAVE_REQUEST_CANCEL_CONFIRM_CLICKED'

export const LEAVE_REQUEST_CANCEL_POST_END = 'LEAVE_REQUEST_CANCEL_POST_END'


export const leaveRequestCancelPostEnd = (message) => ({
    type: LEAVE_REQUEST_CANCEL_POST_END,
    message: message
})

export const leaveRequestCancelConfirmClicked = () => ({
    type: LEAVE_REQUEST_CANCEL_CONFIRM_CLICKED
})

export const leaveRequestCancelClicked = (leaveRequest) => ({
    type: LEAVE_REQUEST_CANCEL_CLICKED,
    leaveRequest: leaveRequest
})

export const leaveRequestCancelHideClicked = () => ({
    type: LEAVE_REQUEST_CANCEL_HIDE_CLICKED
})
