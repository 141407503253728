export const LOGS_CLICKED = 'LOGS_CLICKED'
export const LOGS_GET_END = 'LOGS_GET_END'
export const LOGS_GET_ERROR = 'LOGS_GET_ERROR'

export const logsGetError = () => ({
    type: LOGS_GET_ERROR
})

export const logsGetEnd = (logs) => ({
    type: LOGS_GET_END,
    logs: logs
})

export const logsClicked = () => ({
    type: LOGS_CLICKED
})