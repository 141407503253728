import { connect } from 'react-redux'
import LoadingSynchro from './LoadingSynchro'


function mapStateToProps(state){
    return {
        identityState: state.identity.state
    }
}

export default connect(mapStateToProps)(LoadingSynchro)