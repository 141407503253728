import React from 'react';

import { Breakpoint } from 'react-socks';
import { Alert } from 'react-bootstrap';

import LogsServices from './Services/LogsServices';

export default class Logs extends React.Component {
  async componentDidMount() {
    try {
      let response = await LogsServices.getLogs();
      this.props.getEnd(response);
    } catch (e) {
      this.props.getError();
    }
  }

  render = () => {
    return (
      <>
        <Breakpoint large up>
          <section className='leaveRequests__section'>
            {this.props.message !== null && (
              <Alert variant={this.props.message.type}>
                <p>{this.props.message.value}</p>
              </Alert>
            )}
            <div className='leaveRequests__table'>
              <header className='leaveRequests__header'>
                <ul className='leaveRequests__list'>
                  <li className='leaveRequests__item'>Time</li>
                  <li className='leaveRequests__item'>Level</li>
                  <li className='leaveRequests__item'>Message</li>
                  <li className='leaveRequests__item'>Stack</li>
                </ul>
              </header>
              <div className='leaveRequests__main'>
                {this.props.logs.map((log) => (
                  <ul key={log.time} className='leaveRequests__list'>
                    <li className='leaveRequests__item'>{new Date(log.time).toUTCString()}</li>
                    <li className='leaveRequests__item'>{log.level}</li>
                    <li className='leaveRequests__item'>{log.message}</li>
                    <li className='leaveRequests__item'>{log.stack}</li>
                  </ul>
                ))}
              </div>
            </div>
          </section>
        </Breakpoint>
        <Breakpoint medium down>
          {this.props.message !== null && (
            <Alert variant={this.props.message.type}>
              <p>{this.props.message.value}</p>
            </Alert>
          )}
          {this.props.logs.map((log) => (
            <section key={log.time} className='leaveRequests__section'>
              <div className='leaveRequests__table'>
                <div className='leaveRequests__item'>
                  <div>Time:</div>
                  <div>{new Date(log.time).toUTCString()}</div>
                </div>
                <div className='leaveRequests__item'>
                  <div>Level:</div>
                  <div>{log.level}</div>
                </div>
                <div className='leaveRequests__item'>
                  <div>Message:</div>
                  <div>{log.message}</div>
                </div>
                <div className='leaveRequests__item'>
                  <div>Stack:</div>
                  <div>{log.stack}</div>
                </div>
              </div>
            </section>
          ))}
        </Breakpoint>
      </>
    );
  };
}
