import { authProvider } from '../../AuthUtil/authProvider';

class AppService {

    async getContactInformations(email){
        const token = await authProvider.getIdToken();
        let response = await fetch(process.env.REACT_APP_API_URL + '/Contact?email=' + email,{
            headers: {
                'Authorization': 'Bearer ' + token.idToken.rawIdToken
            }
        });
        return await response.json();
    }
}

export default new AppService();