import { combineReducers } from 'redux';

import currentPage from './CurrentPageReducer';
import identity from './IdentityReducer';
import activityReport from './ActivityReportReducer';
import leaveRequest from './LeaveRequests/LeaveRequestReducer';
import leaveRequests from './LeaveRequests/LeaveRequestsReducer';
import spinnerShow from './SpinnerShowReducer';
import logs from './LogsReducer';
import toDelete from './LeaveRequests/ToDeleteReducer';
import message from './LeaveRequests/MessageReducer';
import expenses from './ExpenseRequests/ExpensesReducer';
import expense from './ExpenseRequests/ExpenseReducer';
import toDeleteExpense from './ExpenseRequests/ToDeleteReducer';
import addExpense from './ExpenseRequests/AddExpenseReducer';
import currencies from './ExpenseRequests/CurrenciesReducer';
import expenseAccount from './ExpenseRequests/ExpenseAccountReducer';
const extranetApp = combineReducers({
  identity,
  currentPage,
  activityReport,
  spinnerShow,
  logs,
  leaveRequests: combineReducers({
    leaveRequest,
    leaveRequests,
    toDelete,
    message,
  }),
  expenses: combineReducers({
    expense,
    toDeleteExpense,
    addExpense,
    expenses,
    currencies,
    expenseAccount,
  }),
});

export default extranetApp;
