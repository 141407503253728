import {
	LEAVE_REQUEST_POST_END,
	NEW_LEAVE_REQUEST_CLICKED,
	SICK_ATTESTATION_POST_END,
	LEAVE_REQUEST_POST_ERROR,
	LEAVE_REQUESTS_CONSULT_CLICKED,
	LEAVE_REQUESTS_GET_END,
	LEAVE_REQUEST_CANCEL_POST_END,
	LEAVE_REQUEST_ATTESTATION_CHANGE,
} from '../../Actions/Actions';

const messageInitialState = null;

const acceptedType = ['image', 'pdf'];

function message(state = messageInitialState, action) {
	switch (action.type) {
		case NEW_LEAVE_REQUEST_CLICKED:
		case LEAVE_REQUESTS_CONSULT_CLICKED:
			return null;
		case LEAVE_REQUESTS_GET_END:
			return action.response.message || state;
		case LEAVE_REQUEST_POST_END:
		case SICK_ATTESTATION_POST_END:
		case LEAVE_REQUEST_CANCEL_POST_END:
			return action.message;
		case LEAVE_REQUEST_POST_ERROR:
			return {
				type: 'danger',
				value: "The server doesn't respond, please try again later.",
			};
		case LEAVE_REQUEST_ATTESTATION_CHANGE:
			let validFormat = acceptedType.some((format) => action.newAttestation.type.includes(format));
			let validSize = action.newAttestation.size < 3 * 1024 * 1024;
			if (!validFormat)
				return {
					type: 'danger',
					value: 'Please upload a file of type pdf or image.',
				};
			else if (!validSize)
				return {
					type: 'danger',
					value: 'Please upload a file smaller than 3Mb.',
				};
			else return null;
		default:
			return state;
	}
}

export default message;
