import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

export default class LoadingSynchro extends Component {
  render = () => {
    return (
      <Modal show={this.props.identityState === 'FAILED'} onHide={() => {}} centered>
        <Modal.Header>
          <Modal.Title>Account information problem</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          It looks there is a problem with your account. Please, contact your manager as soon as possible.
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn--primary' onClick={this.props.logout}>
            Logout
          </button>
        </Modal.Footer>
      </Modal>
    );
  };
}
