import {
  NEW_LEAVE_REQUEST_CLICKED,
  LOGS_CLICKED,
  LEAVE_REQUESTS_CONSULT_CLICKED,
  LEAVE_REQUEST_CONSULT_CLICKED,
  LEAVE_REQUEST_CANCEL_CONFIRM_CLICKED,
  LEAVE_REQUEST_POST_END,
  EXPENSE_NEW_EXPENSE_CLICKED,
  EXPENSES_MY_EXPENSES_CLICKED,
  SICK_ATTESTATION_POST_END,
  ACTIVITY_REPORT_CLICKED,
  HOME_CLICKED,
  EXPENSE_CONSULT_CLICKED,
  EXPENSE_DONE_CLICKED,
  ADD_EXPENSE_CLICKED,
  EXPENSE_ACCOUNT_CLICKED,
  ADD_EXPENSE_BACK_CLICKED,
  ADD_EXPENSE_POST_END,
} from "../Actions/Actions";

const currentPageInitialState = "Home";

function currentPage(state = currentPageInitialState, action) {
  switch (action.type) {
    case HOME_CLICKED:
      return "Home";
    case ACTIVITY_REPORT_CLICKED:
      return "ActivityReport";
    case LEAVE_REQUEST_CANCEL_CONFIRM_CLICKED:
    case LEAVE_REQUESTS_CONSULT_CLICKED:
    case SICK_ATTESTATION_POST_END:
      return "LeaveRequests";
    case LOGS_CLICKED:
      return "Logs";
    case NEW_LEAVE_REQUEST_CLICKED:
    case LEAVE_REQUEST_CONSULT_CLICKED:
      return "LeaveRequest";
    case EXPENSE_NEW_EXPENSE_CLICKED:
      return "NewExpense";
    case EXPENSES_MY_EXPENSES_CLICKED:
      return "Expenses";
    case EXPENSE_CONSULT_CLICKED:
    case ADD_EXPENSE_BACK_CLICKED:
    case ADD_EXPENSE_POST_END:
    case EXPENSE_ACCOUNT_CLICKED:
      return "Expense";
    case ADD_EXPENSE_CLICKED:
      return "AddExpense";
    case EXPENSE_DONE_CLICKED:
      return action.valid ? "Expenses" : "Expense";
    case LEAVE_REQUEST_POST_END:
      return action.isSick ? "LeaveRequest" : "LeaveRequests";
    default:
      return state;
  }
}

export default currentPage;
