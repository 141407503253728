export const ACTIVITY_REPORT_LANG_CHANGE = 'ACTIVITY_REPORT_LANG_CHANGE';
export const ACTIVITY_REPORT_TYPE_CHANGE = 'ACTIVITY_REPORT_TYPE_CHANGE';
export const ACTIVITY_REPORT_FILE_CHANGE = 'ACTIVITY_REPORT_FILE_CHANGE';
export const ACTIVITY_REPORT_VALUE_CHANGE = 'ACTIVITY_REPORT_VALUE_CHANGE';
export const ACTIVITY_REPORT_ACTIVITY_CHANGE = 'ACTIVITY_REPORT_ACTIVITY_CHANGE';
export const ACTIVITY_REPORT_INVOICE_CHANGE = 'ACTIVITY_REPORT_INVOICE_CHANGE';

export const ACTIVITY_REPORT_GENERATE_DOWNLOAD_CLICKED = 'ACTIVITY_REPORT_GENERATE_DOWNLOAD_CLICKED';
export const ACTIVITY_REPORT_CLOSE_DOWNLOAD_MODAL_CLICKED = 'ACTIVITY_REPORT_CLOSE_DOWNLOAD_MODAL_CLICKED';
export const ACTIVITY_REPORT_CLOSE_CONFIRM_MODAL_CLICKED = 'ACTIVITY_REPORT_CLOSE_CONFIRM_MODAL_CLICKED';
export const ACTIVITY_REPORT_TABS_CLICKED = 'ACTIVITY_REPORT_TABS_CLICKED';
export const ACTIVITY_REPORT_CLOSE_DELETE_MODAL_CLICKED = 'ACTIVITY_REPORT_CLOSE_DELETE_MODAL_CLICKED';
export const ACTIVITY_REPORT_CONFIRM_DELETE_CLICKED = 'ACTIVITY_REPORT_CONFIRM_DELETE_CLICKED';
export const ACTIVITY_REPORT_DELETE_CLICKED = 'ACTIVITY_REPORT_DELETE_CLICKED';
export const ACTIVITY_REPORT_ADD_ACTIVITY_CLICKED = 'ACTIVITY_REPORT_ADD_ACTIVITY_CLICKED';
export const ACTIVITY_REPORT_CLICKED = 'ACTIVITY_REPORT_CLICKED';
export const ACTIVITY_REPORT_FILE_DELETE_CLICKED = 'ACTIVITY_REPORT_FILE_DELETE_CLICKED';

export const ACTIVITY_REPORT_FILE_ADD = 'ACTIVITY_REPORT_FILE_ADD';

export const ACTIVITY_REPORT_TOTAL_TOO_LOW = 'ACTIVITY_REPORT_TOTAL_TOO_LOW';
export const ACTIVITY_REPORT_EMPTY_LINES = 'ACTIVITY_REPORT_EMPTY_LINES';

export const ACTIVITY_REPORT_FILES_POST_START = 'ACTIVITY_REPORT_FILES_POST_START';
export const ACTIVITY_REPORT_FILES_POST_END = 'ACTIVITY_REPORT_FILES_POST_END';
export const ACTIVITY_REPORT_FILES_POST_ERROR = 'ACTIVITY_REPORT_FILES_POST_ERROR';
export const ACTIVITY_REPORT_GET_START = 'ACTIVITY_REPORT_GET_START';
export const ACTIVITY_REPORT_GET_END = 'ACTIVITY_REPORT_GET_END';
export const ACTIVITY_REPORT_GET_ERROR = 'ACTIVITY_REPORT_GET_ERROR';
export const ACTIVITY_REPORT_POST_START = 'ACTIVITY_REPORT_POST_START';
export const ACTIVITY_REPORT_POST_END = 'ACTIVITY_REPORT_POST_END';
export const ACTIVITY_REPORT_GET_LOGO_START = 'ACTIVITY_REPORT_GET_LOGO_START';
export const ACTIVITY_REPORT_GET_LOGO_END = 'ACTIVITY_REPORT_GET_LOGO_END';
export const ACTIVITY_REPORT_GET_LOGO_ERROR = 'ACTIVITY_REPORT_GET_LOGO_ERROR';

export const ACTIVITY_REPORT_TABLE_MOUNTED = 'ACTIVITY_REPORT_TABLE_MOUNTED';

export const activityReportGetLogoStart = () => ({
  type: ACTIVITY_REPORT_GET_LOGO_START,
});

export const activityReportGetLogoEnd = () => ({
  type: ACTIVITY_REPORT_GET_LOGO_END,
});

export const activityReportGetLogoError = () => ({
  type: ACTIVITY_REPORT_GET_LOGO_ERROR,
});

export const activityReportFileDeleteClicked = (index, activityReportId) => ({
  type: ACTIVITY_REPORT_FILE_DELETE_CLICKED,
  index: index,
  activityReportId: activityReportId,
});

export const activityReportFileAdd = (newFile, activityReportId) => ({
  type: ACTIVITY_REPORT_FILE_ADD,
  newFile: newFile,
  activityReportId: activityReportId,
});

export const activityReportInvoiceChange = (newInvoice) => ({
  type: ACTIVITY_REPORT_INVOICE_CHANGE,
  newInvoice: newInvoice,
});

export const activityReportFilesPostStart = () => ({
  type: ACTIVITY_REPORT_FILES_POST_START,
});

export const activityReportClicked = () => ({
  type: ACTIVITY_REPORT_CLICKED,
});

export const activityReportActivityChange = (selectedActivity, lineIndex) => ({
  type: ACTIVITY_REPORT_ACTIVITY_CHANGE,
  selectedActivity: selectedActivity,
  lineIndex: lineIndex,
});

export const activityReportTableMounted = (employeeType) => ({
  type: ACTIVITY_REPORT_TABLE_MOUNTED,
  employeeType: employeeType,
});

export const activityReportAddActivityClicked = (employeeType) => ({
  type: ACTIVITY_REPORT_ADD_ACTIVITY_CLICKED,
  employeeType: employeeType,
});

export const activityReportValueChange = (newValue, indexLine, indexDay, selectedType) => ({
  type: ACTIVITY_REPORT_VALUE_CHANGE,
  newValue: newValue,
  indexLine: indexLine,
  indexDay: indexDay,
  selectedType: selectedType,
});

export const activityReportDeleteClicked = (line) => ({
  type: ACTIVITY_REPORT_DELETE_CLICKED,
  selectedDeleteLine: line,
});

export const activityReportConfirmDeleteClicked = (lineIndex) => ({
  type: ACTIVITY_REPORT_CONFIRM_DELETE_CLICKED,
  lineIndex: lineIndex,
});

export const activityReportCloseDeleteModalClicked = () => ({
  type: ACTIVITY_REPORT_CLOSE_DELETE_MODAL_CLICKED,
});

export const activityReportPostEnd = (response) => ({
  type: ACTIVITY_REPORT_POST_END,
  response: response,
});

export const activityReportPostStart = () => ({
  type: ACTIVITY_REPORT_POST_START,
});

export const activityReportTabsClicked = (newKey) => ({
  type: ACTIVITY_REPORT_TABS_CLICKED,
  newKey: newKey,
});

export const activityReportFilesPostError = () => ({
  type: ACTIVITY_REPORT_FILES_POST_ERROR,
});

export const activityReportFilesPostEnd = (message) => ({
  type: ACTIVITY_REPORT_FILES_POST_END,
  message: message,
});

export const activityReportFileChange = (activityReportId, newFile) => ({
  type: ACTIVITY_REPORT_FILE_CHANGE,
  activityReportId: activityReportId,
  newFile: newFile,
});

export const activityReportCloseConfirmModalClicked = () => ({
  type: ACTIVITY_REPORT_CLOSE_CONFIRM_MODAL_CLICKED,
});

export const activityReportEmptyLines = () => ({
  type: ACTIVITY_REPORT_EMPTY_LINES,
});

export const activityReportTotalTooLow = () => ({
  type: ACTIVITY_REPORT_TOTAL_TOO_LOW,
});

export const activityReportCloseDownloadModalClicked = () => ({
  type: ACTIVITY_REPORT_CLOSE_DOWNLOAD_MODAL_CLICKED,
});

export const activityReportGenerateDownloadClicked = (activity) => ({
  type: ACTIVITY_REPORT_GENERATE_DOWNLOAD_CLICKED,
  activity: activity,
});

export const activityReportTypeChange = (newType, countryCode) => ({
  type: ACTIVITY_REPORT_TYPE_CHANGE,
  newType: newType,
  countryCode: countryCode,
});

export const activityReportLangChange = (client, newLang) => ({
  type: ACTIVITY_REPORT_LANG_CHANGE,
  client: client,
  newLang: newLang,
});

export const activityReportGetStart = () => ({
  type: ACTIVITY_REPORT_GET_START,
});

export const activityReportGetEnd = (datas) => ({
  type: ACTIVITY_REPORT_GET_END,
  datas: datas,
});

export const activityReportGetError = () => ({
  type: ACTIVITY_REPORT_GET_ERROR,
});
