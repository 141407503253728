export const LEAVE_REQUESTS_CONSULT_CLICKED = 'LEAVE_REQUESTS_CONSULT_CLICKED'
export const LEAVE_REQUESTS_PAGINATION_CLICKED = 'LEAVE_REQUESTS_PAGINATION_CLICKED'

export const LEAVE_REQUESTS_GET_START = 'LEAVE_REQUESTS_GET_START'
export const LEAVE_REQUESTS_GET_END = 'LEAVE_REQUESTS_GET_END'


export const leaveRequestsPaginationClicked = (newPage) => ({
    type: LEAVE_REQUESTS_PAGINATION_CLICKED,
    newPage: newPage
})

export const leaveRequestsGetStart = () => ({
    type: LEAVE_REQUESTS_GET_START
})

export const leaveRequestsConsultClicked = () => ({
    type: LEAVE_REQUESTS_CONSULT_CLICKED
})

export const leaveRequestsGetEnd = (response) => ({
    type: LEAVE_REQUESTS_GET_END,
    response: response
})