export const EXPENSE_DELETE_CLICKED = 'EXPENSE_DELETE_CLICKED';


// Confirm and Cancel actions
export const EXPENSE_DELETE_CANCEL_CLICKED = 'EXPENSE_DELETE_CANCEL_CLICKED';
export const EXPENSE_DELETE_START = 'EXPENSE_DELETE_START';
export const EXPENSE_DELETE_END = "EXPENSE_DELETE_END";
export const EXPENSE_REMOVE_DELETED_ID = 'EXPENSE_REMOVE_DELETED_ID';


export const expenseDeleteEnd = (message) => ({
    type: EXPENSE_DELETE_END,
    message: message
});

export const expenseDeleteClicked = (id, type, date) => ({
    type:EXPENSE_DELETE_CLICKED,
    id: id,
    expenseType: type,
    date: date
})

export const expenseDeleteCancelClicked = () => ({
    type:EXPENSE_DELETE_CANCEL_CLICKED
})

export const expenseDeleteStart = () => ({
    type:EXPENSE_DELETE_START,
})
export const removeDeletedId = () => ({
    type:EXPENSE_REMOVE_DELETED_ID
})