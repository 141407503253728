import {
    LEAVE_REQUEST_SUBMIT_CLICKED, 
    LEAVE_REQUEST_POST_END,
    LEAVE_REQUEST_POST_ERROR,
    LEAVE_REQUESTS_GET_END,
    LEAVE_REQUEST_CANCEL_CONFIRM_CLICKED,
    LEAVE_REQUEST_CANCEL_POST_END,
    LEAVE_REQUESTS_GET_START,
    LEAVE_REQUEST_GET_START,
    LEAVE_REQUEST_GET_END,
    LEAVE_REQUEST_GET_FILE_END,
    SICK_ATTESTATION_POST_END,
    LEAVE_REQUEST_DATE_CHANGE,
    LEAVE_REQUEST_NUMBER_OF_DAYS_CHANGE,
    ACTIVITY_REPORT_FILES_POST_START,
    ACTIVITY_REPORT_FILES_POST_END,
    ACTIVITY_REPORT_FILES_POST_ERROR,
    ACTIVITY_REPORT_GET_START,
    ACTIVITY_REPORT_GET_END,
    ACTIVITY_REPORT_GET_ERROR,
    ACTIVITY_REPORT_GET_LOGO_START,
    ACTIVITY_REPORT_GET_LOGO_END,
    ACTIVITY_REPORT_GET_LOGO_ERROR,
    EXPENSE_STARTED_GET_CURRENCIES,
    EXPENSE_FINISHED_GET_CURRENCIES,
    EXPENSE_GET_START,
    EXPENSES_GET_START,
    EXPENSES_GET_END,
    EXPENSE_GET_END,
    EXPENSE_DELETE_START,
    EXPENSE_DELETE_END,
    CURRENCIES_GET_START,
    CURRENCIES_GET_END,
    ADD_EXPENSE_POST_START,
    ADD_EXPENSE_POST_END
} from '../Actions/Actions'

const spinnerShowInitialState = false;

function spinnerShow(state = spinnerShowInitialState, action){
    switch(action.type){
        case LEAVE_REQUEST_GET_START:
        case LEAVE_REQUESTS_GET_START:
        case LEAVE_REQUEST_CANCEL_CONFIRM_CLICKED:
        case LEAVE_REQUEST_SUBMIT_CLICKED:
        case ACTIVITY_REPORT_FILES_POST_START:
        case ACTIVITY_REPORT_GET_START:
        case ACTIVITY_REPORT_GET_LOGO_START:
        case EXPENSE_STARTED_GET_CURRENCIES:
        case EXPENSE_GET_START:
        case EXPENSES_GET_START:
        case EXPENSE_DELETE_START:
        case CURRENCIES_GET_START:
        case ADD_EXPENSE_POST_START:
            return true;
        case LEAVE_REQUEST_NUMBER_OF_DAYS_CHANGE:
        case LEAVE_REQUEST_GET_END:
        case LEAVE_REQUEST_GET_FILE_END:
        case LEAVE_REQUEST_CANCEL_POST_END:
        case LEAVE_REQUESTS_GET_END:
        case SICK_ATTESTATION_POST_END:
        case LEAVE_REQUEST_POST_ERROR:
        case ACTIVITY_REPORT_FILES_POST_END:
        case ACTIVITY_REPORT_FILES_POST_ERROR:
        case ACTIVITY_REPORT_GET_END:
        case ACTIVITY_REPORT_GET_ERROR:
        case ACTIVITY_REPORT_GET_LOGO_END:
        case ACTIVITY_REPORT_GET_LOGO_ERROR:
        case EXPENSE_FINISHED_GET_CURRENCIES:
        case EXPENSES_GET_END:
        case EXPENSE_GET_END:
        case EXPENSE_DELETE_END:
        case CURRENCIES_GET_END:
        case ADD_EXPENSE_POST_END:
            return false;
        case LEAVE_REQUEST_POST_END:
            return action.isSick;
        case LEAVE_REQUEST_DATE_CHANGE:
            return action.everythingSet;
        default:
            return state;
    }
}

export default spinnerShow;
