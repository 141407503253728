import React from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Modal } from 'react-bootstrap';
import CraPdf from './CraPdf';
import { FaRegFilePdf } from 'react-icons/fa';

export default function CraPdfDownloader(props) {
  return (
    <Modal show={props.modalShow} onHide={props.hideDownloadActivityModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Download activity report</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.modalShow && (
          <div className='modal__body'>
            <PDFDownloadLink
              document={
                <CraPdf
                  selectedYear={props.selectedYear}
                  selectedMonth={props.selectedMonth}
                  comment={props.comment}
                  name={props.name}
                  activity={props.activity}
                  lang={props.activity.lang}
                />
              }
              fileName={props.activity.reference + '.pdf'}>
              {({ blob, url, loading, error }) => {
                if (loading) {
                  return (
                    <>
                      <div className='spinner'>
                        <div className='dot'></div>
                        <div className='dot'></div>
                        <div className='dot'></div>
                        <div className='dot'></div>
                      </div>
                      <p className='modal__info'>{'We are building your CRA for ' + props.activity.client} </p>
                    </>
                  );
                } else {
                  return (
                    <>
                      <FaRegFilePdf className='modal__icon' />
                      <p className='modal__info'>{'Click to download your CRA for ' + props.activity.client} </p>
                    </>
                  );
                }
              }}
            </PDFDownloadLink>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button className='btn btn--primary' onClick={props.hideDownloadActivityModal}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}
