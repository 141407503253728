import { connect } from "react-redux";
import CancelExpense from "./CancelExpense";
import {
  expenseDeleteCancelClicked,
  expenseDeleteStart,
  removeDeletedId,
  expenseDeleteEnd,
  expenseGetEnd,
} from "../../../../Redux/Actions/Actions";

function mapStateToProps(state) {
  return {
    id: state.expenses.toDeleteExpense.id,
    type: state.expenses.toDeleteExpense.type,
    date: state.expenses.toDeleteExpense.date
  };
}

function mapDispatchToProps(dispatch) {
  return {
    cancel: () => dispatch(expenseDeleteCancelClicked()),
    deleteStart: () => dispatch(expenseDeleteStart()),
    removeDeletedId: () => dispatch(removeDeletedId()),
    deleteEnd: (message) => dispatch(expenseDeleteEnd(message)),
    sendExpenseLines: (data) => dispatch(expenseGetEnd(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CancelExpense);
