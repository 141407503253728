import {
    LOGS_GET_END, 
    LOGS_GET_ERROR
} from '../Actions/Actions'

const LogsInitialState = {
    message: null,
    logs: []
};

function logs(state = LogsInitialState, action){
    switch(action.type){
        case LOGS_GET_ERROR:
            return {
                logs:[],
                message: {
                    type: "danger",
                    value: "The server doesn't respond, please try again later."
                }
            }
        case LOGS_GET_END:
            return {
                logs: action.logs,
                message: null
            };
        default:
            return state;
    }
}

export default logs;