import {
	EXPENSE_NEW_EXPENSE_CLICKED,
	EXPENSE_GET_CURRENCIES,
	EXPENSE_CURRENCY_CHANGE,
	EXPENSE_IS_SET_ANY_CURRENCY,
	EXPENSE_CREATE_NEW_ACCOUNT_STEP_2,
	EXPENSE_CREATE_NEW_ACCOUNT_STEP_1,
	EXPENSE_CREATE_NEW_ACCOUNT_STEP_3_ADD_FORM,
	EXPENSE_CREATE_NEW_ACCOUNT_STEP_4_COMPLETE,
	EXPENSE_LEAVE_CREATE_ACCOUNT,
	EXPENSE_GET_END,
	EXPENSE_SET_ERROR,
	EXPENSE_IT_IS_SEND_BUTTON_AVALIBLE,
	EXPENSE_CHECKBOX_CLICKED,
	EXPENSE_IS_VALID_BEFORE_SEND,
	EXPENSE_NEW_EXPENSE_ACCOUNT_ID,
	EXPENSE_CONSULT_CLICKED,
	EXPENSE_DELETE_END,
	EXPENSE_DONE_CLICKED,
	EXPENSE_ACCOUNT_CLICKED,
	EXPENSE_SET_CURRENCY_FOR_EXPENSE_ACCOUNT,
} from '../../Actions/Actions';
import StepHelper from '../../../Expenses/Expense/Helpers/StepHelper';

const date = new Date();
const month = (date.getMonth() + 1).toString().padStart(2, '0');

const initialState = {
	totalBrut: null,
	totalNet: null,
	totalTax: null,
	month: null,
	lines: [],
	date: null,
	id: null,
	checkbox: false,
	message: null,
	addExpense: null,
	currency: null,
	currencyModel: null,
	canModify: null,
	ownerid: null,
};

const expense = (state = initialState, action) => {
	switch (action.type) {
		case EXPENSE_DONE_CLICKED:
			return {
				...state,
				message: action.valid
					? null
					: {
							type: 'warning',
							value: 'Please, check the checkbox to end your expense account',
					  },
			};
		case EXPENSE_DELETE_END:
			return {
				...state,
				message: action.message,
			};
		case EXPENSE_CONSULT_CLICKED:
			console.log(action);
			return {
				...initialState,
				id: action.id,
				canModify:
					action.status.toLowerCase() === 'to validate' || action.status.toLowerCase() === 'need information'
						? true
						: false,
			};
		case EXPENSE_NEW_EXPENSE_CLICKED:
			return {
				...initialState,
				currentStep: StepHelper.stepOne,
			};
		case EXPENSE_GET_CURRENCIES:
			return {
				...state,
				currencies: action.payload.currencies,
			};
		case EXPENSE_CURRENCY_CHANGE:
			return {
				...state,
				currentCurrencyId: action.payload.currencyId,
				cuurentCurrencyCode: action.payload.currencyCode,
			};
		case EXPENSE_IS_SET_ANY_CURRENCY:
			return {
				...state,
				isSetAnyCurrency: action.payload.isSetAnyCurrency,
			};
		case EXPENSE_CREATE_NEW_ACCOUNT_STEP_1:
			return {
				...state,
				currentStep: StepHelper.stepOne,
			};
		case EXPENSE_CREATE_NEW_ACCOUNT_STEP_2:
			return {
				...state,
				currentStep: StepHelper.stepTwo,
			};
		case EXPENSE_CREATE_NEW_ACCOUNT_STEP_3_ADD_FORM:
			return {
				...state,
				currentStep: StepHelper.stepThree,
			};
		case EXPENSE_CREATE_NEW_ACCOUNT_STEP_4_COMPLETE:
			return {
				...state,
				currentStep: StepHelper.StepFour,
			};
		case EXPENSE_LEAVE_CREATE_ACCOUNT:
			return {
				...state,
				currentStep: StepHelper.noStep,
			};
		case EXPENSE_SET_ERROR:
			return {
				...state,
				error: action.payload.error,
			};
		case EXPENSE_GET_END: {
			return {
				...state,
				totalBrut: action.data.totalBrut,
				totalNet: action.data.totalNet,
				totalTax: action.data.totalTax,
				month: action.data.month,
				lines: action.data.lines,
				date: action.data.date,
				id: action.data.id,
				currency: action.data.currencyId,
				ownerid: action.data.ownerid,
			};
		}
		case EXPENSE_IT_IS_SEND_BUTTON_AVALIBLE:
			return {
				...state,
				SendExpenseLinesAvalibity: action.payload.data,
			};
		case EXPENSE_CHECKBOX_CLICKED:
			return {
				...state,
				checkbox: !state.checkbox,
			};
		case EXPENSE_IS_VALID_BEFORE_SEND:
			return {
				...state,
				isValidBeforeSend: action.payload.data,
			};
		case EXPENSE_NEW_EXPENSE_ACCOUNT_ID:
			return {
				...state,
				newExpenseId: action.payload.data,
			};

		case EXPENSE_ACCOUNT_CLICKED:
			return {
				...state,
				canModify: true,
			};
		case EXPENSE_SET_CURRENCY_FOR_EXPENSE_ACCOUNT:
			return {
				...state,
				currencyModel: action.payload.data,
			};
		default:
			return state;
	}
};

export default expense;
