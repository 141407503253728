import React from 'react';
import { Modal } from 'react-bootstrap';

import CancelLeaveRequestServices from './Services/CancelLeaveRequestServices';
import LeaveRequestsServices from '../../LeaveRequests/Services/LeaveRequestsServices';

export default function CancelLeaveRequest(props) {
  const cancelLeaveRequest = async () => {
    let idToCancel = props.deleteLine.id;
    props.confirm();
    let cancelResponse = await CancelLeaveRequestServices.cancelLeaveRequest(idToCancel);
    props.postEnd(cancelResponse);
    let result = await LeaveRequestsServices.getLeaveRequests(props.contactId);
    props.getEnd(result);
  };

  return (
    <Modal show={props.modalShow} onHide={props.hideModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Cancel leave request</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.deleteLine !== null && (
          <p>
            Do you want to cancel your {props.deleteLine.type} Leave of {props.deleteLine.duration} days from{' '}
            {props.deleteLine.from} to {props.deleteLine.to} ?
          </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button className='btn btn--outlined' onClick={props.hideModal}>
          No
        </button>
        <button className='btn btn--primary' onClick={() => cancelLeaveRequest()}>
          Yes
        </button>
      </Modal.Footer>
    </Modal>
  );
}
