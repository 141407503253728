import { connect } from 'react-redux';

import { identityGetEnd, identityGetError } from '../Redux/Actions/Actions';

import App from './App';

function mapStateToProps(state) {
  return {
    identityState: state.identity.state,
    email: state.identity.email,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    identityGetEnd: (data) => dispatch(identityGetEnd(data)),
    identityGetError: () => dispatch(identityGetError()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
