import {
    EXPENSES_GET_END,
    EXPENSES_PAGINATION_CLICKED,
    EXPENSES_MY_EXPENSES_CLICKED
} from '../../Actions/Actions'

const expensesInitialState = {
    expenses: [],
    activePage: 1,
    message: null
}

function expenses(state = expensesInitialState, action){
    switch(action.type){
        case EXPENSES_PAGINATION_CLICKED:
            return {
                ...state,
                activePage: action.newPage
            }
        case EXPENSES_GET_END:
            return {
                ...state,
                expenses: action.response.lines,
                message: action.response.message
            }
        case EXPENSES_MY_EXPENSES_CLICKED:
            return {
                ...state,
                message: null,
                activePage: 1
            }
        default: 
            return state;
    }
}

export default expenses;