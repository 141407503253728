import { connect } from 'react-redux'
import FailedSynchro from './FailedSynchro'


function mapStateToProps(state){
    return {
        identityState: state.identity.state
    }
}

export default connect(mapStateToProps)(FailedSynchro)