import React from 'react';
import { Modal } from 'react-bootstrap';

export default function DeleteActivity(props) {
  return (
    <Modal show={props.modalShow} onHide={props.hideModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Delete activity line</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.deleteLine.name !== '' && <p>Do you want delete activity line "{props.deleteLine.name}" ?</p>}
      </Modal.Body>
      <Modal.Footer>
        <button className='btn btn--outlined' onClick={props.hideModal}>
          No
        </button>
        <button className='btn btn--primary' onClick={() => props.delete(props.lines.indexOf(props.deleteLine))}>
          Yes
        </button>
      </Modal.Footer>
    </Modal>
  );
}
