import { connect } from 'react-redux';
import Expenses from './Expenses';

import {
	newExpenseClicked,
	expensesGetEnd,
	expensesGetStart,
	expensesPaginationClicked,
	expenseConsultClicked,
	setIsAccountCreation,
} from '../../Redux/Actions/Actions';

function mapStateToProps(state) {
	return {
		contactId: state.identity.contactId,
		message: state.expenses.expenses.message,
		expenses: state.expenses.expenses.expenses.filter(
			(item, index) =>
				index >= (state.expenses.expenses.activePage - 1) * 10 && index < state.expenses.expenses.activePage * 10
		),
		numberOfPages: Math.ceil(state.expenses.expenses.expenses.length / 10),
		activePage: state.expenses.expenses.activePage,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		newExpense: () => dispatch(newExpenseClicked()),
		getEnd: (response) => dispatch(expensesGetEnd(response)),
		getStart: () => dispatch(expensesGetStart()),
		pageChange: (newPage) => dispatch(expensesPaginationClicked(newPage)),
		consult: (id, status) => dispatch(expenseConsultClicked(id, status)),
		setIsAccountCreation: (data) => dispatch(setIsAccountCreation(data)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Expenses);
