import { authProvider } from '../../AuthUtil/authProvider';


class LogsServices {
    async getLogs(){
        const token = await authProvider.getIdToken();
        let response = await fetch(process.env.REACT_APP_API_URL + '/Logs', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.idToken.rawIdToken
            }
        });
        response = await response.json();
        return response;
    }
}

export default new LogsServices();