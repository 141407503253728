import { connect } from 'react-redux';
import ActivityReport from './ActivityReport';
import {
  activityReportLangChange,
  activityReportGetStart,
  activityReportGetEnd,
  activityReportGetError,
  activityReportTypeChange,
  activityReportGenerateDownloadClicked,
  activityReportTotalTooLow,
  activityReportEmptyLines,
  activityReportFileChange,
  activityReportFilesPostStart,
  activityReportFilesPostError,
  activityReportFilesPostEnd,
  activityReportTabsClicked,
  activityReportPostStart,
  activityReportPostEnd,
  activityReportInvoiceChange,
  activityReportFileAdd,
  activityReportFileDeleteClicked,
  activityReportGetLogoStart,
  activityReportGetLogoEnd,
  activityReportGetLogoError,
  activityReportAddActivityClicked,
} from '../Redux/Actions/Actions';

function mapStateToProps(state) {
  return {
    employeeType: state.identity.type,
    firstName: state.identity.firstName,
    lastName: state.identity.lastName,
    contactId: state.identity.contactId,
    businessUnitId: state.identity.businessUnitId,
    type: state.identity.type,
    selectedMonth: state.activityReport.selectedMonth,
    selectedYear: state.activityReport.selectedYear,
    comment: state.activityReport.comment,
    selectedType: state.activityReport.selectedType,
    busy: state.activityReport.busy,
    lines: state.activityReport.lines,
    footerLine: state.activityReport.footerLine,
    daysOfWork: state.activityReport.daysOfWork,
    message: state.activityReport.message,
    disableUpload: state.activityReport.disableUpload,
    activeKey: state.activityReport.activeKey,
    selectYear: state.activityReport.selectYear,
    ready: state.activityReport.ready,
    invoice: state.activityReport.invoice,
    availableActivities: state.activityReport.availableActivities,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    langChange: (client, newLang) => dispatch(activityReportLangChange(client, newLang)),
    getStart: () => dispatch(activityReportGetStart()),
    getEnd: (datas) => dispatch(activityReportGetEnd(datas)),
    getError: () => dispatch(activityReportGetError()),
    getLogoStart: () => dispatch(activityReportGetLogoStart()),
    getLogoEnd: () => dispatch(activityReportGetLogoEnd()),
    getLogoError: () => dispatch(activityReportGetLogoError()),
    typeChange: (newType, countryCode) => dispatch(activityReportTypeChange(newType, countryCode)),
    showDownload: (activity) => dispatch(activityReportGenerateDownloadClicked(activity)),
    totalTooLow: () => dispatch(activityReportTotalTooLow()),
    emptyLines: () => dispatch(activityReportEmptyLines()),
    fileAdd: (activityReportId, newFile) => dispatch(activityReportFileAdd(newFile, activityReportId)),
    fileDelete: (activityReportId, index) => dispatch(activityReportFileDeleteClicked(index, activityReportId)),
    fileChange: (activityReportId, newFile) => dispatch(activityReportFileChange(activityReportId, newFile)),
    filesPostStart: () => dispatch(activityReportFilesPostStart()),
    filesPostEnd: (message) => dispatch(activityReportFilesPostEnd(message)),
    filesPostError: () => dispatch(activityReportFilesPostError()),
    changeKey: (newKey) => dispatch(activityReportTabsClicked(newKey)),
    postStart: () => dispatch(activityReportPostStart()),
    postEnd: (response) => dispatch(activityReportPostEnd(response)),
    invoiceChange: (newInvoice) => dispatch(activityReportInvoiceChange(newInvoice)),
    addActivity: (employeeType) => dispatch(activityReportAddActivityClicked(employeeType)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityReport);
