import { connect } from 'react-redux'
import LeaveRequests from './LeaveRequests'

import {
    leaveRequestsGetEnd,
    leaveRequestCancelClicked,
    newLeaveRequestClicked,
    leaveRequestsGetStart,
    leaveRequestConsultClicked,
    leaveRequestsPaginationClicked
} from '../../Redux/Actions/Actions'

function mapStateToProps(state){
    return {
        contactId: state.identity.contactId,
        leaveRequests: state.leaveRequests.leaveRequests.leaveRequests,
        message: state.leaveRequests.message,
        numberOfPages: Math.ceil(state.leaveRequests.leaveRequests.leaveRequests.length / 10),
        activePage: state.leaveRequests.leaveRequests.activePage
    }
}

function mapDispatchToProps(dispatch){
    return {
        getEnd: (response) => dispatch(leaveRequestsGetEnd(response)),
        delete: (leaveRequest) => dispatch(leaveRequestCancelClicked(leaveRequest)),
        newLeaveRequest: () => dispatch(newLeaveRequestClicked()),
        getStart: () => dispatch(leaveRequestsGetStart()),
        consult: (leaveRequest) => dispatch(leaveRequestConsultClicked(leaveRequest)),
        pageChange: (newPage) => dispatch(leaveRequestsPaginationClicked(newPage))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeaveRequests)