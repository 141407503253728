import { connect } from 'react-redux';
import NavBar from './NavBar';
import { homeClicked, logsClicked } from '../Redux/Actions/Actions';

function mapStateToProps(state) {
  return {
    currentPage: state.currentPage,
    name: state.identity.state === 'SYNCHRONISED' ? state.identity.firstName + ' ' + state.identity.lastName : '',
    employeeType: state.identity.type,
    email: state.identity.email
  };
}

function mapDispatchToProps(dispatch) {
  return {
    seeHome: () => dispatch(homeClicked()),
    goLogs: () => dispatch(logsClicked()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
