import React from 'react';
import './newExpense.css';
import { FaArrowRight } from 'react-icons/fa';
import { Form } from 'react-bootstrap';
import ExpenseService from '../../Services/ExpensesServices';
import SpinnerModal from '../../../App/Component/SpinnerModal/SpinnerModal';

export default class ExpenseRequest extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
			currency: false,
			month: '',
			year: '',
			isExistExpenseAccount: false,
			dateShort: null,
			months: [
				'January',
				'February',
				'March',
				'April',
				'May',
				'June',
				'July',
				'August',
				'September',
				'October',
				'November',
				'December',
			],
		};
	}

	async componentDidMount() {
		const date = new Date();

		this.props.currenciesGetStart();
		//Set Expenses

		const Expenses = await ExpenseService.getExpenses(this.props.contactId);
		this.props.getExpenseAccountByContactId(Expenses);
		const currencies = await ExpenseService.getCurrencies();
		//Set month
		date.setDate(date.getDate() - 5);
		const month = this.state.months[date.getMonth()];
		const year = date.getFullYear();
		this.setState({ month });
		this.setState({ year });
		const dateShort = `${date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`}/${
			date.getFullYear() % 100
		}`;

		this.setState({ dateShort });
		Expenses.lines.forEach((expenseAcc) => {
			if (expenseAcc.month === dateShort) {
				this.setState({ isExistExpenseAccount: true });
				//set curent expense id
			}
		});
		this.props.currenciesGetEnd(currencies);
	}

	currencyChangeEvent(event) {
		const currencyId = event.target.value;

		//GET currency code from currencies list
		const currency = this.props.currenciesList.find((item) => item.id === currencyId);

		// SET currency
		this.setState({ currency });
	}
	async createExpenseAccount() {
		this.setState({ isOpen: true });
		const monthAndDay = this.state.dateShort;
		const contactId = this.props.contactId;
		const currencyId = this.state.currency.id;
		//Create model base on data
		const ExpenseAccount = {
			month: monthAndDay,
			currencyId,
			contactId,
		};

		const expenseAccount = await ExpenseService.createExpenseAccount(ExpenseAccount);
		this.props.setIsAccountCreation(true);
		this.props.setExpenseId(expenseAccount.expense.id);
		this.props.expenseAccountClicked();
		this.setState({ isOpen: false });
	}

	render = () => {
		return (
			<div className='leaveRequest'>
				<SpinnerModal show={this.state.isOpen} />
				<section className='leaveRequest__section'>
					<h2 className='leaveRequest__title'>New Expense Account</h2>
					<section className='leaveRequest__wrapper'>
						{this.state.isExistExpenseAccount ? (
							<p>You have already done an Expense Account this month. You'll be abble to make another one next month</p>
						) : (
							<>
								<div className='leaveRequest__flex'>
									<label className='leaveRequest__label'>Expense Account for:</label>
									<div className='leaveRequest__status'>
										{this.state.month} {this.state.year}
									</div>
								</div>
								<div className='leaveRequest__flex'>
									<label className='leaveRequest__label'>Refund Currency:</label>
									<Form className='leaveRequest__checkbox'>
										<Form.Control as='select' size='sm' onChange={this.currencyChangeEvent.bind(this)}>
											<option value={false}>Select your currency</option>
											{this.props.currenciesList && (
												<>
													{this.props.currenciesList.map((currency) => (
														<option key={currency.id} value={currency.id}>
															{currency.code}
														</option>
													))}
												</>
											)}
										</Form.Control>
									</Form>
								</div>
							</>
						)}
					</section>
					<div className='leaveRequest__group'>
						<button className='print__attach btn leaveRequest__btn btn--outlined' onClick={this.props.homeClicked}>
							Cancel
						</button>
						<button
							className='print__attach btn leaveRequest__btn btn--primary'
							onClick={this.createExpenseAccount.bind(this)}
							disabled={!this.state.currency || this.state.isExistExpenseAccount}>
							Next
							<FaArrowRight className='print__icon' />
						</button>
					</div>
				</section>
			</div>
		);
	};
}
