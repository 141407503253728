import React from 'react';

import { Alert, Pagination } from 'react-bootstrap';
import { Breakpoint } from 'react-socks';

import ExpensesServices from '../Services/ExpensesServices';

import './Expenses.css';
export default class Expenses extends React.Component {
	async componentDidMount() {
		this.props.getStart();
		let result = await ExpensesServices.getExpenses(this.props.contactId);
		this.props.setIsAccountCreation(false);
		this.props.getEnd(result);
	}

	render = () => {
		let items = [];
		for (let number = 1; number <= this.props.numberOfPages; number++) {
			items.push(
				<Pagination.Item
					key={number}
					active={number === this.props.activePage}
					onClick={() => {
						this.props.pageChange(number);
					}}>
					{number}
				</Pagination.Item>
			);
		}
		return (
			<div className='leaveRequests'>
				{this.props.message !== null && (
					<Alert variant={this.props.message.type}>
						<p>{this.props.message.value}</p>
					</Alert>
				)}
				<h2 className='leaveRequests__title'>Expense Account</h2>
				{this.props.expenses.length > 0 && (
					<>
						<Breakpoint large up>
							<section className='leaveRequests__section'>
								<div className='leaveRequests__table'>
									<header className='leaveRequests__header'>
										<ul className='leaveRequests__list'>
											<li className='leaveRequests__item'>Date</li>
											<li className='leaveRequests__item'>Month</li>
											<li className='leaveRequests__item'>Total Amount</li>
											<li className='leaveRequests__item'>Status</li>
											<li className='leaveRequests__item'>Actions</li>
										</ul>
									</header>
									<div className='leaveRequests__main'>
										{this.props.expenses.map((expense, index) => (
											<ul key={'expense' + index} className='leaveRequests__list'>
												<li className='leaveRequests__item'>{expense.date}</li>
												<li className='leaveRequests__item'>{expense.month}</li>
												<li className='leaveRequests__item'>{expense.totalBrut}</li>
												<li className='leaveRequests__item'>{expense.status}</li>
												<li className='leaveRequests__item'>
													<button
														className='btn btn--primary'
														onClick={() => this.props.consult(expense.id, expense.status)}>
														{expense.status.toLowerCase() === 'to validate' ||
														expense.status.toLowerCase() === 'need information'
															? 'Modify'
															: 'View'}
													</button>
												</li>
											</ul>
										))}
									</div>
								</div>
							</section>
						</Breakpoint>
						<Breakpoint medium down>
							{this.props.expenses.map((expense, index) => (
								<section key={'expense' + index} className='leaveRequests__section'>
									<div className='leaveRequests__table'>
										<div className='leaveRequests__item'>
											<div>Date:</div>
											<div>{expense.date}</div>
										</div>
										<div className='leaveRequests__item'>
											<div>Month:</div>
											<div>{expense.month}</div>
										</div>
										<div className='leaveRequests__item'>
											<div>Total amount:</div>
											<div>{expense.totalBrut}</div>
										</div>
										<div className='leaveRequests__item'>
											<div>Status:</div>
											<div>{expense.status}</div>
										</div>
										<div className='leaveRequests__item'>
											<button
												className='btn btn--primary leaveRequests__btn leaveRequests__btn--see'
												onClick={() => this.props.consult(expense.id, expense.status)}>
												{expense.status.toLowerCase() === 'to validate' ||
												expense.status.toLowerCase() === 'need information'
													? 'Modify'
													: 'View'}
											</button>
										</div>
									</div>
								</section>
							))}
						</Breakpoint>
						<Pagination className='leaveRequests__pagination pagination'>{items}</Pagination>
					</>
				)}
			</div>
		);
	};
}
