import React from 'react';

import { Breakpoint } from 'react-socks';
import { Alert, Pagination } from 'react-bootstrap';
import { FaPen } from 'react-icons/fa';

import LeaveRequestsServices from './Services/LeaveRequestsServices';
import CancelLeaveRequestContainer from '../Components/CancelLeaveRequest/CancelLeaveRequestContainer';

import './LeaveRequests.css';

export default class LeaveRequests extends React.Component {
  async componentDidMount() {
    this.props.getStart();
    let result = await LeaveRequestsServices.getLeaveRequests(this.props.contactId);
    this.props.getEnd(result);
  }

  render = () => {
    const renderPagination = () => {
      let pages = [];
      for (let number = 1; number <= this.props.numberOfPages; number++) {
        pages.push(
          <Pagination.Item
            key={number}
            active={number === this.props.activePage}
            onClick={() => {
              this.props.pageChange(number);
            }}>
            {number}
          </Pagination.Item>
        );
      }
      return pages;
    };
    return (
      <div className='leaveRequests'>
        <h2 className='leaveRequests__title'>Leave requests</h2>

        {this.props.message !== null && (
          <Alert variant={this.props.message.type}>
            <p>{this.props.message.value}</p>
          </Alert>
        )}
        <Breakpoint large up>
          {this.props.leaveRequests.length > 0 && (
            <section className='leaveRequests__section'>
              <div className='leaveRequests__table'>
                <header className='leaveRequests__header'>
                  <ul className='leaveRequests__list'>
                    <li className='leaveRequests__item'>From</li>
                    <li className='leaveRequests__item'>To</li>
                    <li className='leaveRequests__item'>Duration</li>
                    <li className='leaveRequests__item'>Type</li>
                    <li className='leaveRequests__item'>Status</li>
                    <li className='leaveRequests__item'>Actions</li>
                  </ul>
                </header>
                <div className='leaveRequests__main'>
                  {this.props.leaveRequests
                    .filter(
                      (item, index) => index >= (this.props.activePage - 1) * 10 && index < this.props.activePage * 10
                    )
                    .map((leaveRequest) => (
                      <ul key={leaveRequest.id} className='leaveRequests__list'>
                        <li className='leaveRequests__item'>{leaveRequest.from}</li>
                        <li className='leaveRequests__item'>{leaveRequest.to}</li>
                        <li className='leaveRequests__item'>{leaveRequest.duration}</li>
                        <li className='leaveRequests__item'>{leaveRequest.type}</li>
                        <li className='leaveRequests__item'>{leaveRequest.status}</li>
                        <li className='leaveRequests__item'>
                          {leaveRequest.canCancel && (
                            <button
                              className='btn btn--outlined leaveRequests__btn leaveRequests__btn--outlined'
                              onClick={() => this.props.delete(leaveRequest)}>
                              Cancel
                            </button>
                          )}
                          <button
                            className='btn btn--primary leaveRequests__btn leaveRequests__btn--see'
                            onClick={() => this.props.consult(leaveRequest)}>
                            {leaveRequest.canModify ? <FaPen /> : 'View'}
                          </button>
                        </li>
                      </ul>
                    ))}
                </div>
              </div>
            </section>
          )}
        </Breakpoint>
        <Breakpoint medium down>
          {this.props.leaveRequests
            .filter((item, index) => index >= (this.props.activePage - 1) * 10 && index < this.props.activePage * 10)
            .map((leaveRequest) => (
              <section key={leaveRequest.id} className='leaveRequests__section'>
                <div className='leaveRequests__table'>
                  <div className='leaveRequests__item'>
                    <div>From:</div>
                    <div>{leaveRequest.from}</div>
                  </div>
                  <div className='leaveRequests__item'>
                    <div>To:</div>
                    <div>{leaveRequest.to}</div>
                  </div>
                  <div className='leaveRequests__item'>
                    <div>Duration:</div>
                    <div>{leaveRequest.duration}</div>
                  </div>
                  <div className='leaveRequests__item'>
                    <div>Type:</div>
                    <div>{leaveRequest.type}</div>
                  </div>
                  <div className='leaveRequests__item'>
                    <div>Status:</div>
                    <div>{leaveRequest.status}</div>
                  </div>
                  <div className='leaveRequests__item'>
                    {leaveRequest.canCancel && (
                      <button
                        className='btn btn--outlined leaveRequests__btn leaveRequests__btn--outlined'
                        onClick={() => this.props.delete(leaveRequest)}>
                        Cancel
                      </button>
                    )}
                    <button
                      className='btn btn--primary leaveRequests__btn leaveRequests__btn--see'
                      onClick={() => this.props.consult(leaveRequest)}>
                      {leaveRequest.canModify ? <FaPen /> : <>'View'</>}
                    </button>
                  </div>
                </div>
              </section>
            ))}
        </Breakpoint>
        <Pagination className='leaveRequests__pagination'>{renderPagination()}</Pagination>
        <CancelLeaveRequestContainer />
      </div>
    );
  };
}
