import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { FaRegTrashAlt } from 'react-icons/fa';

import './ActivityReportTable.css';

export default function ActivityReportTable(props) {
  const { componentMounted, employeeType } = props;
  useEffect(() => {
    componentMounted(employeeType);
  }, [componentMounted, employeeType]);

  const onSelectActivity = (event, line) => {
    props.activityChange(event.target.value, props.lines.indexOf(line));
  };

  const createHeader = (isAR) => {
    if (isAR) {
      return (
        <thead className='table__head' key='thead'>
          <tr key='headerLine'>
            <th>{isAR.unit === 'Day' ? 'Daily AR' : 'Hourly AR'}</th>
            {props.footerLine.days.map((day) => (
              <th key={'header-' + day.date}>{day.date}</th>
            ))}
            <th key='header-total'>Total</th>
          </tr>
        </thead>
      );
    } else {
      return (
        <thead className='table__head' key='thead'>
          <tr key='headerLine'>
            <th>Summary(DAY)</th>
            {props.footerLine.days.map((day) => (
              <th key={'header-' + day.date}>{day.date}</th>
            ))}
            <th key='header-total'>Total</th>
          </tr>
        </thead>
      );
    }
  };

  const createBody = () => {
    return (
      <tbody>
        {props.lines.map((line, i) => (
          <tr key={line.name + '-' + i}>{createLine(line, i)}</tr>
        ))}
      </tbody>
    );
  };

  const createBodyDaily = () => {
    const bodyLines = props.lines
      .filter((line) => (line.unit === 'Day' || !line.unit) && line.type !== 'A')
      .map((line, index) => <tr key={line.name + '-' + index}>{createLine(line, index)}</tr>);
    return <tbody>{bodyLines}</tbody>;
  };

  const createBodyHourly = () => {
    const bodyLines = props.lines
      .filter((line) => (line.unit === 'Hour' || !line.unit) && line.type !== 'A')
      .map((line, index) => <tr key={line.name + '-' + index}>{createLine(line, index)}</tr>);
    return <tbody>{bodyLines}</tbody>;
  };

  const createLine = (line, index) => {
    return (
      <>
        {line.type === 'M' ? (
          <td key={line.name + '-' + index + '-0'}>{line.client + ' - ' + line.name}</td>
        ) : line.type === 'L' ? (
          <td key={line.name + '-' + index + '-0'}>{line.name}</td>
        ) : line.type === 'A' && (
          createSelectActivity(line, index)
        )}
        {line.days.map((day) => (
          <td
            key={line.name + '-' + index + '-' + day.date}
            className={day.isHolidays || day.isWeekend ? 'table__holidays ' : ''}>
            <input
              disabled={!line.canModified || props.busy}
              value={day.value}
              onBlur={(e) => {
                if (e.target.value === '') {
                  props.valueChange(0, props.lines.indexOf(line), line.days.indexOf(day), props.selectedType);
                }
              }}
              onChange={(e) => {
                const value = e.target.value

                let inputValue = value
                if (value.charAt(0) === '.' || value.charAt(0) === ',') {
                  inputValue = `0${value}`
                }

                props.valueChange(inputValue.replace(',', '.'), props.lines.indexOf(line), line.days.indexOf(day), props.selectedType);
              }}></input>
          </td>
        ))}
        <td key={line.name + index + '-total'}>{line.total}</td>
      </>
    );
  };

  const createSelectActivity = (line, index) => {
    return (
      <td key={line.name + '-' + index + '-0'} className='table__hasActivity'>
        <Form.Control
          key={'selectActivity-' + index}
          as='select'
          size='sm'
          className='table__selectActivity'
          onChange={(e) => onSelectActivity(e, line)}>
          <option disabled>Select reason</option>
          <option key={'name-'} value={line.name}>
            {line.name}
          </option>
          {props.availableActivities.filter((activity) => activity !== line.name).map(activity => (
            <option key={'chooseActivity-' + index + activity} value={activity}>
              {activity}
            </option>
            ))}
        </Form.Control>
        <FaRegTrashAlt className='td-icon-trash' onClick={() => props.delete(line)} />
      </td>
    );
  };

  const createBodyFooter = () => {
    const bodyLines = props.lines
      .filter((line) => line.type === 'A')
      .map((line, index) => <tr key={line.name + '-' + index}>{createLine(line, index)}</tr>);
    if (bodyLines) {
      return <tbody>{bodyLines}</tbody>;
    }
  };

  const createFooterBoth = () => {
    if (props.footerLine !== null) {
      let line = props.footerLine;
      let footerColumn = [];
      const hoursPerDay = line.isoCodeCountry === 'FR' ? 7 : 8;
      footerColumn.push(
        <td key='total-days-0'>
          {line.name} {line.total}/{props.daysOfWork}
        </td>
      );

      const unit = line.unit;

      line.days.forEach((day) => {
        if (unit === 'Hour')
          footerColumn.push(
            <td key={'total-' + day.date} className={`${day.isWeekend ? 'table__holidays' : ''} table__block`}>
              <input type='text' disabled value={day.value / hoursPerDay} />
            </td>
          );
        else
          footerColumn.push(
            <td key={'total-' + day.date} className={`${day.isWeekend ? 'table__holidays' : ''} table__block`}>
              <input type='text' value={day.value} disabled />
            </td>
          );
      });

      footerColumn.push(
        <td className='table__block' key='total-total'>
          {line.total}
        </td>
      );

      return (
        <tfoot>
          <tr key='totalLine'>{footerColumn}</tr>
        </tfoot>
      );
    }
  };

  const createFooter = () => {
    if (props.footerLine !== null) {
      const line = props.footerLine;

      return (
        <tfoot>
          <tr key='totalLine'>
            <td key='total-days-0'>
              {line.name} {line.total}/{props.daysOfWork}
            </td>
            {line.days.map((day) => (
              <td key={'total-' + day.date} className={day.isWeekend ? 'table__holidays' : ''}>
                {day.value}
              </td>
            ))}
            <td key='total-total'>{line.total}</td>
          </tr>
        </tfoot>
      );
    }
  };

  const createTable = () => {
    if (props.lines.length > 0 && props.footerLine !== null) {
      const isDailyAR = props.lines.find((line) => line.unit === 'Day');
      const isHourlyAR = props.lines.find((line) => line.unit === 'Hour');

      if (isDailyAR && isHourlyAR) {
        return (
          <>
            <div className='table__activity' key='Daily'>
              <table className='table__striped' key='Daily'>
                {createHeader(isDailyAR)}
                {createBodyDaily()}
              </table>
            </div>
            <div className='table__activity' key='Hourly'>
              <table className='table__striped' key='Hourly'>
                {createHeader(isHourlyAR)}
                {createBodyHourly()}
              </table>
            </div>
            <div className='table__activity' key='Summary'>
              <table className='table__striped' key='Summary'>
                {createHeader()}
                {createBodyFooter()}
                {createFooterBoth()}
              </table>
            </div>
          </>
        );
      } else {
        return (
          <div className='table__activity' key='Summary'>
            <table className='table__striped' key='table'>
              {createHeader(isDailyAR ? isDailyAR : isHourlyAR)}
              {createBody()}
              {createFooter()}
            </table>
          </div>
        );
      }
    } else {
      return <div></div>;
    }
  };

  return createTable();
}
