import {
    LEAVE_REQUESTS_GET_END,
    LEAVE_REQUESTS_PAGINATION_CLICKED
} from '../../Actions/Actions'

const leaveRequestsInitialState = {
    leaveRequests: [],
    activePage: 1
}

function leaveRequests(state = leaveRequestsInitialState, action){
    switch(action.type){
        case LEAVE_REQUESTS_PAGINATION_CLICKED:
            return {
                ...state,
                activePage: action.newPage
            }
        case LEAVE_REQUESTS_GET_END:
            return {
                ...state,
                leaveRequests: action.response.lines
            }
        default: 
            return state;
    }
}

export default leaveRequests;