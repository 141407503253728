
import {
    EXPENSE_DELETE_CLICKED,
    EXPENSE_DELETE_CANCEL_CLICKED,
    EXPENSE_DELETE_START
} from '../../Actions/Actions'

const initialState = {
    id:null,
    type:null,
    date:null
}

function toDelete(state = initialState,action){
    switch(action.type){
        case EXPENSE_DELETE_CLICKED:
            return{
                ...state,
                id:action.id,
                type: action.expenseType,
                date: action.date
            }
        case EXPENSE_DELETE_CANCEL_CLICKED:
            return initialState;
        case EXPENSE_DELETE_START:
            return initialState;    
        default:
            return state
    }
}

export default toDelete;

