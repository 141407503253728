import { authProvider } from '../../../AuthUtil/authProvider';


class LeaveRequestsServices {
    async getLeaveRequests(contactId){
        const token = await authProvider.getIdToken();
        let response = await fetch(process.env.REACT_APP_API_URL + '/LeaveRequests?contactId=' + contactId, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.idToken.rawIdToken
            }
        });
        response = await response.json();
        return response;
    }
}

export default new LeaveRequestsServices();