import React from 'react';
import { Modal } from 'react-bootstrap';

export default function ConfirmSaveAr(props) {
  return (
    <Modal show={props.modalShow} onHide={props.hideModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Warning</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to save an activity report with 0 working days ?</p>
      </Modal.Body>
      <Modal.Footer>
        <button className='btn btn--secondary' onClick={props.hideModal}>
          No
        </button>
        <button className='btn btn--primary' onClick={props.confirmSaveAr}>
          Yes
        </button>
      </Modal.Footer>
    </Modal>
  );
}
