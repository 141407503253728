import { connect } from 'react-redux'
import MainContent from './MainContent'


function mapStateToProps(state){
    return {
        currentPage: state.currentPage
    }
}

export default connect(mapStateToProps)(MainContent)