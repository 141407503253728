import React, { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { FaArrowLeft, FaPen, FaCheck, FaUpload } from 'react-icons/fa';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import en from 'date-fns/locale/en-GB';

import 'react-datepicker/dist/react-datepicker.css';
import { Form, Alert } from 'react-bootstrap';
import LeaveRequestServices from './Services/LeaveRequestServices';
import CancelLeaveRequestContainer from '../Components/CancelLeaveRequest/CancelLeaveRequestContainer';

import './LeaveRequest.css';

let NumberOfDays = require('./Models/NumberOfDaysModel');
let LeaveRequestModel = require('./Models/LeaveRequestModel');
let UploadSickLeaveModel = require('./Models/UploadSickLeaveModel');
let MessageModel = require('./Models/MessageModel');

registerLocale('en-GB', en);
setDefaultLocale('en-GB');

function pad(number) {
	if (number < 10) {
		return '0' + number;
	}
	return number;
}

export default function LeaveRequest(props) {
	const { getStart, getEnd, getFileEnd, leaveRequest, sickLRName, sickAttestation } = props;
	function formatDateToSend(date) {
		return date.getFullYear() + '-' + pad(date.getMonth() + 1) + '-' + pad(date.getDate());
	}

	useEffect(() => {
		async function getLeaveRequest() {
			getStart();
			const result = await LeaveRequestServices.getLeaveRequest(leaveRequest.id);
			getEnd(result);
		}
		async function getLeaveRequestFile() {
			let requestModel = new UploadSickLeaveModel(
				leaveRequest.id,
				props.firstName,
				props.lastName,
				props.businessUnitId,
				props.sickLRName
			);
			getStart();
			const result = await LeaveRequestServices.getLeaveRequestFile(requestModel);
			getFileEnd(result);
		}
		if (!sickAttestation && leaveRequest !== null) {
			getLeaveRequest();
			if (sickLRName) {
				getLeaveRequestFile();
			}
		}
	}, [getStart, getEnd, getFileEnd, leaveRequest, sickLRName, sickAttestation]);

	async function onDateChange(
		startDate = props.initialDate.date,
		startMoment = props.initialDate.moment,
		endDate = props.endDate.date,
		endMoment = props.endDate.moment
	) {
		let everythingSet = typeof startDate !== 'undefined' && typeof endDate !== 'undefined';
		if (
			everythingSet &&
			(startDate > endDate ||
				(startDate.getTime() === endDate.getTime() && startMoment === 'Afternoon' && endMoment === 'Morning'))
		) {
			endDate = startDate;
			endMoment = startMoment;
		}
		props.setDates({ date: startDate, moment: startMoment }, { date: endDate, moment: endMoment }, everythingSet);
		if (everythingSet) {
			let requestModel = new NumberOfDays(
				props.contactId,
				formatDateToSend(startDate),
				startMoment,
				formatDateToSend(endDate),
				endMoment
			);
			let numberOfDays = await LeaveRequestServices.getNumberOfDays(requestModel);
			props.setNumberOfDays(numberOfDays);
		}
	}

	async function onTypeChange(type) {
		props.setType(type);
		props.setDates({date: undefined, moment: props.initialDate.moment}, { date: undefined, moment: props.endDate.moment}, false);
	}

	async function onSubmitClicked() {
		props.submitClicked();
		try {
			let requestModel = new LeaveRequestModel(
				formatDateToSend(props.initialDate.date),
				props.initialDate.moment,
				formatDateToSend(props.endDate.date),
				props.endDate.moment,
				props.type,
				props.numberOfDays,
				props.contactId,
				props.description,
				props.leaveRequest !== null ? props.leaveRequest.id : null,
			);
			let result;
			if (props.leaveRequest === null)
				result = await LeaveRequestServices.addLeaveRequest(requestModel);
			else result = await LeaveRequestServices.putLeaveRequest(requestModel);
			props.postEnd(result.message, props.type === 'Sick');
			if (props.type === 'Sick') {
				let secondRequestModel = new UploadSickLeaveModel(
					result.leaveRequestId,
					props.firstName,
					props.lastName,
					props.businessUnitId
				);
				let sharepointResult = new MessageModel("success", "Your Sick Leave has been sent. Remember to attach an attestation later");
				if (typeof props.sickAttestation !== 'undefined') {
					sharepointResult = await LeaveRequestServices.uploadSickLeave(props.sickAttestation, secondRequestModel);
				}
				props.sickAttestationPostEnd(sharepointResult);
			}
		} catch (e) {
			props.postError();
		}
	}
	return (
		<div className='leaveRequest'>
			<div className='leaveRequest__main'>
				<section className='leaveRequest__section'>
					<h2 className='leaveRequest__title'>{props.leaveRequest === null ? 'New leave request' : 'Leave Request'}</h2>
					{props.message !== null && (
						<div className='leaveRequest__alert'>
							<Alert variant={props.message.type}>
								<p>{props.message.value}</p>
							</Alert>
						</div>
					)}
					{/* {this.props.status !== null &&
            <div className="LeaveRequest-FormContainer">
              <label className="LeaveRequest-FormLabel">Status</label>

              <div className="LeaveRequest-FormAnswer">&emsp; {this.props.status}</div>

            </div>} */}

					<section className='leaveRequest__wrapper'>
						<div className='leaveRequest__fieldset leaveRequest__fieldset--back'>
							<label className='leaveRequest__label'>Leave Type:</label>
							<Form className='leaveRequest__checkbox'>
								<Form.Control
									key='leaveRequest__select'
									as='select'
									size='sm'
									className='leaveRequest__select'
									value={props.type}
									disabled={!props.canModify}
									onChange={(e) => onTypeChange(e.target.value)}>
									<option hidden>Select</option>
									<option disabled={!props.canModify}>Ponctual</option>
									<option disabled={!props.canModify}>Annual</option>
									<option disabled={!props.canModify}>Unpaid</option>
									<option disabled={!props.canModify}>Sick</option>
								</Form.Control>
							</Form>
						</div>
						<div className='leaveRequest__block'>
							<div className='leaveRequest__fieldset'>
								<label className='leaveRequest__label'>Start Date:</label>
								<div className='leaveRequest__field'>
									<DatePicker
										className='leaveRequest__date'
										dateFormat='dd/MM/yyyy'
										minDate={props.type === 'Sick' ? new Date(2023, 0, 1): new Date()}
										selected={props.initialDate.date}
										onChange={(date) => onDateChange(date, undefined, undefined, undefined)}
										selectsStart
										startDate={props.initialDate.date}
										endDate={props.endDate.date}
										disabled={!props.canModify}
									/>
								</div>
							</div>
							<Form className='leaveRequest__buttons'>
								<Form.Check
									className='leaveRequest__check'
									custom
									id='initialDateMorning'
									type='radio'
									name='language'
									value='Morning'
									label='Morning'
									checked={props.initialDate.moment === 'Morning'}
									onChange={(e) => onDateChange(undefined, e.target.value, undefined, undefined)}
									disabled={!props.canModify}
								/>
								<Form.Check
									className='leaveRequest__check'
									custom
									id='initialDateAfternoon'
									type='radio'
									name='language'
									value='Afternoon'
									label='Afternoon'
									checked={props.initialDate.moment === 'Afternoon'}
									onChange={(e) => onDateChange(undefined, e.target.value, undefined, undefined)}
									disabled={!props.canModify}
								/>
							</Form>
						</div>
						<div className='leaveRequest__block'>
							<div className='leaveRequest__fieldset leaveRequest__fieldset--center'>
								<label className='leaveRequest__label'>End Date:</label>
								<DatePicker
									className='leaveRequest__date'
									dateFormat='dd/MM/yyyy'
									minDate={props.initialDate.date}
									selected={props.endDate.date}
									onChange={(date) => onDateChange(undefined, undefined, date, undefined)}
									selectsEnd
									startDate={props.initialDate.date}
									endDate={props.endDate.date}
									disabled={!props.canModify}
								/>
							</div>
							<Form className='leaveRequest__buttons'>
								<Form.Check
									className='leaveRequest__check'
									custom
									id='endDateMorning'
									type='radio'
									name='language'
									value='Morning'
									label='Morning'
									checked={props.endDate.moment === 'Morning'}
									onChange={(e) => onDateChange(undefined, undefined, undefined, e.target.value)}
									disabled={!props.canModify}
								/>
								<Form.Check
									className='leaveRequest__check'
									custom
									id='endDateAfternoon'
									type='radio'
									name='language'
									value='Afternoon'
									label='Afternoon'
									checked={props.endDate.moment === 'Afternoon'}
									onChange={(e) => onDateChange(undefined, undefined, undefined, e.target.value)}
									disabled={!props.canModify}
								/>
							</Form>
						</div>
						<div className='leaveRequest__block leaveRequest__block--row'>
							<label className='leaveRequest__label'>Number of days:</label>
							<div className='leaveRequest__status'>{props.numberOfDays}</div>
						</div>
						<div className='leaveRequest__block leaveRequest__block--column'>
							<label className='leaveRequest__label'>Description (optional):</label>
							<div className='leaveRequest__checkbox'>
								<Form.Control
									as='textarea'
									rows='3'
									cols='52'
									className='leaveRequest__textarea'
									value={props.description ? props.description : ''}
									onChange={(e) => props.setDescription(e.target.value)}
									disabled={!props.canModify}
									maxLength={100}
								/>
							</div>
						</div>
						{props.type === 'Sick' && (!props.leaveRequest || props.leaveRequest.status === "To Validate" || props.sickLRName || typeof props.sickAttestation !== "undefined") && (
							<div className='leaveRequest__block leaveRequest__block--column'>
								<label className='leaveRequest__label'>Sick Attestation:</label>
								<div className='leaveRequest__attached'>
									{(!props.leaveRequest || props.leaveRequest.status === "To Validate") &&
									<React.Fragment>
										<label className='print__attach leaveRequest__btn leaveRequest__upload btn--outlined' htmlFor='sickLeaveAttestation'>
											<FaUpload className='print__icon'/>{!props.sickLRName && typeof props.sickAttestation === 'undefined' && 'Upload'}
										</label>
										<input
											className='input-file'
											title='Click to upload a new file'
											id='sickLeaveAttestation'
											type='file'
											accept='.pdf'
											onChange={(e) => props.setAttestation(e.target.files[0])}
										/>
									</React.Fragment>
									}
									{props.sickLRName ?
									<a href={props.sickLRLink}>{props.sickLRName}</a> :
									typeof props.sickAttestation === 'undefined' ? '' :
									<a target="_blank" rel="noopener noreferrer" href={URL.createObjectURL(props.sickAttestation)}>
										{props.sickAttestation.name}
									</a>
									}
								</div>
							</div>
						)}

						{props.status !== null && (
							<div className='leaveRequest__block'>
								<label className='leaveRequest__status'>Status:</label>

								<div className='leaveRequest__status'>{props.status}</div>
							</div>
						)}
					</section>
					<div className='leaveRequest__group'>
						{props.leaveRequest === null && (
							<button className='print__attach btn leaveRequest__btn btn--outlined' onClick={props.seeHome}>
								<FaArrowLeft className='print__icon' />
								Back
							</button>
						)}
						{((props.canCancel || props.type === 'Sick') && props.leaveRequest !== null)&& (
							<button className='print__attach btn leaveRequest__btn btn--outlined' onClick={props.seeLeaveRequests}>
								<FaArrowLeft className='print__icon' />
								Back
							</button>
						)}
						{props.canModify || props.type === 'Sick' ? (
							<button
								className='print__attach btn leaveRequest__btn btn--primary'
								disabled={!props.ready || sickLRName || props.numberOfDays===0 && typeof props.sickAttestation === 'undefined'}
								onClick={onSubmitClicked.bind(this)}>
								{props.leaveRequest === null ? (
									<>
										Create
										<FaCheck className='print__icon' />
									</>
								) : (
									<>
										Modify
										<FaPen className='print__icon' />
									</>
								)}
							</button>
						) : (
							<button className='print__attach btn leaveRequest__btn btn--outlined' onClick={props.seeLeaveRequests}>
								<FaArrowLeft className='print__icon' />
								Back
							</button>
						)}
					</div>
				</section>
				<section className='leaveRequest__section'>
					<div className='leaveRequest__wrapper'>
						<p className='leaveRequest__info'>
							Annual Leave: is for summer leave <br />
							Ponctual Leave: is for every other paid leaves <br />
							Unpaid Leave: is in the case you don't have anymore days off <br />
							Sick Leave: is in the case you are sick <br />
						</p>
					</div>
				</section>

				<CancelLeaveRequestContainer />
			</div>
		</div>
	);
}
