import React from 'react';
import { Document, Page, Image, View, StyleSheet, Text, Font } from '@react-pdf/renderer'
import regular from "./Fonts/Roboto-Regular.ttf";
import bold from "./Fonts/Roboto-Bold.ttf";

const getExtension = (startOfWord) => {
    let extension = "";
    switch(startOfWord) {
        case '/9j':
            extension = 'jpg';
            break;
        case /^Qk[0-9]$/gm:
            extension = 'bmp';
            break;
        case 'R0l':
            extension = 'gif';
            break;
        default:
            extension = "png";
            break;
    }

    return extension;
}

const getUnitTimeTraslated = (unit, outputLanguage) => {
    let unitTimeTranslated = unit;
    if(outputLanguage !== "English"){
        const translation = {
            French: {
                Day: "Jour",
                Hour: "Heure"
            },
            Polish: {
                Day: "Dzień",
                Hour: "Godzina"
            }
        };

        unitTimeTranslated = translation[outputLanguage][unit];
    }

    return unitTimeTranslated;
}

let english = {
    cra: 'Monthly Activity Report',
    name: 'Name',
    month: 'Month',
    unitTime: 'Time unit',
    customer: 'Customer',
    mission: 'Mission',
    comment: 'Comment',
    presence: 'Presence',
    absence: 'Absence',
    total: 'Total',
    weekend: 'Week-end',
    holiday: 'Public Holiday',
    consultant: 'Consultant',
    consultantInfo: 'Name, date, signature',
    customerInfo: 'Name, date, stamp of the company, signature',
    months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ]
}

let polish = {
    cra: 'Raport Pracy za Miesiąc',
    name: 'Nazwa',
    month: 'Miesiąc',
    unitTime: 'Jednostka czasu',
    customer: 'Klient',
    mission: 'Projekt',
    comment: 'Komentarz',
    presence: 'Obecność',
    absence: 'Nieobecność',
    total: 'Razem',
    weekend: 'Week-end',
    holiday: 'Święta',
    consultant: 'Konsultant',
    consultantInfo: 'Nazwisko, data, podpis',
    customerInfo: 'Nazwisko, data, pieczęć firmy, podpis',
    months: [
        'Styczeń',
        'Luty',
        'Marzec',
        'Kwiecień',
        'Maj',
        'Czerwiec',
        'Lipiec',
        'Sierpień',
        'Wrzesień',
        'Październik',
        'Listopad',
        'Grudzień'
    ]
}

let french = {
    cra: 'Compte rendu d’activité mensuel',
    name: 'Nom',
    month: 'Mois',
    unitTime: 'Unité de temps',
    customer: 'Client',
    mission: 'Intitulé mission',
    comment: 'Commentaire',
    presence: 'Présence',
    absence: 'Absence',
    total: 'Total',
    weekend: 'Week-end',
    holiday: 'Férié',
    consultant: 'Consultant',
    consultantInfo: 'Nom, date, signature',
    customerInfo: 'Nom, date, tampon entreprise, signature',
    months: [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Aout',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre'
    ]
}

let frenchAdress = 'Bertek France - 229 rue Saint-Honoré, 75001 PARIS - +33 (0) 4 34 48 02 81';
let polishAdress = 'Bertek Polska - Warsaw Trade Tower Chłodna 51 - 00-867 Warszawa - POLSKA - +48 (0) 662 368 024';

Font.register({
    family: 'Roboto', fonts: [
        { src: regular }, // font-style: normal, font-weight: normal
        { src: bold, fontWeight: 700 }
    ]
});


export default class CraPdf extends React.Component {
    styles = StyleSheet.create({
        page: {
            fontFamily: 'Roboto'
        },
        header: {
            marginTop: '35pt',
            marginHorizontal: '36pt',
            // height: '26pt',
            marginBottom: '16pt',
            borderBottom: '1pt solid black',
            flexDirection: 'row',
            paddingBottom: '15pt'
        },
        logo: {
            width: "12vw",
        },
        legend: {
            marginHorizontal: '36pt',
            marginBottom: '20pt',
            fontSize: 10,
            flexDirection: 'row',
            justifyContent: 'center'
        },
        titleWrapper: {
            flexGrow: 1,
            alignItems: 'center'
        },
        info: {
            marginHorizontal: '36pt',
            fontSize: 10
        },
        bold: {
            fontWeight: 700
        },
        tabLine: {
            flexDirection: 'row'
        },
        tabFirstRow: {
            backgroundColor: 'white',
            width: '110pt'
        },
        tabLastRowCase: {
            width: '45pt',
            marginRight: '1pt'
        },
        tabCaseLastLine: {
            marginBottom: '1pt'
        },
        table: {
            marginTop: '36pt',
            marginBottom: '25pt',
            marginHorizontal: '36pt',
            fontSize: 10,
            backgroundColor: 'grey'
        },
        tabFirstLineCase: {
            backgroundColor: '#cccccc'
        },
        tabDownLineCase: {
            backgroundColor: 'white',
            height: '25pt'
        },
        textFixedWidth: {
            width: '15pt',
            textAlign: 'center'
        },
        tabCase: {
            marginTop: '1pt',
            marginLeft: '1pt',
            justifyContent: 'center',
            alignItems: 'center'
        },
        tabCenterCase: {
            flexGrow: 1
        },
        tabCaseHolidays: {
            backgroundColor: '#ffa595'
        },
        tabCaseWeekEnd: {
            backgroundColor: '#999999'
        },
        tabCaseMissing: {
            backgroundColor: '#fa378c'
        },
        legendCase: {
            width: '20pt',
            height: '20pt',
            marginRight: '4pt'
        },
        legendLittle: {
            flexDirection: 'row',
            alignItems: 'center',
            marginHorizontal: '10pt'
        },
        signTotalPlace: {
            marginHorizontal: '25pt'
        },
        signPlace: {
            border: '1pt solid black',
            width: '210pt',
            height: '130pt'
        },
        sign: {
            fontSize: 10,
            marginHorizontal: '36pt',
            flexDirection: 'row',
            justifyContent: 'center',
            marginTop: '40pt'

        },
        personText: {
            marginHorizontal: '30pt',
            marginBottom: '2pt'
        },
        infoText: {
            margin: '2pt',
            fontSize: 7
        },
        adress: {
            marginHorizontal: '36pt',
            marginTop: '60pt',
            fontSize: 10,
            justifyContent: 'center',
            flexDirection: 'row'
        }
    });

    render() {
        let text = this.props.lang === "Polish" ? polish : this.props.lang === "French" ? french : english;
        text.adress = this.props.activity.businessUnit === "Polish" ? polishAdress : frenchAdress;
        return (
            <Document>
                <Page orientation="landscape" style={this.styles.page}>
                    <View style={this.styles.header}>
                        <Image src={ this.props.activity.ARBertek ? process.env.REACT_APP_APP_URL + '/bertek-black-logo.png' : `data:image/${getExtension(this.props.activity.logo.substring(0,3))};base64,${this.props.activity.logo}`} style={this.styles.logo} />
                        <View style={this.styles.titleWrapper}><Text>{text.cra}</Text></View>
                    </View>
                    <View style={this.styles.info}>
                        <Text><Text style={this.styles.bold}>{text.name + " : "}</Text>{this.props.name}</Text>
                        <Text><Text style={this.styles.bold}>{text.month + " : "}</Text>{text.months[this.props.selectedMonth] + ' ' + this.props.selectedYear}</Text>
                        <Text><Text style={this.styles.bold}>{text.unitTime + " : "}</Text>{getUnitTimeTraslated(this.props.activity.unit, this.props.lang)}</Text>
                        <Text><Text style={this.styles.bold}>{text.customer + " : "}</Text>{this.props.activity.client} </Text>
                        <Text><Text style={this.styles.bold}>{text.mission + " : "}</Text>{this.props.activity.name} </Text>
                    </View>
                    <View style={this.styles.table}>
                        <View style={this.styles.tabLine}>
                            <View style={[this.styles.tabFirstRow, this.styles.tabCase]}>
                            </View>
                            {this.props.activity.days.map((day) => (
                                <View style={[this.styles.tabFirstLineCase, this.styles.tabCenterCase, this.styles.tabCase]} key={'dateLine' + day.date}>
                                    <Text style={this.styles.textFixedWidth}>{day.date}</Text>
                                </View>
                            ))}
                            <View style={[this.styles.tabLastRowCase, this.styles.tabFirstLineCase, this.styles.tabCase]}>
                                <Text>{text.total}</Text>
                            </View>
                        </View>

                        <View style={this.styles.tabLine}>
                            <View style={[this.styles.tabFirstRow, this.styles.tabCase, this.styles.tabCaseLastLine]}>
                                <Text>{text.presence}</Text>
                            </View>
                            {this.props.activity.days.map((day, index) => {
                                if (day.isHolidays)
                                    return (
                                        <View style={[this.styles.tabDownLineCase, this.styles.tabCenterCase, this.styles.tabCase, this.styles.tabCaseHolidays, this.styles.tabCaseLastLine]} key={'presenceLine' + day.date}>
                                            <Text style={this.styles.textFixedWidth}>{day.value === 0 ? '' : day.value}</Text>
                                        </View>
                                    )
                                else if (day.isWeekend)
                                    return (
                                        <View style={[this.styles.tabDownLineCase, this.styles.tabCenterCase, this.styles.tabCase, this.styles.tabCaseWeekEnd, this.styles.tabCaseLastLine]} key={'presenceLine' + day.date}>
                                            <Text style={this.styles.textFixedWidth}>{day.value === 0 ? '' : day.value}</Text>
                                        </View>
                                    )
                                else if (day.value !== 0)
                                    return (
                                        <View style={[this.styles.tabDownLineCase, this.styles.tabCenterCase, this.styles.tabCase, this.styles.tabCaseLastLine]} key={'presenceLine' + day.date}>
                                            <Text style={this.styles.textFixedWidth}>{day.value}</Text>
                                        </View>
                                    )
                                else
                                    return (
                                        <View style={[this.styles.tabDownLineCase, this.styles.tabCenterCase, this.styles.tabCase, this.styles.tabCaseMissing, this.styles.tabCaseLastLine]} key={'presenceLine' + day.date}>
                                            <Text style={this.styles.textFixedWidth}>{day.value}</Text>
                                        </View>
                                    )
                            })}
                            <View style={[this.styles.tabLastRowCase, this.styles.tabDownLineCase, this.styles.tabCase, this.styles.tabCaseLastLine]}>
                                <Text>{this.props.activity.total}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={this.styles.legend}>
                        <View style={this.styles.legendLittle}>
                            <View style={[this.styles.legendCase, this.styles.tabCaseWeekEnd]}></View>
                            <Text>{text.weekend}</Text>
                        </View>
                        <View style={this.styles.legendLittle}>
                            <View style={[this.styles.legendCase, this.styles.tabCaseHolidays]}></View>
                            <Text>{text.holiday}</Text>
                        </View>
                        <View style={this.styles.legendLittle}>
                            <View style={[this.styles.legendCase, this.styles.tabCaseMissing]}></View>
                            <Text>{text.absence}</Text>
                        </View>
                    </View>
                    <View style={this.styles.info}>
                        <Text><Text style={this.styles.bold}>{text.comment + " : "}</Text>{this.props.comment ? this.props.comment : '/'} </Text>
                    </View>
                    <View style={this.styles.sign}>
                        <View style={this.styles.signTotalPlace}>
                            <Text style={this.styles.personText}>{text.consultant}</Text>
                            <View style={this.styles.signPlace}>
                                <Text style={this.styles.infoText}>{text.consultantInfo}</Text>

                            </View>
                        </View>
                        <View style={this.styles.signTotalPlace}>
                            <Text style={this.styles.personText}>{text.customer}</Text>
                            <View style={this.styles.signPlace}>
                                <Text style={this.styles.infoText}>{text.customerInfo}</Text>

                            </View>
                        </View>
                    </View>
                    {this.props.activity.ARBertek &&
                        <View style={this.styles.adress}>
                            <Text>{text.adress}</Text>
                        </View>}
                </Page>
            </Document>
        )
    }
}